import React from "react";
import { Container } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { Default, Mobile } from "../../helpers/Breakpoints";

export default function CTA() {
  const location = useLocation();

  return (
    <>
      <Default>
        {location.pathname === "/" ? (
          <>
            <Container>
              <div className="d-flex flex-column justify-content-center text-white px-3 cta-style">
                <h2>List your space and start earning.</h2>
                <p className="my-2">
                  Let the dead spaces of your home earn for you. Be it the
                  living room, the terrace or the backyard.
                </p>
                <small className="mt-3">
                  <Link
                    to="/list-space"
                    className="btn btn-reg bg-secondary fw-medium text-white btn-padding"
                  >
                    Get started now
                  </Link>
                </small>
              </div>
            </Container>
          </>
        ) : (
          <>
            <div className="d-flex flex-column justify-content-center text-white px-3 cta-style">
              <h2>List your space and start earning.</h2>
              <p className="my-2">
                Let the dead spaces of your home earn for you. Be it the living
                room, the terrace or the backyard.
              </p>
              <small className="mt-3">
                <Link
                  to="/list-space"
                  className="btn btn-reg bg-secondary fw-medium text-white btn-padding"
                >
                  Get started now
                </Link>
              </small>
            </div>
          </>
        )}
      </Default>
      <Mobile>
      {location.pathname === "/" ? (
          <>
            <Container>
              <div className="d-flex flex-column justify-content-center text-white px-3 cta-style">
                <h2>List your space and start earning.</h2>
                <p className="my-2">
                  Let the dead spaces of your home earn for you. Be it the
                  living room, the terrace or the backyard.
                </p>
                <small className="mt-3">
                  <Link
                    to="/list-space"
                    className="btn btn-reg bg-secondary fw-medium text-white btn-padding"
                  >
                    Get started now
                  </Link>
                </small>
              </div>
            </Container>
          </>
        ) : (
          <>
            <div className="d-flex flex-column justify-content-center text-white px-3 cta-style">
              <h2>List your space and start earning.</h2>
              <p className="my-2">
                Let the dead spaces of your home earn for you. Be it the living
                room, the terrace or the backyard.
              </p>
              <small className="mt-3">
                <Link
                  to="/list-space"
                  className="btn btn-reg bg-secondary fw-medium text-white btn-padding"
                >
                  Get started now
                </Link>
              </small>
            </div>
          </>
        )}
      </Mobile>
    </>
  );
}
