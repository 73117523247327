export default function isGuestListEmpty(guests) {
	if (
		guests.some(
			guest =>
				guest.guest_name === '' ||
				guest.guest_mobile === '' ||
				guest.guest_gender === ''
		)
	) {
		return true;
	}
	return false;
}
