import React, { useEffect, useState } from 'react';
import { Skeleton } from '../../../modules/common';
import { useQuery } from 'react-query';
// import queryString from 'query-string';

import { Row, Col } from 'react-bootstrap';
import { fetchPartyListing } from '../../../../api/requests';
import { EventCard } from '../../../modules/content';

import { RenderUserObj } from '../../../helpers';
import { useSelector } from 'react-redux';

import { v4 } from 'uuid';

import { useParams /*useHistory, useLocation*/ } from 'react-router-dom';

export default function Today({ setPage, ...props }) {
	const { pagenumber } = useParams();
	// const location = useLocation();
	// const history = useHistory();

	let [token, setToken] = useState(null);

	const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
	const userObj = RenderUserObj(useSelector(state => state.auth.data));

	const rand = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

	// console.log(props, query);

	useEffect(() => {
		setPage(pagenumber);
		// props.onSetFilter(filter);
		if (isAuthenticated) {
			if (userObj !== undefined) setToken(userObj.token);
		}
	}, [pagenumber, setPage, isAuthenticated, userObj]);

	const { data, isLoading, error } = useQuery(
		['partyListing', '', 'today', token],
		fetchPartyListing,
		{
			refetchOnWindowFocus: false,
		}
	);

	// function handlePageTransition(next, prev) {
	//   if (pagenumber > 1 && prev) {
	//     history.push({
	//       pathname: `/parties/page/${parseInt(pagenumber) - 1}`,
	//       search: "?filter=today",
	//     });
	//   }

	//   if (pagenumber < 12 && next) {
	//     history.push({
	//       pathname: `/parties/page/${parseInt(pagenumber) + 1}`,
	//       search: "?filter=today",
	//     });
	//   }
	// }

	// console.log(moment().format('YYYY[-]MM[-]DD'));

	return (
		<div>
			{error && <h5>Failed to load</h5>}
			{isLoading && (
				<div className="grid-container">
					{rand.map((item, index) => {
						return <Skeleton key={index} />;
					})}
				</div>
			)}
			<Row>
				{data &&
					data.data.map((item, index) => {
						return (
							<Col md={4} key={v4()} className="mb-3">
								<EventCard type="party" {...item} />
							</Col>
						);
					})}
			</Row>
			{/* //Pagination */}
			{/* <div className="d-flex justify-content-center align-items-center flex-wrap mt-4 mb-2">
        <button
          className="btn shadow text-primary btn-sm mr-2"
          onClick={() => handlePageTransition(false, true)}
          disabled={location.pathname.slice(-2) === "/1"}
        >
          &#8592;
        </button>
        <button
          className="btn shadow text-primary btn-sm ml-2"
          onClick={() => handlePageTransition(true, false)}
          disabled={location.pathname.slice(-2) === "12"}
        >
          &#8594;
        </button>
      </div> */}
		</div>
	);
}
