import React from "react";
import { Button } from "react-bootstrap";

export default function ParkingComponent({ setAmenities, parking }) {
  return (
    <div>
      <label>
        <h4>Parking facilities</h4>
      </label>
      <div className="d-flex justify-content-between mb-3">
        <Button
          variant={parking === 1 ? `primary` : `shadow shadow-sm`}
          size="reg"
          block
          onClick={() => {
            setAmenities((prev) => ({ ...prev, parking: 1 }));
          }}
        >
          Yes
        </Button>
        <Button
          variant={parking === 2 ? `primary` : `shadow shadow-sm`}
          size="reg"
          className="m-0"
          block
          onClick={() => {
            setAmenities((prev) => ({ ...prev, parking: 2 }));
          }}
        >
          No
        </Button>
        <Button
          variant={parking === 3 ? `primary` : `shadow shadow-sm`}
          size="reg"
          className="m-0"
          block
          onClick={() => {
            setAmenities((prev) => ({ ...prev, parking: 3 }));
          }}
        >
          Valet
        </Button>
        <Button
          variant={parking === 4 ? `primary` : `shadow shadow-sm`}
          size="reg"
          className="m-0"
          block
          onClick={() => {
            setAmenities((prev) => ({ ...prev, parking: 4 }));
          }}
        >
          On the street
        </Button>
      </div>
    </div>
  );
}
