import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faStar } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons';

import DefaultDP from '../../../../assets/DP.png';
import { Default, Mobile } from '../../../helpers/Breakpoints';
import Img from 'react-cool-img';
import { useEffect } from 'react';
import './Verification.module.scss';
import styles from './Verification.module.scss';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { startOnFidoVerification } from '../../../modules/verification/onfido';
import axios from 'axios';

export default function Verification({ userObj, isLoading, data, ...props }) {
	const [name, setName] = useState('');
	const [auth, setAuth] = useState(false);
	const [picture, setPicture] = useState('');
	const [token, setToken] = useState(false);
	const [review, setReview] = useState(true);
	const [disableButton, setDisableButton] = useState(false);
	function renderImage() {
		if (userObj.image.includes('null')) {
			return null;
		}
		return userObj.image;
	}
	useEffect(() => {
		axios
			.get('https://api-dev.playace.co/v1/user/govt-verification', {
				headers: {
					Authorization: `Bearer ${userObj.token}`,
				},
			})
			.then(res => {
				console.log(res);
				if (res.data.data.is_govt_id_verified === false) {
					if (res.data.data.onfido_check_id !== null) setReview(true);
					else setReview(false);
				}
			});
	}, [data, userObj]);

	// useEffect(() => {
	// 	const url = 'https://api.instagram.com/oauth/access_token';

	// 	const data = {
	// 		client_id: '792834798305170',
	// 		client_secret: 'a6d92e2b58293c40e7c3dad5b8b29aab',
	// 		grant_type: 'authorization_code',
	// 		redirect_uri: 'https://playace.co/dashboard',
	// 		code: 'AQDtfCSmN9HfFTyB5yao_jI3ajJqBRiTd2_a6QHL8xQBl5fWWjGqpAUWfO8V5SGu_6ROPxufOt-Cc29tBZGmQt8oWpmoZ10mm6_0UtfE-BRcbbUJmumhjtSQc2yqueV35HsNRKAxPK6KWeeub0kmPsaKXxRYYiXiwuRgY1HeW-2TAKct4yXn8PtEXKDomS8QvsXlROBt52D8FohDAllPYnAeP0RaVHMePGzTicrZkL-LKQ#_',
	// 	};

	// 	const options = {
	// 		headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
	// 		params: {
	// 			client_id: '792834798305170',
	// 			client_secret: 'a6d92e2b58293c40e7c3dad5b8b29aab',
	// 		},
	// 	};
	// 	// renderImage();
	// 	axios
	// 		.post(
	// 			url,
	// 			{
	// 				client_id: '792834798305170',
	// 				client_secret: 'a6d92e2b58293c40e7c3dad5b8b29aab',
	// 				grant_type: 'authorization_code',
	// 				redirect_uri: 'https://playace.co/dashboard',
	// 				code: 'AQDtfCSmN9HfFTyB5yao_jI3ajJqBRiTd2_a6QHL8xQBl5fWWjGqpAUWfO8V5SGu_6ROPxufOt-Cc29tBZGmQt8oWpmoZ10mm6_0UtfE-BRcbbUJmumhjtSQc2yqueV35HsNRKAxPK6KWeeub0kmPsaKXxRYYiXiwuRgY1HeW-2TAKct4yXn8PtEXKDomS8QvsXlROBt52D8FohDAllPYnAeP0RaVHMePGzTicrZkL-LKQ#_',
	// 			},
	// 			options
	// 		)
	// 		.then(res => console.log(res));

	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [userObj]);

	// const responseFacebook = response => {
	// 	console.log(response);
	// 	if (response.status !== 'unknown') {
	// 		setAuth(true);
	// 		setName(response.name);
	// 		setPicture(response.picture.data.url);
	// 		setToken(response.accesToken);
	// 	}
	// };
	// const componentClicked = response => {
	// 	console.log(response);
	// };
	// console.log(auth, userObj.image, disableButton, data);
	//
	return (
		<>
			<Default>
				<div
					className={`text-center ${styles['onfido-sdk-ui-Theme-modalOverlay']}`}
				>
					<div
						className="d-flex justify-content-center align-items-center mx-auto"
						style={{
							height: '120px',
							width: '120px',
							borderRadius: '50%',
							overflow: 'hidden',
						}}
					>
						<Img
							placeholder={DefaultDP}
							error={DefaultDP}
							src={renderImage()}
							className="img-fluid"
							alt="profile"
						/>
					</div>
					{/* <small className="d-block text-muted mt-3">Update photo</small> */}
					<h3 className="mt-3">
						{isLoading || data.data.first_name + ' ' + data.data.last_name}
					</h3>
					{/* <FontAwesomeIcon
            icon={faStar}
            size="1x"
            className="text-primary"
          ></FontAwesomeIcon> */}
					{/* <small className="text-muted ml-2">1 review</small> */}
				</div>
				<hr />
				<div>
					<p className="text-primary text-uppercase">Verified Documents</p>
					<div className="d-flex justify-content-between align-items-center mb-3">
						<p className="fw-bold m-0">Phone Number</p>
						{isLoading || data.data.is_phone_verified === 1 ? (
							<FontAwesomeIcon
								icon={faCheckCircle}
								size="2x"
								className="text-secondary"
							></FontAwesomeIcon>
						) : (
							<small className="d-block fw-bold">ADD</small>
						)}
					</div>
					<div className="d-flex justify-content-between align-items-center mb-3">
						<p className="fw-bold m-0">Government ID</p>
						{isLoading || data.data.is_govt_id_verified ? (
							<FontAwesomeIcon
								icon={faCheckCircle}
								size="2x"
								className="text-secondary"
							></FontAwesomeIcon>
						) : (
							<small>
								<div
									className={`${disableButton ? 'd-block' : 'd-none'} loader`}
									id="loader"
								></div>
								<button
									id="add-governmentId"
									onClick={() => {
										setDisableButton(true);
										startOnFidoVerification(userObj);
									}}
									className={`${
										disableButton || review ? 'd-none' : 'd-block'
									} make-pointer border-0 btn btn-primary fw-bold text-uppercase`}
									style={{ fontSize: '0.75rem' }}
								>
									ADD
								</button>

								{review ? (
									<p className="fw-bold m-0 text-primary text-uppercase">
										Under Review
									</p>
								) : null}
							</small>
						)}
					</div>
					{/* <div className="d-flex justify-content-between align-items-center mb-3">
						<p className="fw-bold m-0">{auth ? name : 'Social Media'}</p>
						{isLoading || data.data.is_email_verified ? (
							<FontAwesomeIcon
								icon={faCheckCircle}
								size="2x"
								className="text-secondary"
							></FontAwesomeIcon>
						) : (
							<small>
								{auth ? (
									<div
										className="d-flex flex-row-reverse align-items-center"
										style={{
											margin: 'auto',
											color: '#000',
										}}
									>
										<img
											src={picture}
											alt={name}
											style={{ borderRadius: '50%' }}
										/>
									</div>
								) : (
									<FacebookLogin
										appId="309245097314944"
										fields="name,email,picture"
										callback={responseFacebook}
										render={renderProps => (
											<button
												onClick={renderProps.onClick}
												className="fw-bold make-pointer bg-white border-0"
											>
												ADD
											</button>
										)}
									/>
								)}
							</small>
						)}
					</div> */}
				</div>
				<div id="onfido-mount"></div>
			</Default>
			<Mobile>
				<div className="text-center">
					<div
						className="d-flex justify-content-center align-items-center mx-auto"
						style={{
							height: '120px',
							width: '120px',
							borderRadius: '50%',
							overflow: 'hidden',
						}}
					>
						<Img
							placeholder={DefaultDP}
							error={DefaultDP}
							src={renderImage()}
							className="img-fluid"
							alt="profile"
						/>
					</div>
					{/* <small className="d-block text-muted mt-3">Update photo</small> */}
					<h3 className="mt-3">
						{isLoading || data.data.first_name + ' ' + data.data.last_name}
					</h3>
					{/* <FontAwesomeIcon
            icon={faStar}
            size="1x"
            className="text-primary"
          ></FontAwesomeIcon>
          <small className="text-muted ml-2">1 review</small> */}
				</div>
				<hr />
				<div>
					<p className="text-primary text-uppercase">Verified Documents</p>
					<div className="d-flex justify-content-between align-items-center mb-3">
						<p className="fw-bold m-0">Phone Number</p>
						{isLoading || data.data.is_phone_verified === 1 ? (
							<FontAwesomeIcon
								icon={faCheckCircle}
								size="2x"
								className="text-secondary"
							></FontAwesomeIcon>
						) : (
							<small className="d-block fw-bold">ADD</small>
						)}
					</div>
					<div className="d-flex justify-content-between align-items-center mb-3">
						<p className="fw-bold m-0">Government ID</p>
						{isLoading || data.data.is_govt_id_verified ? (
							<FontAwesomeIcon
								icon={faCheckCircle}
								size="2x"
								className="text-secondary"
							></FontAwesomeIcon>
						) : (
							<small>
								<div
									style={{ display: disableButton ? 'block' : 'none' }}
									className={`${disableButton ? 'd-block' : 'd-none'} loader`}
									id="loader-mobile"
								></div>
								<button
									id="add-governmentId-mobile"
									onClick={() => {
										setDisableButton(true);
										startOnFidoVerification(userObj);
									}}
									className={`${
										disableButton || review ? 'd-none' : 'd-block'
									} btn btn-primary fw-bold text-uppercase`}
								>
									ADD
								</button>
								{review ? (
									<p className="fw-bold m-0 text-primary text-uppercase">
										Under Review
									</p>
								) : null}
							</small>
						)}
					</div>
					{/* <div className="d-flex justify-content-between align-items-center mb-3">
            <p className="fw-bold m-0">Social Media Links</p>
            {isLoading || data.data.is_email_verified ? (
              <FontAwesomeIcon
                icon={faCheckCircle}
                size="2x"
                className="text-secondary"
              ></FontAwesomeIcon>
            ) : (
              <small className="d-block fw-bold">ADD</small>
            )}
          </div> */}
				</div>
				<div id="onfido-mount"></div>
			</Mobile>
		</>
	);
}
