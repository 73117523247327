export default function converTime(data) {
    let timings = data.timings.split(" - ");
    let time;
    let spaceTimings = timings.map(i => {
        time = i.toString().match(/^([01]\d|2[0-4])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

        if (time.length > 1) { // If time format correct
            time = time.slice(1);  // Remove full string match value
            time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
            time[0] = +time[0] % 12 || 12; // Adjust hours
        }
        return time.join('');
    })
    return spaceTimings;
}