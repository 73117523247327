import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Col, Row, Modal } from 'react-bootstrap';
import ReactPixel from 'react-facebook-pixel';
import { Helmet } from 'react-helmet';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

import Auth from '../../../modules/auth/Auth';
import { Default, Mobile } from '../../../helpers/Breakpoints';
import {
	RenderDate,
	RenderUserObj,
	Loader,
	isGuestListEmpty,
} from '../../../helpers';
import avatar from '../../../../assets/square_logo.png';
import checked from '../../../../assets/ic_checked.png';
import coupon from '../../../../assets/ic_coupon.png';
import { useMutation } from 'react-query';
import { sendRazorpayRequest } from '../../../modules/payments/Razorpay';
import {
	requestToAttend,
	applyCouponCode,
	fetchPartyById,
} from '../../../../api/requests';
import {
	setBookClickedTrue,
	setBookClickedFalse,
} from '../../../../actions/authActions';
import { useSelector, useDispatch } from 'react-redux';
import { useQuery } from 'react-query';

import { calculateDiscount } from '../../../helpers';

export default function BookTicket(props) {
	const [couponCode, setCouponCode] = useState('');
	const [message, setMessage] = useState('');
	const userObj = RenderUserObj(useSelector(state => state.auth.data));
	const bookClicked = useSelector(state => state.auth.bookClicked);
	const [couponDiscount, setCouponDiscount] = useState(0);
	const [allowedDiscount, setAllowedDiscount] = useState(0);
	const [couponLimit, setCouponLimit] = useState(0);
	const [isPaying, setIsPaying] = useState(false);
	const [expired, setExpired] = useState('');
	let [showLoginModal, setShowLoginModal] = useState(null);


	const token = (userObj && userObj.token) || null;
	// let [bookClicked, setbookClicked] = useState(null);
	const [mutate, info] = useMutation(requestToAttend);

	const handleClose = () => {
		setShowLoginModal(false);
	};

	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();

	useEffect(() => {
		setIsPaying(false);
		// Calculates the current date and the party date to determine if the party has expired or not
		// const date = '2021-9-1';
		// const time = '19:45 PM';
		const date = props.party.data.event_timings.event_date;
		const time = props.party.data.event_timings.event_time_from;
		var dt = moment(time, ['h:mm A']).format('HH:mm');
		let newPartyDate = dt.split(':');

		//Create date object and set the time to that
		var partyTime = new Date(time);
		partyTime.setHours(partyHour, partyMinute, 0);
		let partyArray = date.split('-').map(i => parseInt(i));
		partyArray.push(parseInt(newPartyDate[0]), parseInt(newPartyDate[1]), 0);

		var d = new Date(),
			month = '' + (d.getMonth() + 1),
			day = '' + d.getDate(),
			year = d.getFullYear();

		if (month.length < 2) month = parseInt(month);
		if (day.length < 2) day = parseInt(day);
		[year, month, day].join('-');

		var partyHour = d.getHours();
		var partyMinute = d.getMinutes();

		var currentHour = d.getHours();
		var currentMinute = d.getMinutes();

		//Create date object and set the time to that
		var currentTime = new Date();
		currentTime.setHours(currentHour, currentMinute, 0);
		let currentDateArray = [parseInt(year), parseInt(month), parseInt(day)];
		currentDateArray.push(currentHour, currentMinute, 0);

		let partyDate = new Date(
			partyArray[0],
			partyArray[1] - 1,
			partyArray[2],
			partyArray[3],
			partyArray[4],
			partyArray[5]
		);
		let currentDate = new Date(
			currentDateArray[0],
			currentDateArray[1] - 1,
			currentDateArray[2],
			currentDateArray[3],
			currentDateArray[4],
			currentDateArray[5]
		);

		let invitationDeadline = new Date(
			props.party.data.event_timings.event_invitation_end_date
		);

		// let invitationDeadline = new Date('2021-09-01T00:00:00+00:00');
		// let currentDate = new Date('2021-09-01T13:44:00+00:00');

		// Check if the party date and current date differ by 30 minutes and invitation date is behind party date
		let thirtyMinutesGap = 1800000;
		let invitationValid =
			(moment(invitationDeadline).isAfter(currentDate, 'day') ||
				moment(invitationDeadline).isSame(currentDate, 'day')) &&
			(moment(partyDate).isSame(invitationDeadline, 'day') ||
				moment(partyDate).isAfter(invitationDeadline, 'day'));

		let partyValid =
			partyDate.getTime() - currentDate.getTime() >= thirtyMinutesGap;
		if (invitationValid)
			partyValid ? setExpired('') : setExpired('Bookings Closed');
		else setExpired('Bookings Closed');

		ReactPixel.init('1890035134379309');
		ReactPixel.track('PageView');
	}, []);

	useEffect(() => {
		if (props.isAuthenticated) {
			setShowLoginModal(false);
		}

		if (props.isAuthenticated && bookClicked) {
			setShowLoginModal(false);
			dispatch(setBookClickedFalse());
			if (
				props.party.data.user_event_status === '' ||
				(props.party &&
					props.party.data.host_info &&
					props.party.data.host_info.id) === (userObj && userObj.id)
			) {
				history.push(`/booking/party/${props.party.data.id}`);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.isAuthenticated, bookClicked]);

	useEffect(() => {
		// Update coupon discount on adding guests
		if (couponCode !== '') {
			let discount = calculateDiscount(
				couponLimit,
				allowedDiscount,
				props.party.data.event_amount,
				props.guestCount
			);
			setCouponDiscount(discount);
		}
	}, [props.guestCount]);

	console.log("Token : " + token);
	const { data, isLoading } = useQuery(
		['partyDetail', props.party.data.id, token],
		fetchPartyById,
		{
			// refetchOnWindowFocus: false,
		}
	);

	function handleClick(e) {
		e.preventDefault();
		dispatch(setBookClickedTrue());
		if (props.isAuthenticated) {
			history.push(`/booking/party/${props.party.data.id}`);
		} else {
			setShowLoginModal(true);
			dispatch(setBookClickedTrue());
		}
	}

	function handlePayment(e) {
		// e.preventDefault();
		setIsPaying(false);

		// This returns the details of the guests, sent to the backend in the required payload format
		let finalGuestArray = [...props.guests];
		finalGuestArray.shift();
		let propsGuests = finalGuestArray.map(guest => {
			if (Object.keys(guest).length > 0) {
				guest.guest_gender === 'Female'
					? (guest.guest_gender = 2)
					: (guest.guest_gender = 1);
				if (guest.hasOwnProperty('id')) {
					delete guest.id;
				}
			}
			return guest;
		});

		if (
			props.finalAmount === 0 ||
			props.party.data.event_amount === null ||
			props.party.data.event_amount * props.guestCount == couponDiscount ||
			props.party.data.event_amount * props.guestCount == props.couponDiscount
		) {
			props.isBooking();
			console.log('Free event');

			// history.push(`/?requested=true`);
			const postRequestToAttend = async () => {
				try {
					await mutate(
						{
							event_id: props.party.data.id,
							payment_id: 1234, //random
							payment_gateway_id: '',
							playace_code: null,
							user_guests: propsGuests,
							user_token: userObj.token,
						},
						{
							onSuccess: () => {
								setIsPaying(false);
								history.push(`/?requested=true`);
							},
						}
					);
					console.log(info);
				} catch (e) {
					console.log(e);
				}
			};
			postRequestToAttend();
		} else {
			// props.couponDiscount is for mobile UI and couponDiscount is for desktop UI
			let finalAmount;
			let finalDiscount = 0;

			if (props.couponDiscount > 0) finalDiscount = props.couponDiscount;
			else finalDiscount = couponDiscount;

			if (finalDiscount > 0)
				finalAmount = (props.amount + props.TaxAmount - finalDiscount).toFixed(
					2
				);
			else finalAmount = (props.amount + props.TaxAmount).toFixed(2);

			sendRazorpayRequest(
				props.userObj,
				parseFloat(finalAmount),
				props.party,
				propsGuests
			);
		}
	}

	const handleTicketsAvailability = () => {
		fetchPartyById('partyDetail', props.party.data.id, token).then(res => {
			if (props.guests.length > res.data.event_updates.tickets_available) {
				props.handleTicket();
			} else handlePayment();
		});
	};

	const handleCoupon = e => {
		setCouponDiscount(0);
		setMessage('');
		setCouponCode(e.target.value);
	};

	const couponCodeApply = () => {
		let partyData = {
			event_id: props.party.data.id,
			coupon_code: couponCode,
		};
		applyCouponCode(token, partyData).then(res => {
			if (
				couponCode.length > 0 &&
				res.data.message === "Coupon code doesn't exists."
			)
				setMessage('Invalid coupon code');
			else if (
				couponCode.length <= 0 &&
				res.data.message === "Coupon code doesn't exists."
			)
				setMessage('Please enter a valid coupon code');
			else if (
				res.data.message === 'Coupon code applied successfully.' &&
				res.data
			) {
				// Calculate discount for desktop UI
				let discount = calculateDiscount(
					res.data.data.coupon_limit,
					res.data.data.coupon_discount_percent,
					props.party.data.event_amount,
					props.guestCount
				);
				setAllowedDiscount(res.data.data.coupon_discount_percent);
				setCouponLimit(res.data.data.coupon_limit);
				setCouponDiscount(discount);
			}
		});
	};

	//For mobile
	if (props.view === 'sticky') {
		return (
			<div>
				<Default>
					<Modal
						size="lg"
						show={showLoginModal}
						onHide={handleClose}
						dialogClassName="modal-dimensions"
						backdrop="static"
						centered
					>
						<Auth handleClose={handleClose} />
					</Modal>
				</Default>
				<Mobile>
					<Modal
						size="lg"
						show={showLoginModal}
						onHide={handleClose}
						backdrop="static"
						centered
					>
						<Auth handleClose={handleClose} />
					</Modal>
				</Mobile>

				{location.pathname.includes('book') ? (
					<>
						<button
							onClick={
								// props.guests.length <=
								// props.party.data.event_updates.tickets_available
								// 	? handlePayment
								// :
								handleTicketsAvailability
							}
							className="btn btn-primary fw-bold btn-padding payment"
							disabled={isGuestListEmpty(props.guests) || isPaying}
						>
							{props.text}
						</button>
					</>
				) /* : (props.party &&
					props.party.data.host_info &&
					props.party.data.host_info.id) === (userObj && userObj.id) ? (
					<h5 className="fw-bold">You're hosting this party</h5>
				) : isLoading ? (
					<div
						style={{
							minHeight: 'auto',
							textAlign: 'center',
						}}
					>
						<Loader height="80px" width="80px" />
					</div>
				) */ : props.party.data.user_event_status === '' && !isLoading ? (
						<Link
							to={`/booking/party/${props.party.data.id}`}
							onClick={handleClick}
							style={{
								fontSize: props.sold ? '1.25rem' : '.875rem',
							}}
							className={`btn btn-reg btn-primary fw-bold btn-padding ${props.sold || expired !== ''
								? 'bg-white text-primary border-0 p-0'
								: 'bg-primary'
								}`}
						>
							{props.sold ? 'Sold Out' : expired !== '' ? expired : props.text}
						</Link>
					) : props.party.data.user_event_status === 'waiting' && !isLoading ? (
						<h5 className="fw-bold">Awaiting approval</h5>
					) : props.party.data.user_event_status === 'rejected' && !isLoading ? (
						<h5 className="fw-bold">Request declined</h5>
					) : (
						<h5 className="fw-bold">Booking accepted</h5>
					)}
			</div>
		);
	}

	//For mobile - detail page
	if (props.view === 'mobile') {
		return (
			<div
				className="text-center p-3 rounded"
				style={{ border: '2px solid #eaeaea' }}
			>
				{props.party.data.event_amount ? (
					<>
						<h5 className="fw-bold">
							Contribution: Rs {props.party.data.event_amount}/- per person
						</h5>
					</>
				) : (
					<>
						<h5 className={`fw-bold`}>Free Event</h5>
						{/* <h5 className="font-bold">{expired !== 'Book' ? expired : ''}</h5> */}
					</>
				)}

				{expired === '' ? (
					<h5 className="text-primary mb-4">
						{props.sold ? 'Sold Out' : 'Few Spots Left!'}
					</h5>
				) : (
					<h5 className="text-primary mb-4">
						{props.sold ? 'Sold Out' : expired}
					</h5>
				)}

				{/* (props.party &&
					props.party.data.host_info &&
					props.party.data.host_info.id) === (userObj && userObj.id) ? (
					<h5 className="fw-bold">You're hosting this party</h5>
				) : isLoading ? (
					<div
						style={{
							minHeight: 'auto',
							textAlign: 'center',
						}}
					>
						<Loader height="80px" width="80px" />
					</div>
				) :  */props.party.data.user_event_status === '' && !isLoading ? (
						<Link
							to={{
								pathname: `/booking/party/${props.party.data.id}`,
								state: {
									price: props.party.data.event_amount,
									id: props.party.data.id,
								},
							}}
							onClick={handleClick}
							style={{
								fontSize: props.sold ? '1.25rem' : '.875rem',
							}}
							className={`btn btn-reg btn-primary fw-bold btn-padding ${props.sold || expired !== '' ? 'd-none' : 'd-inline-block'
								}`}
						>
							{props.sold ? 'Sold Out' : 'Request to Book'}
						</Link>
					) : props.party.data.user_event_status === 'waiting' && !isLoading ? (
						<h5 className="fw-bold">Awaiting approval</h5>
					) : props.party.data.user_event_status === 'rejected' && !isLoading ? (
						<h5 className="fw-bold">Request declined</h5>
					) : (
						<h5 className="fw-bold">Booking accepted</h5>
					)}

				<Default>
					<Modal
						size="lg"
						show={showLoginModal}
						onHide={handleClose}
						dialogClassName="modal-dimensions"
						backdrop="static"
						centered
					>
						<Auth handleClose={handleClose} />
					</Modal>
				</Default>
				<Mobile>
					<Modal
						size="lg"
						show={showLoginModal}
						onHide={handleClose}
						backdrop="static"
						centered
					>
						<Auth handleClose={handleClose} />
					</Modal>
				</Mobile>
			</div>
		);
	}
	//For Booking page
	if (props.view === 'receipt') {
		return (
			<div className={`${props.classes}`}>
				<div
					style={{ display: 'none' }}
					className="loader-booking-container col-md-11"
				>
					<div className="loader-booking"></div>
				</div>
				<Row className="pt-4 pb-3 px-4">
					<Col md={7} sm="7">
						<p className="mb-0">
							<span className="badge badge-primary p-2 text-uppercase rounded-sm">
								house party
							</span>
						</p>
						<h5 className="fw-bold my-2">{props.party.data.event_heading}</h5>
						{props.party &&
							props.party.data.event_timings &&
							props.party.data.location_details ? (
							<>
								<small>
									{props.party.data.location_details.event_locality},{' '}
									{props.party.data.location_details.city_name}
								</small>{' '}
								<br />
								<small>
									{RenderDate(props.party.data.event_timings.event_date)}
								</small>{' '}
								<br />
								<small>
									{props.party.data.event_timings.event_time_from} -{' '}
									{props.party.data.event_timings.event_time_to}
								</small>
							</>
						) : null}
					</Col>
					<Col md={5} sm={5} className="align-self-end">
						<div className="float-right">
							<h5 className="fw-bold m-0">
								Contribution: <br />
								{props.party.data.event_amount ? (
									<small>Rs {props.party.data.event_amount} per person</small>
								) : (
									<>
										<small className="text-primary">Free Event</small>
										<small className="font-bold">
											{expired !== '' ? expired : ''}
										</small>
									</>
								)}
							</h5>
						</div>
					</Col>
				</Row>
				<hr className="my-3 w-100" />
				{props.party.data.event_amount ? (
					<>
						<h5 className="px-4">Contribution</h5>
						<div className="px-4">
							<div className="d-flex justify-content-between align-items-baseline my-3">
								<div>
									<small className="text-muted d-block">
										Item total ({props.party.data.event_amount} x{' '}
										{props.guestCount})
									</small>
									<small
										style={{
											display: couponDiscount ? 'block' : 'none',
											color: '#ff1844',
										}}
									>
										Promo - ({couponCode})
									</small>
									<small
										style={{
											display: couponDiscount ? 'block' : 'none',
											color: '#ff1844',
											fontSize: '10px',
										}}
									>
										{allowedDiscount}% off{' '}
										{couponLimit && `upto ${couponLimit}`}
									</small>
								</div>
								<div className="d-flex flex-column align-items-end">
									<small className="text-muted d-block">
										Rs {props.amount}
									</small>
									<small
										style={{
											display: couponDiscount ? 'block' : 'none',
											color: '#ff1844',
										}}
									>
										-Rs {couponDiscount}
									</small>
								</div>
							</div>
							<div className="d-flex justify-content-between align-items-center my-3">
								<small className="text-muted d-block">Taxes and charges</small>
								<small className="text-muted d-block">
									Rs{' '}
									{props.party.data.event_amount * props.guestCount ==
										couponDiscount
										? 0
										: props.TaxAmount.toFixed(2)}
								</small>
							</div>
							<div className="d-flex justify-content-between align-items-center my-3">
								<small className="text-muted d-block">Final contribution</small>
								<small className="fw-bold d-block">
									Rs{' '}
									{
										props.party.data.event_amount * props.guestCount ==
											couponDiscount
											? 0
											: // couponDiscount
											// ?
											(
												props.amount +
												props.TaxAmount -
												couponDiscount
											).toFixed(2)
										// : (props.amount + props.TaxAmount).toFixed(2)
									}
								</small>
							</div>
						</div>
						<hr className="my-3 w-100" />
						<div className="d-flex align-items-center justify-content-between px-4">
							<div
								className={`group w-75 ${couponDiscount ? 'd-none' : 'd-flex'
									} align-items-center`}
							>
								<img className="mr-2" style={{ width: '20px' }} src={coupon} />
								<div>
									<input
										className={`mb-0`}
										type="text"
										required
										value={couponCode}
										onChange={handleCoupon}
									/>
									<span className="highlight"></span>
									<span className="bar"></span>
									<label className="ml-4">Enter Coupon code</label>
								</div>
							</div>
							<div
								className={`${couponDiscount ? 'd-flex' : 'd-none'
									} align-items-center`}
							>
								<img className="mr-2" style={{ width: '20px' }} src={checked} />
								<div>
									<p style={{ margin: 0, fontWeight: '700' }}>
										Code {couponCode ? couponCode : ''} applied!
									</p>
									<small>Promo code applied successfully.</small>
								</div>
							</div>
							<button
								type="button"
								onClick={couponCodeApply}
								className="border-0 bg-white"
								style={{
									display: couponDiscount <= 0 ? 'block' : 'none',
									color: '#ff1844',
								}}
							>
								Apply
							</button>
							<div
								className={`${couponDiscount ? 'd-flex' : 'd-none'
									} flex-column align-items-end`}
								style={{ color: '#ff1844' }}
							>
								<small>-Rs {couponDiscount}</small>
								<button
									className="bg-white border-0 p-0"
									style={{ color: '#ff1844' }}
									onClick={() => {
										setCouponDiscount(0);
										setCouponCode('');
									}}
								>
									<small>remove</small>
								</button>
							</div>
						</div>
						<small
							className="d-flex align-items-baseline justify-content-between px-4"
							style={{ color: '#ff1844' }}
						>
							{message.length > 0 && message}
						</small>
						<hr className="my-3 w-100" />
					</>
				) : null}
				<div className="d-flex justify-content-between align-items-center px-4">
					<div>
						<p className="fw-bold m-0">
							{props.finalAmount !== 0 ? (
								<span>
									Rs.{' '}
									{
										props.party.data.event_amount * props.guestCount ==
											couponDiscount
											? 0
											: (props.finalAmount - couponDiscount).toFixed(2)
										// : props.finalAmount.toFixed(2)
									}
								</span>
							) : (
								<span className="text-primary m-0 fw-bold">Free Event</span>
							)}
						</p>
						<small>
							{props.guestCount} Guest{props.guestCount > 1 ? 's' : null}
						</small>
					</div>
					<div className="text-center pt-3 pb-4">
						{/* Button is disabled when fields are empty or when user is making payment */}
						<button
							onClick={
								// props.guests.length <=
								// props.party.data.event_updates.tickets_available
								// 	? handlePayment
								// :
								handleTicketsAvailability
							}
							className="btn btn-reg btn-primary fw-bold btn-padding payment"
							disabled={isGuestListEmpty(props.guests) || isPaying}
						>
							{props.finalAmount === 0 ? <>Book now</> : <>Pay now</>}
						</button>
					</div>
				</div>

				{info.isLoading ? (
					<div
						style={{
							position: 'absolute',
							top: 0,
							left: 0,
							height: '100%',
							width: '100%',
							background: 'rgba(0, 0, 0, 0.3)',
						}}
						className="d-flex justify-content-center align-items-center"
					>
						<Loader />
					</div>
				) : null}
				<Modal
					size="lg"
					show={showLoginModal}
					onHide={handleClose}
					dialogClassName="modal-dimensions"
					backdrop="static"
					centered
				>
					<Auth handleClose={handleClose} />
				</Modal>
			</div>
		);
	}
	//default return
	return (
		<div className="sticky-ticket" id="sticky-ticket">
			<Helmet>
				<script>
					{`
					!function(f,b,e,v,n,t,s)
					{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
					n.callMethod.apply(n,arguments):n.queue.push(arguments)};
					if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version=‘2.0’;
					n.queue=[];t=b.createElement(e);t.async=!0;
					t.src=v;s=b.getElementsByTagName(e)[0];
					s.parentNode.insertBefore(t,s)}(window, document,‘script’,
					‘https://connect.facebook.net/en_US/fbevents.js’);
					fbq(‘init’, ‘1890035134379309’);
					fbq(‘track’, ‘PageView’);
					`}
				</script>
				<noscript>
					{`
					<img height=“1” width=“1" style=“display:none”
					src=“https://www.facebook.com/tr?id=1890035134379309&ev=PageView&noscript=1”
					/>
					`}
				</noscript>
			</Helmet>
			<div className="shadow rounded-lg">
				<div style={{ display: 'none' }} className="loader-booking-container">
					<div className="loader-booking"></div>
				</div>
				<Row className="px-4 pt-4 pb-3">
					<Col>
						<p className="mb-0">
							<span className="badge badge-primary p-2 text-uppercase rounded-sm">
								house party
							</span>
						</p>
						<h5 className="fw-bold my-2 text-truncate">
							{props.party.data.event_heading}
						</h5>
						{props.party &&
							props.party.data.event_timings &&
							props.party.data.location_details ? (
							<>
								<small>
									{props.party.data.location_details.event_locality},{' '}
									{props.party.data.location_details.city_name}
								</small>{' '}
								<br />
								<small>
									{RenderDate(props.party.data.event_timings.event_date)}
								</small>{' '}
								<br />
								<small>
									{props.party.data.event_timings.event_time_from} -{' '}
									{props.party.data.event_timings.event_time_to}
								</small>
							</>
						) : null}
					</Col>
					<Col>
						<div className="float-right text-center">
							{props.party && props.party.data.host_info ? (
								<>
									<p>Hosted by</p>
									<img
										src={
											props.party.data.host_info.image.substr(
												props.party.data.host_info.image.length - 4
											) === 'null'
												? avatar
												: props.party.data.host_info.image
										}
										alt="profile-user"
										className="rounded-circle"
										height="40"
										width="40"
									/>
									<br />
									<small>{props.party.data.host_info.first_name}</small>
									<br />
									{ // Get the number of parties hosted
										(() => {
											switch (props.party.data.host_info.parties_hosted.toString()) {
												case '1': return <small> 'First Time Host' </small>;
												case '2': return <small> {props.party.data.host_info.parties_hosted - 1} party hosted</small>;
												default: return <small> {props.party.data.host_info.parties_hosted - 1} parties hosted</small>;
											}
										})()
									}
									<br />
								</>
							) : null}
							{/* <small>
								<FontAwesomeIcon
									icon={faStar}
									size="lg"
									className="text-primary"
								></FontAwesomeIcon>{' '}
								4
							</small> */}
						</div>
					</Col>
				</Row>
				<hr className="my-3 w-100" />
				<div className="text-center px-4 pt-3 pb-4">
					{props.party.data.event_amount ? (
						<h5>
							Contribution: <br />
							<br />
							Rs.{props.party.data.event_amount}/- per person
						</h5>
					) : (
						<div>
							<h5>Free event</h5>
							{/* <h5 className="font-bold">
								{expired !== '' ? expired + 'exp' : ''}
							</h5> */}
						</div>
					)}

					{/* When the party has expired show the "booking closed" text */}
					{expired === '' ? (
						// If party is sold out show "sold out" text else show 'Few Spots Left!'
						<h5 className="text-primary mb-4">
							{props.sold ? 'Sold Out' : 'Few Spots Left!'}
						</h5>
					) : (
						<h5 className="text-primary mb-4">
							{props.sold ? 'Sold Out' : expired}
						</h5>
					)}

					{
						/* (props.party &&
							props.party.data.host_info &&
							props.party.data.host_info.id) === (userObj && userObj.id) ? (
							<h5 className="fw-bold">You're hosting this party</h5>
						) : isLoading ? (
							<div
								style={{
									minHeight: 'auto',
									textAlign: 'center',
								}}
							>
								<Loader height="80px" width="80px" />
							</div>
						) : */ props.party.data.user_event_status === '' && !isLoading ? (
							<Link
								to={{
									pathname: `/booking/party/${props.party.data.id}`,
									state: {
										price: props.party.data.event_amount,
										id: props.party.data.id,
									},
								}}
								onClick={handleClick}
								className={`btn btn-reg btn-primary fw-bold btn-padding ${props.sold || expired !== '' ? 'd-none' : 'd-inline-block'
									}`}
							>
								Request to Book
							</Link>
						) : props.party.data.user_event_status === 'waiting' && !isLoading ? (
							<h5 className="fw-bold">Awaiting approval</h5>
						) : props.party.data.user_event_status === 'rejected' &&
							!isLoading ? (
							<h5 className="fw-bold">Request declined</h5>
						) : (
							<h5 className="fw-bold">Booking accepted</h5>
						)}
				</div>
			</div>
			<Modal
				size="lg"
				show={showLoginModal}
				onHide={handleClose}
				dialogClassName="modal-dimensions"
				backdrop="static"
				centered
			>
				<Auth handleClose={handleClose} />
			</Modal>
		</div>
	);
}
