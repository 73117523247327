import React from 'react';
import Img from 'react-cool-img';

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faHeart } from "@fortawesome/free-regular-svg-icons";

import BAS from '../assets/img/book a spcae.png';
import { AAP } from '../assets/img/attend a party.png';

export default function RenderImage({
	loading,
	setLoading,
	imagesrc,
	classes,
	isSection,
}) {
	return (
		<React.Fragment>
			{classes === 'parties-heart' ? (
				<>
					<div
						className={`img-wrapper justify-content-center animate`}
						style={{ position: 'relative' }}
					>
						<Img
							style={{
								borderTopLeftRadius: '8px',
								borderTopRightRadius: '8px',
								objectFit: 'cover',
								width: '100%',
								objectPosition: 'top',
								height: '100%',
							}}
							src={imagesrc}
							srcSet={
								isSection === 'attend'
									? `${imagesrc}?ar=16:9&fit=crop&dpr=1 1x, ${imagesrc}?ar=16:9&fit=crop&dpr=2 2x, ${imagesrc}?ar=16:9&fit=crop&dpr=3 3x`
									: `${imagesrc}?ar=16:9&dpr=1 1x, ${imagesrc}?ar=16:9&fit=max&dpr=2 2x, ${imagesrc}?ar=16:9&fit=max&dpr=3 3x`
							}
							alt="parties spaces events playace"
							className="img-fluid"
							error={AAP}
						/>
						{/* <FontAwesomeIcon
              icon={faHeart}
              size="2x"
              className={`text-white ${classes}`}
            ></FontAwesomeIcon> */}
					</div>
				</>
			) : (
				<>
					<div
						className="img-wrapper d-block justify-content-center animate"
						style={{ position: 'relative', minHeight: '200px' }}
					>
						<Img
							style={{
								borderRadius: '8px',
								objectFit: 'cover',
								width: '100%',
								minHeight: '100%',
								maxHeight: '100%',
							}}
							// srcSet={`${imagesrc}?dpr=1 1x, ${imagesrc}?fit=max&dpr=2 2x, ${imagesrc}?fit=max&dpr=3 3x`}
							srcSet={`${imagesrc}?ar=16:9&fit=crop&dpr=1 1x, ${imagesrc}?ar=16:9&fit=crop&dpr=2 2x, ${imagesrc}?ar=16:9&fit=crop&dpr=3 3x`}
							src={imagesrc}
							alt="parties spaces events playace"
							className="img-fluid"
							error={BAS}
						/>
						{/* <FontAwesomeIcon
              icon={faHeart}
              size="2x"
              className={`text-white ${classes}`}
            ></FontAwesomeIcon> */}
						{/* <Form.Check type="checkbox"></Form.Check> */}
					</div>
				</>
			)}
		</React.Fragment>
	);
}
