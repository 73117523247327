//PACKAGE IMPORTS
import React, { useState, useEffect, lazy, Suspense } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Navbar, Nav, Modal, Container } from 'react-bootstrap';
import {
	Switch,
	Route,
	Link,
	NavLink,
	useLocation,
	useHistory,
	Redirect,
} from 'react-router-dom';
import { isEmpty } from 'lodash';
import ReactGA from 'react-ga';

//ASSET IMPORTS
import playace from '../../../assets/logo.png';
// import { ScrollToTop } from '../../helpers';
import DefaultDP from '../../../assets/DP.png';

//COMPONENT IMPORTS
import {
	Home,
	Parties,
	Spaces,
	PartiesDetail,
	BookParty,
	BookSpace,
	Profile,
	ListASpace,
	HostAParty,
	UploadCover,
	PlanningOverview,
	PlanningDetails,
	BankingDetails,
	AboutUs,
	Referral,
	Contact,
	NotFoundPage,
	Refunds,
} from '../';
import Auth from '../../modules/auth/Auth';

//REDUX IMPORTS
import { unloadCurrentUser } from '../../../actions/authActions';
import SpacesDetail from '../Spaces/SpacesDetail';
import Img from 'react-cool-img';
import { Default, Mobile } from '../../helpers/Breakpoints';
import TermsAndConditions from '../TermsAndConditions/TermsAndConditions';
import PrivacyPolicy from '../PrivacyPolicy';
import Disclaimer from '../Disclaimer';
import Faq from '../Faq';
ReactGA.initialize('UA-163435677-1');

export default function Header() {
	let [expanded, setExpanded] = useState(false);
	const [show, setShow] = useState(false);

	const dispatch = useDispatch();
	const location = useLocation();
	const history = useHistory();

	const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
	const userObj = useSelector(state => state.auth.data);
	const authState = useSelector(state => state.auth);

	// const user = renderUsername(userObj);

	//RENDER USER IMAGE ON THE HEADER
	function renderUserImage() {
		if (isEmpty(userObj)) {
			// console.log(`%c [Auth status]: No user logged in.`, 'background: #4c4a4a; color: #ff3d60');
			return undefined;
		}
		const parsedUserObj = JSON.parse(userObj);
		if (parsedUserObj.image.includes('null')) {
			return null;
		}
		return parsedUserObj.image;
	}

	function usePageViews() {
		useEffect(() => {
			// InitializeReactGA(ReactGA);
			ReactGA.set({ page: location.pathname });
			ReactGA.pageview(location.pathname);
		}, [location]);
	}

	function handleClick(e) {
		e.preventDefault();
		setTimeout(() => {
			if (
				location.pathname === '/dashboard' ||
				location.pathname.includes('book') ||
				location.pathname === '/list-space' ||
				location.pathname === '/host-party/banking-details' ||
				location.pathname === '/host-party/planning-overview' ||
				location.pathname === '/host-party/planning-details'
			) {
				setExpanded(false);
				history.push('/');
			}
			dispatch(unloadCurrentUser(authState));
		}, 2000);
	}

	useEffect(() => {
		if (isAuthenticated) {
			setShow(false);
		}

		if (
			(location.pathname === '/dashboard' ||
				location.pathname.includes('book')) &&
			// location.pathname === "/list-space")
			!isAuthenticated
		) {
			history.push('/');
		}
	}, [isAuthenticated, history, location]);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	usePageViews();
	return (
		<React.Fragment>
			{/* <ScrollToTop /> */}
			<Navbar
				bg="white"
				expand="lg"
				fixed="top"
				className={isAuthenticated ? `py-0` : null}
				style={
					location.pathname === '/referral' ||
					location.pathname === '/contact' ||
					location.pathname === '/dashboard'
						? { border: 'none' }
						: { borderBottom: '1px solid #333' }
				}
				expanded={expanded}
			>
				<Container>
					<Navbar.Brand as={Link} to="/">
						<img src={playace} alt="playace-logo" width="130" />
					</Navbar.Brand>
					<Navbar.Toggle
						aria-controls="basic-navbar-nav"
						onClick={() => setExpanded(expanded ? false : 'expanded')}
					/>
					<Navbar.Collapse id="basic-navbar-nav">
						<Nav className="ml-auto my-2 text-uppercase align-items-center">
							<Nav.Link
								as={NavLink}
								className="mx-2 fw-bold"
								activeClassName="selected"
								exact
								to="/"
								onClick={() => setExpanded(false)}
							>
								Home
							</Nav.Link>
							<Nav.Link
								className="mx-2 fw-bold"
								as={NavLink}
								activeClassName="selected"
								exact
								to="/parties/page/1"
								onClick={() => setExpanded(false)}
							>
								Attend a party
							</Nav.Link>
							<Nav.Link
								className="mx-2 fw-bold"
								as={NavLink}
								activeClassName="selected"
								exact
								to="/host-party"
								onClick={() => setExpanded(false)}
							>
								Host a party
							</Nav.Link>
							<Nav.Link
								className="mx-2 fw-bold"
								as={NavLink}
								activeClassName="selected"
								exact
								to="/spaces"
								onClick={() => setExpanded(false)}
							>
								Book a space
							</Nav.Link>
							{/* /////////////////////////////// */}

							{/* //////////////////////////// */}
							{/* {!isAuthenticated ? (
                <Nav.Link
                  className="mx-2 fw-bold"
                  onClick={() => {
                    handleShow();
                    setExpanded(false);
                  }}
                >
                  List a space
                </Nav.Link>
              ) : (
                <Nav.Link
                  className="mx-2 fw-bold"
                  as={NavLink}
                  activeClassName="selected"
                  exact
                  to="/list-space"
                  onClick={() => setExpanded(false)}
                >
                  List a space
                </Nav.Link>
              )} */}
							<Nav.Link
								className="mx-2 fw-bold"
								as={NavLink}
								activeClassName="selected"
								exact
								to="/list-space"
								onClick={() => setExpanded(false)}
							>
								List a space
							</Nav.Link>
							{!isAuthenticated ? (
								<Nav.Link
									className="mx-2 fw-bold"
									onClick={() => {
										handleShow();
										setExpanded(false);
									}}
								>
									Sign up/Login
								</Nav.Link>
							) : (
								<>
									<Nav.Link
										className="mx-2 fw-bold"
										as={NavLink}
										to="/dashboard"
										onClick={() => setExpanded(false)}
									>
										<div
											className="d-flex justify-content-center align-items-center"
											style={{
												height: '40px',
												width: '40px',
												borderRadius: '50%',
												overflow: 'hidden',
											}}
										>
											<Img
												placeholder={DefaultDP}
												error={DefaultDP}
												src={isEmpty(userObj) ? null : `${renderUserImage()}`}
												alt="user profile"
												className="rounded-circle m-0 p-0 img-fluid"
											/>
										</div>
									</Nav.Link>
									<Nav.Link className="mx-2 fw-bold" onClick={handleClick}>
										Logout
									</Nav.Link>
								</>
							)}
						</Nav>
					</Navbar.Collapse>
					<Default>
						<Modal
							size="lg"
							show={show}
							onHide={handleClose}
							dialogClassName="modal-dimensions"
							backdrop="static"
							centered
						>
							<Auth handleClose={handleClose} />
						</Modal>
					</Default>
					<Mobile>
						<Modal
							size="lg"
							show={show}
							onHide={handleClose}
							backdrop="static"
							centered
						>
							<Auth handleClose={handleClose} />
						</Modal>
					</Mobile>
				</Container>
			</Navbar>

			<Switch>
				<Route exact path="/" component={Home} />
				<Route exact path="?paid=true" component={Home} />
				{/* <Suspense fallback={<p>Loading...</p>}> */}
				<Route exact path="/parties/page/:pagenumber" component={Parties} />
				<Route exact path="/parties/:id" component={PartiesDetail} />
				<Route exact path="/spaces" component={Spaces} />
				<Route exact path="/spaces/:id" component={SpacesDetail} />
				<Route exact path="/booking/party/:id" component={BookParty} />
				<Route exact path="/booking/space/:id" component={BookSpace} />
				<Route exact path="/dashboard" component={Profile} />
				<Route exact path="/list-space" component={ListASpace} />
				<Route exact path="/host-party" component={HostAParty} />
				<Route exact path="/host-party/upload-cover" component={UploadCover} />
				<Route
					exact
					path="/host-party/planning-overview"
					component={PlanningOverview}
				/>
				{/* </Suspense> */}
				<Route
					exact
					path="/host-party/planning-details"
					component={PlanningDetails}
				/>
				<Route
					exact
					path="/host-party/banking-details"
					component={BankingDetails}
				/>
				<Route exact path="/about" component={AboutUs} />
				<Route exact path="/referral" component={Referral} />
				<Route exact path="/contact" component={Contact} />
				<Route exact path="/refunds" component={Refunds} />
				<Route exact path="/terms" component={TermsAndConditions} />
				<Route exact path="/privacy" component={PrivacyPolicy} />
				<Route exact path="/disclaimer" component={Disclaimer} />
				<Route exact path="/faq" component={Faq} />
				<Route path="*">
					<NotFoundPage />
				</Route>
				<Route exact path="/dashboard">
					{!isAuthenticated ? (
						<Redirect to="/" />
					) : (
						<Suspense fallback={<p>Loading...</p>}>
							<Profile />
						</Suspense>
					)}
				</Route>
				<Route exact path="/list-space">
					{!isAuthenticated ? (
						<Redirect to="/" />
					) : (
						<Suspense fallback={<p>Loading...</p>}>
							<ListASpace />
						</Suspense>
					)}
				</Route>
			</Switch>
		</React.Fragment>
	);
}
