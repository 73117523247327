import React, { useState } from "react";

import { ListGroup, Dropdown } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-regular-svg-icons";

import { useHistory } from "react-router-dom";
import styles from "./PartiesFilterNav.module.scss";
import { useEffect } from "react";

export default function PartiesFilterNav({ filter, onSetFilter }) {
  const history = useHistory();

  // let [dropdown, setDropdown] = useState(false);

  // useEffect(() => {
  //   setDropdown(false);
  // }, []);

  return (
    <div className="filters-container">
      <button
        onClick={() => {
          onSetFilter("all");
          history.push({ search: "?filter=all" });
        }}
        className={`btn btn-small btn-reg mx-1 filter-btn ${filter === "all" ? "selected-filter" : null
          }`}
      >
        <small>All</small>
      </button>
      <button
        onClick={() => {
          onSetFilter("today");
          history.push({ search: "?filter=today" });
        }}
        className={`btn btn-small btn-reg mx-1 filter-btn ${filter === "today" ? "selected-filter" : null
          }`}
      >
        <small>Today</small>
      </button>
      <button
        onClick={() => {
          onSetFilter("tomorrow");
          history.push({ search: "?filter=tomorrow" });
        }}
        className={`btn btn-small btn-reg mx-1 filter-btn ${filter === "tomorrow" ? "selected-filter" : null
          }`}
      >
        <small>Tomorrow</small>
      </button>
      {/* <button
        onClick={() => onSetFilter("city")}
        className={`btn btn-small btn-reg mx-1 filter-btn ${
          filter === "city" ? "selected-filter" : null
        }`}
      >
        <small>City</small>
      </button> */}
      {/* <button
        onClick={() => {
          setDropdown(!dropdown);
        }}
        className={`btn btn-small btn-reg mx-1 filter-btn`}
      >
        <small>Location</small>
      </button> */}
      <Dropdown>
        <Dropdown.Toggle id="dropdown-basic" style={{ color: "#212529", backgroundColor: "#ffffff" }} className={`${styles["dropdown-toggle"]} btn btn-small btn-reg mx-1 filter-btn`}>
          <small>Location</small>
        </Dropdown.Toggle>
        <Dropdown.Menu  >
          <Dropdown.Item
            onClick={() => {
              onSetFilter("mumbai");
              history.push({ search: "?filter=mumbai" });
            }}>Mumbai
          </Dropdown.Item>
          <Dropdown.Item onClick={() => {
            onSetFilter("pune");
            history.push({ search: "?filter=pune" });
          }}>Pune</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      {/* <ListGroup
        className={
          dropdown
            ? `${styles.dropdown} ${styles.show}`
            : `${styles.dropdown} ${styles.hide}`
        }
      >
        <ListGroup.Item
          action
          onClick={() => {
            onSetFilter("mumbai");
            history.push({ search: "?filter=mumbai" });
          }}
        >
          Mumbai
        </ListGroup.Item>
        <ListGroup.Item
          action
          onClick={() => {
            onSetFilter("pune");
            history.push({ search: "?filter=pune" });
          }}
        >
          Pune
        </ListGroup.Item>
      </ListGroup> */}
      <button
        onClick={() => {
          onSetFilter("favorites");
          history.push({ search: "?filter=favorites" });
        }}
        className={`btn btn-small btn-reg mx-1 filter-btn ${filter === "favorites" ? "selected-filter" : null
          }`}
      >
        <FontAwesomeIcon icon={faHeart} size="lg"></FontAwesomeIcon>
      </button>
    </div>
  );
}
