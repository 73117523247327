import React from "react";
import { Container } from "react-bootstrap";

export default function AboutUs() {
  return (
    <Container style={{ margin: "6rem auto 0 auto" }}>
      <div className="w-75 mx-auto">
        <h2 className="text-center mb-5">About us</h2>
        <div>
          <h4 className="my-4">The Idea</h4>
          <p>
            Our founder always loved house parties over other avenues for
            socialising. For him, other avenues either had the hassles of
            getting dressed, stressing to get the name in the guest list and
            then being judged by the bouncer at the entrance of a night club or
            the rest of the options were loud or expensive. <br /> <br /> His
            idea of socialising is catching up with people in a very casual
            space where he can have a conversation over a good laugh. And what
            better place can it be than a house party? He observed, others too
            prefer house parties and get excited with the thought of it. But we
            don’t get to be a part of it often. Why? He realised as a culture
            most of us stay with parents and we always want the house to be
            entirely to us to host a house party. We wait for our folks to go
            out and therefore a house party becomes a rare occasion.
          </p>
          <h4 className="my-4">The Eureka Moment</h4>
          <p>
            In 2015, our founder came across a website called Airbnb.com and for
            him it was love at first sight. He fell in love with the concept,
            the design, the language and everything about it. In his free time,
            he logged on to Airbnb.com and simply explored it. In 2016, he got
            an opportunity to go to LA and where else could he book the stay? He
            was really excited and looking forward to the entire AIRBNB
            experience. <br />
            <br />
            In LA, on a Friday evening, while he was waiting for an uber, he saw
            a young bunch with bags full of beverages entering the apartment.
            Seeing them go to a house party made him nostalgic. With all the
            thoughts in his head in that surrounding, arrived a eureka moment.
            He thought, why not create an Airbnb for House Parties and that’s
            where it all began. <br />
            <br /> He saw this opportunity and wanted to create a platform which
            can connect people looking to attend house parties with people
            willing to host them. <br />
            <br /> Our Founder came back to India and started working on the
            idea. After many initial challenges, we launched Playace.co on 11th
            November 2017 with the first-ever party hosted in Mumbai. <br />
            <br /> Today after a few years, we have successfully hosted hundreds
            of house parties across various cities in the country with zero
            mishaps and taking the opportunity along the way to work with
            various brands both domestic and international.
          </p>
          <h4 className="my-4">Why the name 'Playace'?</h4>
          <p>
            A lot of people pronounce it in different ways. But it kinda works
            for us as thought is put behind to wonder what exactly is it. It is
            pronounced as ‘PLACE’, for a simple reason, whenever you make plans,
            one of the first questions you ask is, ‘Which place are we going?’,
            ‘Is it a good place?’, or ‘Which place shall we go to?’ etc.
            Therefore all you need is a good place to have a good time. <br />
            Also, in this entire process, a host is a very important aspect.
            Therefore we often tell them to Play the Ace, which means to play
            the good host. So, it is also a wordplay in a way and it sounds fun
            too.
          </p>
          <h4 className="my-4">Why people host and attend with us?</h4>
          <p>
            A lot of effort, time and multiple trial and errors have gone behind
            to create the recipe. These trial and errors have helped us base our
            products, services, pricing recommendations and safety protocols,
            after all, we are creating a product for people. Even today every
            day is a new day with a new learning which is applied to our
            offerings. <br />
            <br /> Playace.co enables people to host and attend safe and secured
            house parties conveniently with like-minded strangers. <br />
            <br />
            Hosting allows you to not only meet new people over shared interests
            but also earn while having fun. With us, the regular hosts are able
            to earn enough amount to fulfill their aspirations. Some utilise
            this amount to pay their rents, some to buy appliances and some on
            beauty and wellness. <br />
            <br /> Initially, the idea on paper was very different. But
            gradually as things were evolving, the original idea needed a slight
            pivot based on the users needs and acceptance. We started observing
            that people were attending more importantly to talk. On learning
            more we realised guests sought comfort in opening to strangers. It’s
            true if you give it a thought, it’s always easier to speak to a
            stranger without the fear of being judged. And that’s majorly what
            happens at these house parties. You may not see the people again you
            meet here, unless you want to. <br />
            <br /> The best part about attending the house parties on playace.co
            is that you get to meet different hosts and different guests, you
            get to attend new themes at different venues, it’s always a new
            experience every time.
          </p>
          <h4 className="my-4">How we changed lives</h4>
          <p>
            It is a good feeling and gives us a sense of purpose to know that we
            were able to touch lives. We never really thought that a simple idea
            could do this and this realisation makes us more responsible and
            every day we strive hard to keep continuing. We feel that if we
            could change one life a day, we are doing something meaningful. And
            if we are able to build a chain, one day we would be make this world
            a happier PLAYACE. <br />
            <br /> We have so many stories and testimonials to share, right from
            how people got over the loss of their loved ones, how people got
            over depression, how two strangers met to become friends to help
            each other in the crucial times of their lives, how people moved to
            a new city and formed their own community to overcome urban
            isolation, how two boys who were in love with each other booked
            separately for the same party and got acceptance by the end of it.
            There are many such stories and we are creating more. <br />
            <br />
            We often face this initial resistance from people when we say house
            parties. They generally think that it is going to be loud, wild,
            messy etc. But honestly, if you ask yourself, what do you do at a
            house party, you would get the answer. For us, a house party means a
            cosy place where like-minded people gather over shared interests to
            do what they love. When a bunch of musicians or storytellers or
            foodies etc gather under one roof, especially a home to share their
            interests, they are having a party of their own. <br />
            <br /> Why the term house parties? Because it is attractive and
            exciting more importantly. Home has a certain warmth and
            belongingness. And we genuinely want people, especially the hosts to
            find the true potential of their home, while they stay in it. <br />
            <br /> Many industry experts asked us to change the term ‘House
            Parties’ to something else to make it sound more friendly and wise.
            We asked them would our business sound exciting to them at the first
            word if we told them that we were into the business of ‘Home Hosted
            Experiences’ or ‘Events inside Homes’? They sound bland, don’t they?
            And guess what, they were convinced. We have struggled over the
            years but still believed that we will get over this mental block and
            without confusing our users stuck to House Parties. <br />
            <br /> If you want to enhance your life with a secondary source of
            income and create communities, open your doors to host a house party
            and let them (people) in. If you are saturated with your regular
            socialising options or want to go to a more casual place or are
            stired of your friends ditching you every time and do not want to
            depend on them to make plans or want to meet like-minded strangers,
            your reason could be any, book a house party today, you never know,
            which one might change your life for good. <br />
            <br /> Well, we can go on and on about Playace.co, but to bring an
            end, for now, let us modify a little what Forrest Gump said ‘House
            Party is like a box of chocolates, you never know what you gonna
            get’. So come, experience a house party today!
          </p>
        </div>
      </div>
    </Container>
  );
}
