import React, { useRef, useEffect, useState } from "react";
import styles from "./InputField.module.scss";

export default function InputField(props) {
  let [firstRender, setfirstRender] = useState(false);
  const inputRef = useRef();

  useEffect(() => {
    setfirstRender(true);

    return () => {
      setfirstRender(false);
    };
  }, []);

  function focusInput(listener) {
    if (listener === "focus") {
      inputRef.current.classList.add("input-control");
      // inputRef.current.classList.add("InputField_input-control__8eVjG");
      // console.log("class added");
    }
    if (listener === "blur" && inputRef.current.value === "") {
      inputRef.current.classList.remove("InputField_input-control__8eVjG");
      // console.log("class removed");
    }
  }

  const handleFocus = () => {
    if (firstRender) {
      // console.log("running" + inputRef.current);
      inputRef.current.addEventListener("focus", focusInput("focus"));
    }
  };

  // const handleBlur = () => {
  //   inputRef.current.addEventListener("blur", focusInput("blur"));
  // };

  //PURE REACT IMPLEMENTATION////////////////
  // const handleFocus = (e) => {
  //   e.target.classList.add("InputField_input-control__8eVjG");
  // };

  // const handleBlur = (e) => {
  //   if (e.target.value === "") {
  //     e.target.classList.remove("InputField_input-control__8eVjG");
  //   }
  // };
  ///////////////////////////////////////

  return (
    <div className={styles["input-wrapper"]}>
      <input
        ref={inputRef}
        className={styles["input-style"]}
        onClick={handleFocus}
        // onFocus={handleFocus}
        // onBlur={handleBlur}
        type={props.type || "text"}
        value={props.value}
        onChange={props.handleChange}
        pattern={props.pattern}
        name={props.name || null}
        minLength={props.minLength || null}
        maxLength={props.maxLength || null}
        autoFocus={props.autofocus || null}
        required
      />
      {/* <label className={styles["input-label"]}>{props.label}</label> */}
      <label className="input-label">{props.label}</label>
    </div>
  );
}
