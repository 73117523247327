import React from "react";
import Img from "react-cool-img";

import AAP from "../../../assets/img/attend a party.png";
import styles from "./Party.module.scss";

import moment from "moment";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";
import { fetchUpcomingPartiesAsHost } from "../../../../api/requests";
import { Loader } from "../../../helpers";
import { v4 } from "uuid";

export default function AsHost({ token, ...props }) {
  const { data, isLoading } = useQuery(
    ["FETCH_PARTIES_AS_HOST", token],
    fetchUpcomingPartiesAsHost,
    {
      retry: 1,
      refetchOnWindowFocus: false,
    }
  );
  return (
    <div className={styles["as-a-host"]}>
      <p className="fw-bold text-uppercase text-primary">As a host</p>
      <div>
        {isLoading && (
          <div className={styles["loading-overlay"]}>
            <Loader />
          </div>
        )}
        {data && data.data.length === 0 && <p>No parties hosted.</p>}
        {data &&
          data.data.map((party) => {
            if (party.review_status === 0 || party.review_status === 2) {
              return <PartyCardWithoutImage key={v4()} {...party} />;
            } else {
              return <PartyCardWithImage key={v4()} {...party} />;
            }
          })}

        <Link to="/host-party" className="btn btn-primary fw-bold mt-5">
          ADD A PARTY
        </Link>
      </div>
    </div>
  );
}

function PartyCardWithImage(props) {
  // console.log(props);
  return (
    <div className={`${styles["card-with-img"]} shadow`}>
      <div className={styles["card-with-img__img"]}>
        <Img
          style={{
            borderTopLeftRadius: "15px",
            borderBottomLeftRadius: "15px",
            objectFit: "cover",
          }}
          src={props.image}
          alt="party attending"
        />
      </div>
      <div className={`${styles["card-with-img__content"]} py-3 pr-3 ml-3`}>
        <div className="d-flex justify-content-between">
          <div>
            <h5 className="fw-bold">{props.heading}</h5>
            <small className="m-0 text-muted">
              {moment(props.date).format("MMM DD")} | {props.time_from}-
              {props.time_to}
            </small>
            <br />
            <small className="m-0 text-muted">
              {/* USE CITYNAME and LOCALITY */}
              {props.location && props.location.locality}, {props.location && props.location.location}
            </small>
          </div>
          <div>
            {/**Price field not available, update when price is available, same for  ticket count*/}
            <h5 className="fw-bold mr-1 price">&#8377; {props.amount}</h5>
          </div>
        </div>
        {/* <div className="d-flex justify-content-between mr-3"> */}
        {/* <p className="text-primary m-0 p-0">LIVE</p> */}
        {/* <p className="text-primary text-right fw-bold m-0">TICKETS SOLD: {props.heading}</p> */}
        {/* </div> */}
      </div>
    </div>
  );
}

function PartyCardWithoutImage(props) {
  return (
    <div className={`${styles["card-without-img"]} shadow mb-3`}>
      <div className={`${styles["card-without-img__content"]}`}>
        <div className="d-flex justify-content-between p-3">
          <div>
            <h5 className="fw-bold">{props.heading}</h5>
            <small className="m-0 text-muted">
              {moment(props.date).format("MMM DD")} | {props.time_from}-
              {props.time_to}
            </small>
            <br />
            <small className="m-0 text-muted">
              {/* USE CITYNAME and LOCALITY */}
              {props.location && props.location.locality}, {props.location && props.location.location}
            </small>
          </div>
          <div className="d-flex flex-column justify-content-between">
            <h5 className="fw-bold text-right price">&#8377; {props.amount}</h5>
            <p className="fw-bold text-primary m-0">
              {props.review_status === 0 ? <>PENDING</> : <>REJECTED</>}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
