import React from "react";
import { Link } from "react-router-dom";
import { Default, Mobile } from "../../../helpers/Breakpoints";
import SpacesImg from "../../../assets/img/attend host earn.png";

export default function SpacesInfo() {
  return (
    <>
      <Default>
        <img
          src={SpacesImg}
          className="img-fluid"
          style={{ height: "auto", width: "180px" }}
          alt="Spaces icon"
        />
        <div className="text-center">
          <h4 className="text-uppercase text-secondary">Spaces</h4>
          <p>None listed</p>
          <Link to="/list-space" className="btn btn-secondary btn-reg fw-bold text-uppercase my-4">
            List now
          </Link>
        </div>
      </Default>
      <Mobile>
        <img
          src={SpacesImg}
          className="img-fluid"
          style={{ height: "auto", width: "180px" }}
          alt="Spaces icon"
        />
        <div className="text-center">
          <h4 className="text-uppercase text-secondary">Spaces</h4>
          <p>None listed</p>
          <Link to="/list-space" className="btn btn-secondary btn-reg fw-bold text-uppercase my-4">
            List now
          </Link>
        </div>
      </Mobile>
    </>
  );
}
