import React from "react";

import { RenderUserObj } from "../../../helpers";
import { useSelector } from "react-redux";

import styles from "./Party.module.scss";
import AsHost from "./AsHost";
import AsGuest from "./AsGuest";
import { Default, Mobile } from "../../../helpers/Breakpoints";

function Party() {
  const user = RenderUserObj(useSelector((state) => state.auth.data));

  return (
    <>
      <Default>
        <div className={styles.container}>
          <AsHost token={user.token} />
          <AsGuest token={user.token} />
        </div>
      </Default>
      <Mobile>
        <div>
          <AsGuest token={user.token} />
          <AsHost token={user.token} />
        </div>
      </Mobile>
    </>
  );
}

export default Party;
