import NoFood from './Pink cross icons-01.svg';
import NoNonVeg from './Pink cross icons-02.svg';
import NoSmoking from './Pink cross icons-03.svg';
import NoAlcohol from './Pink cross icons-04.svg';
import NoVeg from './Pink cross icons-05.svg';
import NoParking from './Pink cross icons-06.svg';
import NoKids from './Playace icon Swatches-27.svg';
import NoElevator from './Pink cross icons-09.svg';
import NoAC from './Pink cross icons-10.svg';
import NoPets from './Pink cross icons-13.svg';
import NoGuestPets from './Pink cross icons-15.svg';
import NoFootwear from './Playace icon Swatches-23.svg';

const PartiesCrossImages = {
    NoFood, NoNonVeg, NoSmoking, NoAlcohol, NoVeg, NoParking, NoKids, NoElevator, NoAC, NoPets, NoGuestPets, NoFootwear
};

export default PartiesCrossImages;
