import React, { useCallback } from "react";
import { Container } from "react-bootstrap";

import { Default, Mobile } from "../../helpers/Breakpoints";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuoteLeft } from "@fortawesome/free-solid-svg-icons";

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/swiper.scss";

import { brandLogos } from "../../../assets/brand-logos";

import Carousel from "react-bootstrap/Carousel";
import SwiperCore, { Autoplay } from "swiper";

SwiperCore.use([Autoplay]);

function Testimonial() {
  const MemoizedTestimonialSlide = useCallback(() => {
    return TestimonialSlide();
  }, []);

  const MemoizedBrandsSlideDesktop = useCallback(() => {
    return BrandsSlideDesktop();
  }, []);

  const MemoizedBrandsSlideMobile = useCallback(() => {
    return BrandsSlideMobile();
  }, []);

  return (
    <section className="jumbotron-fluid jumbotron-section">
      <Container className="my-5 text-center">
        <h3>Trusted by</h3>
        <Default>
          <MemoizedBrandsSlideDesktop />
        </Default>
        <Mobile>
          <MemoizedBrandsSlideMobile />
        </Mobile>
        <MemoizedTestimonialSlide />
      </Container>
    </section>
  );
}

function TestimonialSlide() {
  let data = [
    {
      id: 1,
      name: "Debashish Das",
      content:
        "It's the strangers who make these beautiful house parties wonderful. I loved it Team Playace, you have rocked again.",
      interval: 5000,
    },
    {
      id: 2,
      name: "Keerti Singh",
      content:
        "My house parties are centered on travel experiences. People come here and share their travel experiences - both good and bad, we also have interesting activities centered on traveling.",
      interval: 5000,
    },
    {
      id: 3,
      name: "Purnima Sehr",
      content:
        "I met great people and made some new friends too. Thanks Playace, I am going to keep coming.",
      interval: 5000,
    },
  ];

  return (
    <Container
      style={{
        paddingTop: "4rem",
        paddingBottom: "8rem",
        borderTop: "1px solid #a1a1a1",
        borderBottom: "1px solid #a1a1a1",
      }}
    >
      <p>
        <FontAwesomeIcon
          icon={faQuoteLeft}
          size="3x"
          className="text-primary mb-4"
        ></FontAwesomeIcon>
      </p>
      <Carousel>
        {data.map((quote) => {
          return (
            <Carousel.Item
              key={quote.id}
              className="text-dark"
              interval={quote.interval}
            >
              <div className="testimonial-slider">
                <h5 style={{ lineHeight: "1.5" }}>{quote.content}</h5>
                <p className="mt-5">
                  {quote.name} <br />
                  {quote.location}
                </p>
              </div>
            </Carousel.Item>
          );
        })}
      </Carousel>
    </Container>
  );
}

function BrandsSlideDesktop() {
  return (
    <Swiper autoplay spaceBetween={0} slidesPerView={5}>
      <SwiperSlide>
        <img
          src={brandLogos.bacardi}
          className="img-fluid"
          alt="bacardi"
          height="50%"
          //width="220"
          width="50%"
        />
      </SwiperSlide>
      <SwiperSlide>
        <img
          src={brandLogos.starSportsSelect}
          className="img-fluid"
          alt="star sports select"
          height="50%"
          //width="150"
          width="50%"
        />
      </SwiperSlide>
      <SwiperSlide>
        <img
          src={brandLogos.ITC}
          className="img-fluid"
          alt="ITC limited"
          height="30%"
          //width="110"
          width="30%"
        />
      </SwiperSlide>
      <SwiperSlide>
        <img
          src={brandLogos.WeWork}
          className="img-fluid"
          alt="wework"
          height="50%"
          //width="250"
          width="50%"
        />
      </SwiperSlide>
      <SwiperSlide>
        <img
          src={brandLogos.OML}
          className="img-fluid"
          alt="OML"
          height="50%"
          //width="250"
          width="50%"
        />
      </SwiperSlide>
      <SwiperSlide>
        <img
          src={brandLogos.chilis}
          className="img-fluid"
          alt="chili's"
          height="50%"
          //width="150"
          width="40%"
        />
      </SwiperSlide>
      <SwiperSlide>
        <img
          src={brandLogos.crossword}
          className="img-fluid"
          alt="crossword"
          height="50%"
          //width="220"
          width="50%"
        />
      </SwiperSlide>
      <SwiperSlide>
        <img
          src={brandLogos.whiteOwl}
          className="img-fluid"
          alt="whiteowl"
          height="50%"
          //width="280"
          width="50%"
        />
      </SwiperSlide>
      <SwiperSlide>
        <img
          src={brandLogos.dotShot}
          className="img-fluid"
          alt="dotshot"
          height="50%"
          // width="100"
          width="25%"
        />
      </SwiperSlide>
      <SwiperSlide>
        <img
          src={brandLogos.pizzeria1441}
          className="img-fluid"
          alt="1441 pizzeria"
          height="50%"
          // width="150"
          width="35%"
        />
      </SwiperSlide>
      <SwiperSlide>
        <img
          src={brandLogos.brewHouse}
          className="img-fluid"
          alt="brew house"
          height="50%"
          //width="150"
          width="35%"
        />
      </SwiperSlide>
      <SwiperSlide>
        <img
          src={brandLogos.svami}
          className="img-fluid"
          alt="svami"
          height="50%"
          //width="185"
          width="40%"
        />
      </SwiperSlide>
      <SwiperSlide>
        <img
          src={brandLogos.harshChocolates}
          className="img-fluid"
          alt="harsh chocolates"
          height="50%"
          //width="100"
          width="25%"
        />
      </SwiperSlide>
      <SwiperSlide>
        <img
          src={brandLogos.workAmp}
          className="img-fluid"
          alt="workAmp"
          height="50%"
          //width="250"
          width="40%"
        />
      </SwiperSlide>
      <SwiperSlide>
        <img
          src={brandLogos.OnoTeas}
          className="img-fluid"
          alt="ono teas"
          height="50%"
          //width="250"
          width="50%"
        />
      </SwiperSlide>
      <SwiperSlide>
        <img
          src={brandLogos.rageCoffee}
          className="img-fluid"
          alt="rage coffee"
          height="50%"
          //width="250"
          width="50%"
        />
      </SwiperSlide>
      <SwiperSlide>
        <img
          src={brandLogos.things2Do}
          className="img-fluid"
          alt="things 2 do"
          height="50%"
          //width="150"
          width="50%"
        />
      </SwiperSlide>
      <SwiperSlide>
        <img
          src={brandLogos.sipWise}
          className="img-fluid"
          alt="sipwise"
          height="50%"
          //width="180"
          width="50%"
        />
      </SwiperSlide>
      <SwiperSlide>
        <img
          src={brandLogos.zolo}
          className="img-fluid"
          alt="zolo"
          height="50%"
          //width="100"
          width="30%"
        />
      </SwiperSlide>
      <SwiperSlide>
        <img
          src={brandLogos.limese}
          className="img-fluid"
          alt="limese"
          height="50%"
          //width="150"
          width="35%"
        />
      </SwiperSlide>
    </Swiper>
  );
}

function BrandsSlideMobile() {
  return (
    <Swiper autoplay spaceBetween={20} slidesPerView={4} className="my-4">
      <SwiperSlide>
        <img
          src={brandLogos.bacardi}
          className="img-fluid"
          alt="bacardi"
          height="50"
          width="220"
        />
      </SwiperSlide>
      <SwiperSlide>
        <img
          src={brandLogos.starSportsSelect}
          className="img-fluid"
          alt="star sports select"
          height="150"
          width="150"
        />
      </SwiperSlide>
      <SwiperSlide>
        <img
          src={brandLogos.ITC}
          className="img-fluid"
          alt="ITC limited"
          height="40"
          width="50"
        />
      </SwiperSlide>
      <SwiperSlide>
        <img
          src={brandLogos.WeWork}
          className="img-fluid"
          alt="wework"
          height="250"
          width="250"
        />
      </SwiperSlide>
      <SwiperSlide>
        <img
          src={brandLogos.OML}
          className="img-fluid"
          alt="OML"
          height="250"
          width="250"
        />
      </SwiperSlide>
      <SwiperSlide>
        <img
          src={brandLogos.chilis}
          className="img-fluid"
          alt="chili's"
          height="150"
          width="150"
        />
      </SwiperSlide>
      <SwiperSlide>
        <img
          src={brandLogos.crossword}
          className="img-fluid"
          alt="crossword"
          height="50"
          width="220"
        />
      </SwiperSlide>
      <SwiperSlide>
        <img
          src={brandLogos.whiteOwl}
          className="img-fluid"
          alt="whiteowl"
          height="100"
          width="280"
        />
      </SwiperSlide>
      <SwiperSlide>
        <img
          src={brandLogos.dotShot}
          className="img-fluid"
          alt="dotshot"
          height="50"
          width="50"
        />
      </SwiperSlide>
      <SwiperSlide>
        <img
          src={brandLogos.pizzeria1441}
          className="img-fluid"
          alt="1441 pizzeria"
          height="100"
          width="100"
        />
      </SwiperSlide>
      <SwiperSlide>
        <img
          src={brandLogos.brewHouse}
          className="img-fluid"
          alt="brew house"
          height="100"
          width="100"
        />
      </SwiperSlide>
      <SwiperSlide>
        <img
          src={brandLogos.svami}
          className="img-fluid"
          alt="svami"
          height="30"
          width="145"
        />
      </SwiperSlide>
      <SwiperSlide>
        <img
          src={brandLogos.harshChocolates}
          className="img-fluid"
          alt="harsh chocolates"
          height="50"
          width="50"
        />
      </SwiperSlide>
      <SwiperSlide>
        <img
          src={brandLogos.workAmp}
          className="img-fluid"
          alt="workAmp"
          height="250"
          width="250"
        />
      </SwiperSlide>
      <SwiperSlide>
        <img
          src={brandLogos.OnoTeas}
          className="img-fluid"
          alt="ono teas"
          height="50"
          width="250"
        />
      </SwiperSlide>
      <SwiperSlide>
        <img
          src={brandLogos.rageCoffee}
          className="img-fluid"
          alt="rage coffee"
          height="250"
          width="250"
        />
      </SwiperSlide>
      <SwiperSlide>
        <img
          src={brandLogos.things2Do}
          className="img-fluid"
          alt="things 2 do"
          height="50"
          width="150"
        />
      </SwiperSlide>
      <SwiperSlide>
        <img
          src={brandLogos.sipWise}
          className="img-fluid"
          alt="sipwise"
          height="60"
          width="180"
        />
      </SwiperSlide>
      <SwiperSlide>
        <img
          src={brandLogos.zolo}
          className="img-fluid"
          alt="zolo"
          height="30"
          width="60"
        />
      </SwiperSlide>
      <SwiperSlide>
        <img
          src={brandLogos.limese}
          className="img-fluid"
          alt="limese"
          height="50"
          width="150"
        />
      </SwiperSlide>
    </Swiper>
  );
}

export default Testimonial;
