import React, { useState } from 'react';
import { Row, Col, Modal } from 'react-bootstrap';
import { Default, Login, Signup } from './index';
import axios from 'axios';
import { URL } from '../../../api/requests';
import styles from './Auth.module.scss';
import { Default as Desktop, Mobile } from '../../helpers/Breakpoints';

import loginGraphic from '../../../assets/login_graphic.svg';

//returns a switch statement rendering based on whether otp has been sent
//and whether account exists
export default function Auth(props) {
	let [phone, setPhone] = useState('');
	let [otpsent, setOtpSent] = useState(null);
	let [newUser, setNewUser] = useState(false);

	const handleSubmitRequest = e => {
		e.preventDefault();
		//TODO: Validation for phone number
		axios
			.post(`${URL}v1/otp/send`, {
				opt_type: 'mobile',
				email: '',
				mobile: phone,
			})
			.then(response => {
				if (response.data.message === 'otp sent.') {
					setOtpSent(true);
					// console.log('Set to true');
				} else if (response.data.message === 'User not found.') {
					setOtpSent(false);
					// console.log('Set to false');
				}
			})
			.catch(errors => console.log(errors));
		// setPhone("");
	};

	const handleChange = e => {
		setPhone(e.target.value);
	};

	let isOTPSent = otpsent;

	return (
		<React.Fragment>
			<Desktop>
				<Modal.Body className="p-0 pl-4">
					<Row>
						<Col
							md={6}
							xs={12}
							className="d-flex flex-column justify-content-center py-5"
						>
							{(() => {
								// console.log('running');
								switch (isOTPSent) {
									case true:
										// console.log(isOTPSent);
										return (
											<Login
												phone={phone}
												setNewUser={setNewUser}
												handleClose={props.handleClose}
												handleHost={props.handleHost}
											/>
										);
									case false:
										return (
											<div>
												<Signup
													phone={phone}
													setNewUser={setNewUser}
													setOTPSent={setOtpSent}
												/>
												<small
													className="d-block make-pointer text-primary"
													onClick={() => {
														setOtpSent(null);
														setNewUser(!newUser);
													}}
												>
													Already have an account? Login.
												</small>
											</div>
										);

									default:
										return (
											<Default
												handleSubmit={handleSubmitRequest}
												handleChange={handleChange}
												phone={phone}
												setNewUser={setNewUser}
												newUser={newUser}
											/>
										);
								}
							})()}
						</Col>
						<Col md={6} xs={12} className="bg-primary d-flex flex-column py-5">
							<button
								className={`btn btn-primary text-white ${styles.closebtn}`}
								onClick={props.handleClose}
							>
								X
							</button>
							<h4 className="text-white text-center">
								Our doors are always <br />
								welcome to new guests!
							</h4>
							{!newUser ? (
								<h4 className="text-white text-center mt-5">Login now!</h4>
							) : (
								<h4 className="text-white text-center mt-5">Signup now!</h4>
							)}
							<img
								className="mt-3 d-block mx-auto"
								src={loginGraphic}
								alt="login playace"
								width="160px"
							/>
						</Col>
					</Row>
				</Modal.Body>
			</Desktop>
			<Mobile>
				<Modal.Header closeButton></Modal.Header>
				<Modal.Body className="px-4">
					<div className="d-flex flex-column justify-content-center">
						{(() => {
							// console.log('running');
							switch (isOTPSent) {
								case true:
									// console.log(isOTPSent);
									return (
										<Login
											phone={phone}
											setNewUser={setNewUser}
											handleClose={props.handleClose}
											handleHost={props.handleHost}
										/>
									);
								case false:
									return (
										<div>
											<Signup
												phone={phone}
												setNewUser={setNewUser}
												setOTPSent={setOtpSent}
											/>
											<small
												className="d-block make-pointer text-primary"
												onClick={() => {
													setOtpSent(null);
													setNewUser(!newUser);
												}}
											>
												Already have an account? Login.
											</small>
										</div>
									);

								default:
									return (
										<Default
											handleSubmit={handleSubmitRequest}
											handleChange={handleChange}
											phone={phone}
											setNewUser={setNewUser}
											newUser={newUser}
										/>
									);
							}
						})()}
					</div>
					{/* <Col md={6} xs={12} className="bg-primary d-flex flex-column py-5">
            <button
              className={`btn btn-primary text-white ${styles.closebtn}`}
              onClick={props.handleClose}
            >
              X
            </button>
            <h4 className="text-white text-left">New guest at the party?</h4>
            {!newUser ? (
              <h4 className="text-white text-left">Login now!</h4>
            ) : (
              <h4 className="text-white text-left">Signup now!</h4>
            )}
            <img
              className="mt-3 d-block mx-auto"
              src={loginGraphic}
              alt="login playace"
              width="160px"
            />
          </Col> */}
				</Modal.Body>
			</Mobile>
		</React.Fragment>
	);
}
