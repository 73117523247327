import React from "react";
import { Button } from "react-bootstrap";

export default function PetsComponent({ setAmenities, amenities }) {
  return (
    <div>
      <label>
        <h4>Pets at home</h4>
      </label>
      <div className="d-flex justify-content-between mb-3">
        <Button
          variant={amenities.pet === 1 ? `primary` : `shadow shadow-sm`}
          size="reg"
          block
          onClick={() => {
            setAmenities((prev) => ({ ...prev, pet: 1 }));
          }}
        >
          Yes
        </Button>
        <Button
          variant={amenities.pet === 2 ? `primary` : `shadow shadow-sm`}
          size="reg"
          className="m-0"
          block
          onClick={() => {
            setAmenities((prev) => ({ ...prev, pet: 2 }));
          }}
        >
          No
        </Button>
      </div>
      {/* Guest pet */}
      <label>
        <h4>Are guests allowed to bring pets?</h4>
      </label>
      <div className="d-flex justify-content-between mb-3">
        <Button
          variant={amenities.guest_pet === 1 ? `primary` : `shadow shadow-sm`}
          size="reg"
          block
          onClick={() => {
            setAmenities((prev) => ({ ...prev, guest_pet: 1 }));
          }}
        >
          Yes
        </Button>
        <Button
          variant={amenities.guest_pet === 2 ? `primary` : `shadow shadow-sm`}
          size="reg"
          className="m-0"
          block
          onClick={() => {
            setAmenities((prev) => ({ ...prev, guest_pet: 2 }));
          }}
        >
          No
        </Button>
      </div>
    </div>
  );
}
