import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';
import tawkToScript from '../../helpers/tawkTo';

import { Container, Modal } from 'react-bootstrap';

import successfulPayment from '../../assets/3 copy.svg';

import Slide from './Slide';
import Ahe from './Ahe';
import Attend from './Attend';
import CTA from './CTA';
import Spaces from './Spaces';
import Services from './Services';
import Testimonial from './Testimonial';

import '../../assets/index.scss';
import styles from '../../modules/auth/Auth.module.scss';

export default function Home() {
	let [showPaymentModal, setShowPaymentModal] = useState(false);
	const [closeModal, setCloseModal] = useState(false);
	let [showHostRequestModal, setShowHostRequestModal] = useState(false);

	const location = useLocation();
	const tawkToPropertyId = '5abf59814b401e45400e3a26';

	const tawkToKey = '8694642484d36335b7b0752f7689e55b60e248c3';

	useEffect(() => {
		tawkToScript(tawkToPropertyId, tawkToKey);
		ReactPixel.init('1890035134379309');
		ReactPixel.track('PageView');
	}, []);

	useEffect(() => {
		if (location.search === '?paid=true') {
			setShowPaymentModal(true);
		}
		if (location.search === '?requested=true') {
			setShowPaymentModal(true);
		}
		if (location.search === '?enquiry=true') {
			setShowPaymentModal(true);
		}
		if (location.search === '?host_request=true') {
			console.log('Hosting request sent succesfully.');
			setShowHostRequestModal(true);
		}
		// console.log(location);
	}, [location]);

	return (
		<>
			<div className="mTop">
				<Slide />
				<Ahe />
				<Attend button={true} />
				<CTA />
				<Spaces button={true} />
				<Services />
				<Testimonial />
			</div>
			<Modal
				show={showPaymentModal}
				onHide={() => {
					setShowPaymentModal(false);
				}}
				centered
			>
				<button
					className={`${styles.closebtn} bg-primary text-white rounded border-0`}
					onClick={() => {
						setShowPaymentModal(false);
					}}
				>
					X
				</button>
				<Container className=" text-center py-5">
					<img
						src={successfulPayment}
						alt="payment successful playace"
						height="150"
						width="150"
					/>
					<h3 className="fw-bold">
						{location.search === '?paid=true'
							? 'PAYMENT SUCCESSFUL!'
							: location.search === '?enquiry=true'
							? 'ENQUIRY SUCCESSFUL'
							: 'REQUEST SENT TO THE HOST!'}
					</h3>
					<p>
						{location.search === '?paid=true' ||
						location.search === '?requested=true'
							? 'Your request is being reviewed. You will be notified shortly.'
							: "Your enquiry was sent successfully. We'll email you with updates soon."}
					</p>
					<Link
						to={`/parties/page/1`}
						className="btn btn-reg btn-primary fw-bold btn-padding"
					>
						Explore more parties
					</Link>
				</Container>
			</Modal>
			<Modal
				show={showHostRequestModal}
				onHide={() => {
					setShowHostRequestModal(false);
				}}
				centered
			>
				<Container className="text-center py-5">
					<h3 className="fw-bold">Hosting Request Sent!</h3>
					<p>You will hear back from us shortly!</p>
					<Link
						to={`/parties/page/1`}
						className="btn btn-reg btn-primary fw-bold btn-padding"
					>
						Explore parties to attend
					</Link>
				</Container>
			</Modal>
		</>
	);
}

// export default React.memo(Home);
