import React, { useState } from "react";
import { Button } from "react-bootstrap";

export default function FoodComponent({ setAmenities, food, food_type }) {
  // let [food_type, setFoodType] = useState([]);
  let [foodAvail, setFoodAvail] = useState(1);
  // let updateFoodTypeArray = () => {
  //   console.log(food_type);
  // };
  return (
    <div>
      <label>
        <h4>Food served</h4>
      </label>
      <div className="d-flex justify-content-between mb-3">
        <Button
          variant={foodAvail === 1 ? `primary` : `shadow shadow-sm`}
          size="reg"
          block
          onClick={() => {
            setFoodAvail(1);
          }}
        >
          Yes
        </Button>
        <Button
          variant={foodAvail === 2 ? `primary` : `shadow shadow-sm`}
          size="reg"
          className="m-0"
          block
          onClick={() => {
            setFoodAvail(2);
          }}
        >
          No
        </Button>
        <Button
          variant={foodAvail === 3 ? `primary` : `shadow shadow-sm`}
          size="reg"
          className="m-0"
          block
          onClick={() => {
            setFoodAvail(3);
          }}
        >
          Bring your own
        </Button>
      </div>
      <label>
        <h4>Food type allowed/served</h4>
      </label>
      <div className="d-flex justify-content-between mb-3">
        <Button
          variant={food === 1 ? `primary` : `shadow shadow-sm`}
          size="reg"
          block
          onClick={() => {
            setAmenities((prev) => ({ ...prev, food: 1 }));
          }}
        >
          Veg
        </Button>
        <Button
          variant={food === 2 ? `primary` : `shadow shadow-sm`}
          size="reg"
          className="m-0"
          block
          onClick={() => {
            setAmenities((prev) => ({ ...prev, food: 2 }));
          }}
        >
          Non Veg
        </Button>
        <Button
          variant={food === 3 ? `primary` : `shadow shadow-sm`}
          size="reg"
          className="m-0"
          block
          onClick={() => {
            setAmenities((prev) => ({ ...prev, food: 3 }));
          }}
        >
          Both
        </Button>
      </div>
      {/* Food type */}
      {foodAvail === 1 && (
        <>
          <label>
            <h4>Meal menu</h4>
          </label>
          <div className="d-flex justify-content-between mb-3">
            <Button
              variant={food_type === 1 ? `primary` : `shadow shadow-sm`}
              size="reg"
              block
              onClick={() => {
                setAmenities((prev) => ({ ...prev, food_type: 1 }));
              }}
            >
              Nibbles
            </Button>
            <Button
              variant={food_type === 2 ? `primary` : `shadow shadow-sm`}
              size="reg"
              className="m-0"
              block
              onClick={() => {
                setAmenities((prev) => ({ ...prev, food_type: 2 }));
              }}
            >
              Starters
            </Button>
            <Button
              variant={food_type === 3 ? `primary` : `shadow shadow-sm`}
              size="reg"
              className="m-0"
              block
              onClick={() => {
                setAmenities((prev) => ({ ...prev, food_type: 3 }));
              }}
            >
              Main
            </Button>
            <Button
              variant={food_type === 4 ? `primary` : `shadow shadow-sm`}
              size="reg"
              className="m-0"
              block
              onClick={() => {
                setAmenities((prev) => ({ ...prev, food_type: 4 }));
              }}
            >
              Dessert
            </Button>
            <Button
              variant={food_type === 5 ? `primary` : `shadow shadow-sm`}
              size="reg"
              className="m-0"
              block
              onClick={() => {
                setAmenities((prev) => ({ ...prev, food_type: 5 }));
              }}
            >
              Bring your own
            </Button>
          </div>
        </>
      )}
    </div>
  );
}
