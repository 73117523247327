import React, { useState, useEffect } from 'react';
import { RenderUserObj } from '../../helpers';

import { postFavorite, postUnfavorite } from '../../../api/requests';

import './EventCard.module.scss';

import { Link, useLocation } from 'react-router-dom';

// import { Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faHeart } from '@fortawesome/free-regular-svg-icons';
import { faHeart as faHeartSolid } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';

import { RenderImage, RenderDate } from '../../helpers';

export default function EventCard(props) {
	let [loading, setLoading] = useState(null);
	let [favorite, setFavorite] = useState(null);
	const auth = RenderUserObj(useSelector(state => state.auth.data));
	const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
	const location = useLocation();

	useEffect(() => {
		setLoading(true);
		if (isAuthenticated) {
			setFavorite(props.event_favorite);
			// console.log(favorite);
		}
	}, [props, isAuthenticated]);
	// console.log(props);
	if (props.type === 'party') {
		// console.log(props);
		return (
			<React.Fragment>
				<div
					className={`position-relative shadow-sm ${props.classes}`}
					style={{ padding: '0 0 1rem 0', borderRadius: '8px' }}
				>
					<Link
						className="link-unstyled"
						to={{
							pathname: `/parties/${props.event_id}`,
							state: props.event_sold_out,
							location: location.pathname,
						}}
					>
						<RenderImage
							loading={loading}
							setLoading={setLoading}
							imagesrc={props.event_cover_image}
							classes="parties-heart"
							isSection={props.isSection}
						/>
						<div>
							<div className="d-flex justify-content-between mt-3 badge-price px-3">
								<p className="mb-0">
									<span
										className="badge badge-primary px-2 text-uppercase"
										style={{ borderRadius: '6px' }}
									>
										{props.category_name}
									</span>
								</p>
								<span className="d-flex flex-column align-items-end">
									<p className="fw-bold price mb-0">
										{props.amount ? (
											<span>&#x20b9; {props.amount}</span>
										) : (
											'Free'
										)}
									</p>
								</span>
							</div>

							<div className="d-flex justify-content-between">
								<h6 className="fw-bold px-3 my-2">{props.heading}</h6>
								<p className="fw-bold price mb-0">
									{props.event_sold_out ? (
										<span className="text-primary d-flex px-3 justify-content-end">
											Sold Out
										</span>
									) : (
										<span className="text-white d-flex px-3 justify-content-end">
											-
										</span>
									)}
								</p>
							</div>
							<div className="d-flex justify-content-between timeplace text-muted px-3">
								<small>
									{props.location.locality} | {props.location.city_name}
								</small>
								<small>{RenderDate(props.event_date)}</small>
							</div>
						</div>
					</Link>
					{isAuthenticated && (
						<FontAwesomeIcon
							icon={!favorite ? faHeart : faHeartSolid}
							size="2x"
							style={{ color: 'red' }}
							className="favorite-icon"
							onClick={() => {
								if (favorite === false) {
									setFavorite(true);
									postFavorite(props.event_id, auth.token);
									// console.log("liked");
								}
								if (favorite === true) {
									setFavorite(false);
									postUnfavorite(props.event_id, auth.token);
									// console.log("unliked");
								}
							}}
						></FontAwesomeIcon>
					)}
				</div>
			</React.Fragment>
		);
	}

	if (props.type === 'property') {
		return (
			<div className="grid-col">
				<Link
					className="link-unstyled"
					to={{
						pathname: `/spaces/${props.id}`,
						state: {
							city: props.location,
						},
					}}
				>
					<div className="d-flex flex-column h-100">
						<RenderImage
							loading={loading}
							setLoading={setLoading}
							imagesrc={props.cover_image}
							classes="spaces-heart"
						/>
						<div className="h-100 d-flex flex-column">
							<div className="d-flex justify-content-between align-items-baseline text-muted mt-2">
								<p className="mb-0">
									<span
										className="badge badge-secondary text-uppercase px-2"
										style={{ borderRadius: '6px' }}
									>
										{props.category_name}
									</span>
								</p>
								<small>
									<FontAwesomeIcon
										icon={faUser}
										size="lg"
										className="text-dark"
									></FontAwesomeIcon>{' '}
									{props.limit}
								</small>
							</div>
							<h6
								className="fw-bold text-sm my-2 w-100"
								style={{ textOverflow: 'ellipsis', whiteSpace: 'wrap' }}
							>
								{props.name}
							</h6>
							<div className="d-flex justify-content-between align-items-end text-muted mt-auto">
								{/* <small>{props.place}</small> */}
								<small className="w-50">
									{props.location.locality} | {props.location.city_name}
								</small>
								<p className="fw-bold text-dark price m-0">
									Rs.{props.rate === null ? '0' : props.rate}
								</p>
							</div>
						</div>
					</div>
				</Link>
			</div>
		);
	}

	return null;
}
