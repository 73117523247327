import React from "react";
// import Img from "react-cool-img";

// import BAS from "../../../assets/img/book a spcae.png";
import styles from "./Space.module.scss";

import { Link } from "react-router-dom";

// import moment from "moment";

import { useQuery } from "react-query";
import { fetchUpcomingPartiesAsGuest } from "../../../../api/requests";
import { Loader } from "../../../helpers";

export default function AsGuest({ token, ...props }) {
  const { data, isLoading } = useQuery(
    ["FETCH_PARTIES_AS_GUEST", token],
    fetchUpcomingPartiesAsGuest,
    {
      retry: 1,
      refetchOnWindowFocus: false,
    }
  );
  console.log(data);
  return (
    <div className={styles["as-a-guest"]}>
      <p className="fw-bold text-uppercase text-secondary">As a guest</p>
      <div>
        {isLoading && (
          <div className={styles["loading-overlay"]}>
            <Loader />
          </div>
        )}
        <p>No spaces listed.</p>
        {/* {data && data.data.length === 0 && <p>No Spaces listed.</p>}
        {data &&
          data.data.map((space) => {
            if (space.review_status === 0 || space.review_status === 2) {
              return <SpaceCardWithoutImage key={space.id} {...space} />;
            } else {
              return <SpaceCardWithImage key={space.id} {...space} />;
            }
          })} */}
        <Link to="/spaces " className="btn btn-secondary fw-bold mt-5">
          EXPLORE SPACES
        </Link>
      </div>
    </div>
  );
}

// function SpaceCardWithImage() {
//   return (
//     <div className={`${styles["card-with-img"]} shadow`}>
//       <div className={styles["card-with-img__img"]}>
//         <Img
//           style={{
//             borderTopLeftRadius: "15px",
//             borderBottomLeftRadius: "15px",
//             objectFit: "cover",
//           }}
//           src={BAS}
//           alt="party attending"
//         />
//       </div>
//       <div className={`${styles["card-with-img__content"]} py-3 ml-3`}>
//         <div className="d-flex justify-content-between">
//           <div>
//             <h5 className="fw-bold">Never have I ever</h5>
//             <small className="m-0 text-muted">Nov 16 | 8:00pm to 12:00pm</small>
//             <br />
//             <small className="m-0 text-muted">Khar West, Mumbai</small>
//           </div>
//           <div>
//             <h5 className="fw-bold mr-3">&#8377; 350</h5>
//           </div>
//         </div>
//         <div className="d-flex justify-content-between mt-3 mr-3">
//           <p className="text-secondary m-0 p-0">LIVE</p>
//           <p className="text-secondary fw-bold m-0">NO. OF TIMES BOOKED: 20</p>
//         </div>
//       </div>
//     </div>
//   );
// }

// function SpaceCardWithoutImage() {
//   return (
//     <div className={`${styles["card-without-img"]} shadow`}>
//       <div className={`${styles["card-without-img__content"]}`}>
//         <div className="d-flex justify-content-between p-3">
//           <div>
//             <h5 className="fw-bold">Never have I ever</h5>
//             <small className="m-0 text-muted">Nov 16 | 8:00pm to 12:00pm</small>
//             <br />
//             <small className="m-0 text-muted">Khar West, Mumbai</small>
//           </div>
//           <div className="d-flex flex-column justify-content-between">
//             <h5 className="fw-bold text-right">&#8377; 350</h5>
//             <p className="fw-bold text-secondary m-0">PAYMENT PENDING</p>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }
