import pizzeria1441 from "./1441.png";
import brewHouse from "./Black.png";
import crossword from "./crossword rect logo.jpg";
import dotShot from "./dotshot.png";
import starSportsSelect from "./fNNMuvtQ.png";
import ITC from "./ITC.png";
import harshChocolates from "./LOGO HARSH-Colour-1 (1).jpg";
import limese from "./image copy 2.png";
import zolo from "./image.png";
import chilis from "./Logo_Growth_C.png";
import bacardi from "./logoagegate23714.png";
import rageCoffee from "./LogoCoffeesquareresized.png";
import OML from "./OML_Logo_2016.png";
import OnoTeas from "./Ono Teas Logo Manish.png";
import sipWise from "./sipwise.png";
import svami from "./Svami_Black_Text_Horizontal_x25@2x.png";
import things2Do from "./things2do logo.png";
import WeWork from "./WEWORK.png";
import whiteOwl from "./WO-logo-horizontal-on-white-bright-with-margin.png";
import workAmp from "./WorkAmp-Logo-Banner.png";

export const brandLogos =  {
  pizzeria1441,
  brewHouse,
  crossword,
  dotShot,
  starSportsSelect,
  ITC,
  harshChocolates,
  limese,
  zolo,
  chilis,
  bacardi,
  rageCoffee,
  OML,
  OnoTeas,
  sipWise,
  svami,
  things2Do,
  WeWork,
  whiteOwl,
  workAmp,
};