import React, { useEffect, useState } from 'react';
// import queryString from 'query-string';

import { Skeleton } from '../../../modules/common';
import { useQuery } from 'react-query';

import { Row, Col } from 'react-bootstrap';
import { fetchPartyListing } from '../../../../api/requests';

import { EventCard } from '../../../modules/content';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RenderUserObj } from '../../../helpers';

export default function All({ setPage, ...props }) {
	const { pagenumber } = useParams();
	// const history = useHistory();
	// const location = useLocation();
	let [token, setToken] = useState(null);

	// const query = queryString.parse(location.search);

	const rand = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
	const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
	const userObj = RenderUserObj(useSelector(state => state.auth.data));

	// console.log(props, query);
	useEffect(() => {
		setPage(pagenumber);
	}, [pagenumber, setPage]);

	useEffect(() => {
		props.onSetFilter('all');
		if (isAuthenticated) {
			if (userObj !== undefined) setToken(userObj.token);
		}
	}, [props, isAuthenticated, userObj]);

	const { data, isLoading, error } = useQuery(
		['partyListing', '', '', token],
		fetchPartyListing,
		{
			retry: 0,
			refetchInterval: false,
			refetchOnWindowFocus: false,
		}
	);

	// function handlePageTransition(next, prev) {
	//   if (pagenumber > 1 && prev) {
	//     history.push({
	//       pathname: `/parties/page/${parseInt(pagenumber) - 1}`,
	//       search: "?filter=all",
	//     });
	//   }

	//   if (pagenumber < 12 && next) {
	//     history.push({
	//       pathname: `/parties/page/${parseInt(pagenumber) + 1}`,
	//       search: "?filter=all",
	//     });
	//   }
	// }
	return (
		<div>
			{error && <h5>Failed to load</h5>}
			{isLoading && (
				<div className="grid-container">
					{rand.map(index => {
						return <Skeleton key={index} />;
					})}
				</div>
			)}
			<Row>
				{data &&
					data.data.map((item, index) => {
						return (
							<Col key={index} md={4} className="mb-3">
								<EventCard type="party" {...item} />
							</Col>
						);
					})}
			</Row>
			{/* //Pagination */}
			{/* <div className="d-flex justify-content-center align-items-center flex-wrap mt-4 mb-2">
        <button
          className="btn shadow text-primary btn-sm mr-2"
          onClick={() => handlePageTransition(false, true)}
          disabled={location.pathname.slice(-2) === "/1"}
        >
          &#8592;
        </button>
        <button
          className="btn shadow text-primary btn-sm ml-2"
          onClick={() => handlePageTransition(true, false)}
          disabled={location.pathname.slice(-2) === "12"}
        >
          &#8594;
        </button>
      </div> */}
		</div>
	);
}
