import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFemale, faMale } from "@fortawesome/free-solid-svg-icons";
// import { InputField } from "../../../modules/common";
import { Form, Button } from "react-bootstrap";

import { updateUser } from "../../../../api/requests";

import moment from "moment";
import { Default, Mobile } from "../../../helpers/Breakpoints";

import { useSelector } from "react-redux";

import "./About.module.scss";

export default function About({ isLoading, data, ...props }) {
  let [isFormOpen, setisFormOpen] = useState(false);
  let [userData, setuserData] = useState({
    first_name: data.first_name,
    last_name: data.last_name,
    location: data.location,
    description: data.description,
    phone: data.phone,
    email: data.email,
  });
  const userObj = JSON.parse(useSelector((state) => state.auth.data)); //required for token

  const handleChange = (e) => {
    e.persist();
    setuserData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
    console.log(userData);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(userData);
    updateUser(userData, userObj.token);
  };
  return (
    <>
      <Default>
        {isFormOpen ? (
          <>
            <Form onSubmit={handleSubmit}>
              <div>
                <label className="text-primary">First Name</label>
                <input
                  name={"first_name"}
                  value={userData.first_name}
                  onChange={handleChange}
                  pattern="^[a-zA-Z\s]{3,20}$"
                  required
                />
                <label className="text-primary">Last Name</label>
                <input
                  name={"last_name"}
                  value={userData.last_name}
                  onChange={handleChange}
                  pattern="^[a-zA-Z\s]{3,20}$"
                  required
                />

                <label className="text-primary">Location</label>
                <input
                  name={"location"}
                  value={userData.location || " "}
                  onChange={handleChange}
                  pattern="^[a-zA-Z\s]{3,20}$"
                  required
                />

                <label className="text-primary">Description</label>
                <textarea
                  name={"description"}
                  value={userData.description || " "}
                  onChange={handleChange}
                  required
                />

                <label className="text-primary">Phone</label>
                <input
                  name={"phone"}
                  value={userData.phone}
                  onChange={handleChange}
                  pattern="[0-9]*"
                  maxLength="10"
                  minLength="10"
                  required
                />
                <label className="text-primary">Email</label>
                <input
                  name={"email"}
                  value={userData.email}
                  onChange={handleChange}
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                  required
                />
              </div>
              <div className="my-5 d-flex align-items-center justify-content-end">
                <Button type="submit" className="btn btn-small btn-primary">
                  Update
                </Button>
                <p
                  className="my-0 ml-3 text-muted d-inline-block make-pointer"
                  onClick={() => {
                    setisFormOpen(false);
                  }}
                >
                  Cancel
                </p>
              </div>
            </Form>
          </>
        ) : (
          <>
            <div className="d-flex justify-content-between align-items-center">
              <p className="m-0 fw-bold">
                {data.first_name + " " + data.last_name}
              </p>
              <small
                className="d-block text-muted make-pointer"
                onClick={() => {
                  setisFormOpen(true);
                }}
              >
                Edit Profile
              </small>
            </div>
            <hr />
            {/* <div className="d-flex justify-content-between align-items-center">
              <p>26 years</p>
              <p>
                {data.gender === 1 ? (
                  <>
                    {" "}
                    <FontAwesomeIcon
                      icon={faMale}
                      size="lg"
                      className="text-primary mr-2"
                    ></FontAwesomeIcon>{" "}
                    Male{" "}
                  </>
                ) : (
                  <>
                    {" "}
                    <FontAwesomeIcon
                      icon={faFemale}
                      size="lg"
                      className="text-primary mr-2"
                    ></FontAwesomeIcon>{" "}
                    Female{" "}
                  </>
                )}
              </p>
              <p className="text-capitalize">{userData.location}</p>
            </div> */}
            <p className="mt-3">
              <span className="fw-bold">Email:</span> {userData.email}
            </p>
            <p className="mt-3">
              <span className="fw-bold">Phone:</span> {userData.phone}
            </p>
            <p className="mt-3">
              Joined Playace in {moment(data.created_at).format("MMM YYYY")}
            </p>
            <hr />
            <div>
              <p className="text-primary text-uppercase">About me</p>
              <p>{data.description}</p>
            </div>
          </>
        )}
      </Default>
      <Mobile>
        {isFormOpen ? (
          <>
            <Form onSubmit={handleSubmit}>
              <div>
                <label className="text-primary">First Name</label>
                <input
                  name={"first_name"}
                  value={userData.first_name}
                  onChange={handleChange}
                  pattern="^[a-zA-Z\s]{3,20}$"
                  required
                />
                <label className="text-primary">Last Name</label>
                <input
                  name={"last_name"}
                  value={userData.last_name}
                  onChange={handleChange}
                  pattern="^[a-zA-Z\s]{3,20}$"
                  required
                />

                <label className="text-primary">Location</label>
                <input
                  name={"location"}
                  value={userData.location}
                  onChange={handleChange}
                  pattern="^[a-zA-Z\s]{3,20}$"
                  required
                />

                <label className="text-primary">Description</label>
                <textarea
                  name={"description"}
                  value={userData.description}
                  onChange={handleChange}
                />

                <label className="text-primary">Phone</label>
                <input
                  name={"phone"}
                  value={userData.phone}
                  onChange={handleChange}
                  pattern="[0-9]*"
                  maxLength="10"
                  minLength="10"
                  required
                />
                <label className="text-primary">Email</label>
                <input
                  name={"email"}
                  value={userData.email}
                  onChange={handleChange}
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                  required
                />
              </div>
              <div className="my-5 d-flex align-items-center justify-content-end">
                <Button type="submit" className="btn btn-small btn-primary">
                  Update
                </Button>
                <p
                  className="my-0 ml-3 text-muted d-inline-block make-pointer"
                  onClick={() => {
                    setisFormOpen(false);
                  }}
                >
                  Cancel
                </p>
              </div>
            </Form>
          </>
        ) : (
          <>
            <div className="d-flex justify-content-between align-items-center">
              <p className="m-0 fw-bold">
                {userData.first_name + " " + userData.last_name}
              </p>
              <small
                className="d-block text-muted"
                onClick={() => {
                  setisFormOpen(true);
                }}
              >
                Edit Profile
              </small>
            </div>
            <hr />
            {/* <div className="d-flex justify-content-between align-items-center">
              <p>26 years</p>
              <p>
                {data.gender === 1 ? (
                  <>
                    <FontAwesomeIcon
                      icon={faMale}
                      size="lg"
                      className="text-primary mr-2"
                    ></FontAwesomeIcon>
                    Male
                  </>
                ) : (
                  <>
                    <FontAwesomeIcon
                      icon={faFemale}
                      size="lg"
                      className="text-primary mr-2"
                    ></FontAwesomeIcon>
                    Female
                  </>
                )}
              </p>
              <p>{userData.location}</p>
            </div> */}
            <p className="mt-3">
              <span className="fw-bold">Email:</span> {userData.email}
            </p>
            <p className="mt-3">
              <span className="fw-bold">Phone:</span> {userData.phone}
            </p>
            <p className="mt-3">
              Joined Playace in {moment(data.created_at).format("MMM YYYY")}
            </p>
            <hr />
            <div>
              <p className="text-primary text-uppercase">About me</p>
              <p>{userData.description}</p>
            </div>
          </>
        )}
      </Mobile>
    </>
  );
}
