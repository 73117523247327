import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-regular-svg-icons';

import { EventCard } from '../../modules/content';
import { Skeleton } from '../../modules/common';
import { RenderUserObj, MaintainScroll } from '../../helpers';

import { fetchProperties } from '../../../api/requests';

import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';

export default function Spaces() {
	let [token, setToken] = useState(null);
	const { pathname } = useLocation();

	MaintainScroll('scroll', true);

	let [filterState, setFilterState] = useState({
		activeButton: null,
		buttonElements: [
			{
				id: 1,
				innerHTML: <small>Date</small>,
			},
			{
				id: 2,
				innerHTML: <small>Time</small>,
			},
			{
				id: 3,
				innerHTML: <small>Location</small>,
			},
			{
				id: 4,
				innerHTML: <small>Filters</small>,
			},
			{
				id: 5,
				innerHTML: (
					<small>
						<FontAwesomeIcon icon={faHeart} size="lg"></FontAwesomeIcon>
					</small>
				),
			},
		],
	});

	useEffect(() => {
		setFilterState({
			...filterState,
			activeButton: filterState.buttonElements[0],
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const rand = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
	const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
	const userObj = RenderUserObj(useSelector(state => state.auth.data));
	const { data, isLoading, error } = useQuery(
		['propertyListing', token],
		fetchProperties,
		{
			retry: 1,
			refetchInterval: false,
			refetchOnWindowFocus: false,
		}
	);
	// console.log(filterState)
	function toggleActiveFilter(index) {
		// console.log(index)
		setFilterState({
			...filterState,
			activeButton: filterState.buttonElements[index],
		});
		// filterListing(index);
	}

	function toggleActiveClasses(index) {
		// console.log(filterState.buttonElements[index], filterState.activeButton)
		if (filterState.buttonElements[index] === filterState.activeButton) {
			return 'btn btn-small btn-reg mx-1 filter-btn selected-filter';
		} else {
			return 'btn btn-small btn-reg mx-1 filter-btn';
		}
	}

	useEffect(() => {
		if (isAuthenticated) {
			if (userObj !== undefined) setToken(userObj.token);
		}
	}, [isAuthenticated, userObj]);

	return (
		<Container className="mTop mb-5">
			{/* <ScrollToTop /> */}
			{/* <Row className="mt-5 mb-3">
        <Col lg={7} sm={12} />
        <Col lg={5} sm={12}>
          <div className="filters-container mb-3">
            {filterState.buttonElements.map((item, index) => {
              return (
                <button
                  key={index}
                  className={toggleActiveClasses(index)}
                  onClick={() => {
                    toggleActiveFilter(index);
                    // filterListing(index);
                  }}
                >
                  {item.innerHTML}
                </button>
              );
            })}
          </div>
        </Col>
      </Row> */}
			{error && <h5>Failed to load</h5>}
			{isLoading && (
				<div className="grid-container">
					{rand.map(index => {
						return <Skeleton key={index} />;
					})}
				</div>
			)}
			<Row>
				{data &&
					data.map((item, index) => {
						return (
							<Col key={index} md={4} className="mb-3">
								<EventCard type="property" {...item} />
							</Col>
						);
					})}
			</Row>
		</Container>
	);
}
