import NoFood from './Teal cross icons-01.svg';
import NoNonVeg from './Teal cross icons-02.svg';
import NoSmoking from './Teal cross icons-03.svg';
import NoAlcohol from './Teal cross icons-04.svg';
import NoVeg from './Teal cross icons-05.svg';
import NoParking from './Teal cross icons-06.svg';
import NoKids from './Playace icon Swatches-29.svg';
import NoElevator from './Teal cross icons-09.svg';
import NoAC from './Teal cross icons-10.svg';
import NoPets from './Teal cross icons-13.svg';
import NoGuestPets from './Teal cross icons-15.svg';
import NoFootwear from './Playace icon Swatches-25.svg';

const SpacesCrossImages = {
    NoFood, NoNonVeg, NoSmoking, NoAlcohol, NoVeg, NoParking, NoKids, NoElevator, NoAC, NoPets, NoGuestPets, NoFootwear
};

export default SpacesCrossImages;