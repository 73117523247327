import React, { useState } from 'react';
import { Container, Row, Col, Modal } from 'react-bootstrap';
import { Default, Mobile } from '../../helpers/Breakpoints';
import { useHistory } from 'react-router-dom';
import Img from 'react-cool-img';
import styles from './HostAParty.module.scss';
import { useSelector } from 'react-redux';
import PlanParty from '../../../assets/hosting_plan_party.png';
import WelcomeGuests from '../../../assets/hosting_welcome_guests.png';
import GetPaid from '../../../assets/hosting_get_paid.png';
import ThankyouImage from '../../../assets/request_sent.png';

import PlanningOverview from './PlanningOverview';
// import UploadCover from "./UploadCover";
import Auth from '../../modules/auth/Auth';
import { useEffect } from 'react';
// import { v4 } from "uuid";
// import Axios from "axios";
// import { Redirect } from "react-router";

export default function HostAParty() {
	return (
		<>
			<Default>
				<div className="mt-5">
					<div className={`${styles['top-section']} p-5`}>
						<Container className="text-center px-5 pt-5 w-75">
							<ShowListModal>
								<h3 className="mb-4 text-uppercase">
									Host a <span className="text-primary">house party!</span>
								</h3>
							</ShowListModal>
						</Container>
					</div>
					<div className={`${styles['party-top-section']} px-5 pb-5`}>
						<Container className="w-75">
							<Row className="align-items-center">
								<Col md={6} sm={12}>
									<h4 className="text-uppercase mt-5">
										Step 1: Plan your{' '}
										<span className="text-primary">party</span>
									</h4>
									<p>
										Think of a nice theme, fill in a few details, add pictures
										of your Playace and set a price.
									</p>
								</Col>
								<Col md={6} sm={12}>
									<Img
										src={PlanParty}
										className="img-fluid d-block ml-auto"
										width="40%"
									/>
								</Col>
							</Row>
						</Container>
					</div>
					<div className={`px-5 pb-5`}>
						<Container className="w-75">
							<Row className="align-items-center">
								<Col md={6} sm={12}>
									<Img src={WelcomeGuests} className="img-fluid" width="40%" />
								</Col>
								<Col md={6} sm={12}>
									<h4 className="text-uppercase mt-5">
										Step 2: Welcome{' '}
										<span className="text-primary"> your guests</span>
									</h4>
									<p>
										Once your party is LIVE, start receiving requests from
										verified guests, curate your own guest list and you are all
										set to have an epic party!
									</p>
								</Col>
							</Row>
						</Container>
					</div>
					<div className={`${styles['party-bottom-section']} px-5 pb-5`}>
						<Container className="w-75">
							<Row className="align-items-center">
								<Col md={6} sm={12}>
									<h4 className="text-uppercase mt-5">
										Step 3: Get <span className="text-primary">paid</span>
									</h4>
									<p>
										After all the fun and successful completion of your party,
										receive payments through our secured payment systems.
									</p>
								</Col>
								<Col md={6} sm={12}>
									<Img
										src={GetPaid}
										className="img-fluid d-block ml-auto"
										width="40%"
									/>
								</Col>
							</Row>
						</Container>
					</div>
					{/* <div className={`${styles['background-grey']} text-center py-5`}>
						<ShowListModal>
							<h3 className="mb-4 text-uppercase">
								Host a party <span className="text-primary">now!</span>
							</h3>
						</ShowListModal>
					</div> */}
				</div>
			</Default>
			<Mobile>
				<div className="mt-5">
					<div className={`${styles['top-section']} p-5`}>
						<Container className="text-center px-3 pt-5">
							<ShowListModal>
								<h3 className="mb-4 text-uppercase">
									Host a <span className="text-primary">house party!</span>
								</h3>
							</ShowListModal>
						</Container>
					</div>
					<div className={`${styles['party-top-section']} px-5 pb-5`}>
						<Container>
							<Row className="align-items-center">
								<Col md={6} sm={12}>
									<h4 className="text-uppercase mt-5">
										Step 1: Plan your{' '}
										<span className="text-primary">party</span>
									</h4>
									<p>
										Think of a nice theme, fill in a few details, add pictures
										of your Playace and set a price.
									</p>
								</Col>
								<Col md={6} sm={12}>
									<Img
										src={PlanParty}
										className="img-fluid d-block mx-auto"
										width="50%"
									/>
								</Col>
							</Row>
						</Container>
					</div>
					<div className={`px-5 pb-5`}>
						<Container>
							<Row className="align-items-center">
								<Col md={6} sm={12} className="text-center order-2">
									<Img
										src={WelcomeGuests}
										className="img-fluid mx-auto"
										width="50%"
									/>
								</Col>
								<Col md={6} sm={12} className="order-1">
									<h4 className="text-uppercase mt-5">
										Step 2: Welcome
										<span className="text-primary"> your guests</span>
									</h4>
									<p>
										Once your party is LIVE, start receiving requests from
										verified guests, curate your own guest list and you are all
										set to have an epic party!
									</p>
								</Col>
							</Row>
						</Container>
					</div>
					<div className={`${styles['party-bottom-section']} px-5 pb-5`}>
						<Container>
							<Row className="align-items-center">
								<Col md={6} sm={12}>
									<h4 className="text-uppercase mt-5">
										Step 3: Get <span className="text-primary">paid</span>
									</h4>
									<p>
										After all the fun and successful completion of your party,
										receive payments through our secured payment systems.
									</p>
								</Col>
								<Col md={6} sm={12}>
									<Img
										src={GetPaid}
										className="img-fluid d-block mx-auto"
										width="50%"
									/>
								</Col>
							</Row>
						</Container>
					</div>
					{/* <div className={`${styles['background-grey']} text-center py-5`}>
						<ShowListModal>
							<h3 className="mb-4 text-uppercase">
								Host a party <span className="text-primary">now!</span>
							</h3>
						</ShowListModal>
					</div> */}
				</div>
			</Mobile>
		</>
	);
}

const ShowListModal = React.memo(function ShowListModal({
	children,
	...props
}) {
	const [show, setShow] = useState(false);
	const [showQuery, setShowQuery] = useState(false);
	const [showRequestSentModal, setShowRequestSentModal] = useState(false);
	const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
	const history = useHistory();

	useEffect(() => {
		if (isAuthenticated) setShow(false);
	}, []);

	const handleClose = () => {
		setShow(false);
	};

	const openHostModal = () => {
		setShow(false);
		setShowQuery(true);
	};

	const closeModal = () => {
		setShowQuery(false);
		setShowRequestSentModal(true);
	};

	const handleCloseRequestSentModal = () => {
		setShowRequestSentModal(false);
		setShowQuery(false);
		history.push('/');
	};
	const handleShow = () => setShow(true);

	const handleClick = () => {
		if (!isAuthenticated) {
			handleShow();
		} else {
			setShowQuery(true);
		}
	};

	return (
		<>
			{children}
			<div
				onClick={handleClick}
				className="btn btn-primary text-uppercase my-3 px-5"
			>
				Get started
			</div>
			<Default>
				<Modal
					size="lg"
					show={show}
					onHide={handleClose}
					dialogClassName="modal-dimensions"
					backdrop="static"
					centered
				>
					<Auth handleClose={handleClose} handleHost={openHostModal} />
				</Modal>
				<Modal
					size="lg"
					show={showQuery}
					onHide={closeModal}
					backdrop="static"
					centered
					style={{ display: showRequestSentModal ? 'none' : 'block' }}
				>
					<Modal.Header>
						<button
							className={`btn text-dark float-right `}
							style={{
								left: '100%',
								position: 'relative',
								margin: ' 0 -4em',
							}}
							onClick={() => setShowQuery(false)}
						>
							X
						</button>
					</Modal.Header>
					<PlanningOverview closeModal={closeModal} />
				</Modal>
				<Modal
					show={showRequestSentModal}
					backdrop="static"
					onHide={handleCloseRequestSentModal}
				>
					<div
						className={'d-flex flex-column justify-content-center text-center'}
					>
						<img src={ThankyouImage} alt="Request-sent-image" />
						<h5>Party Request sent</h5>
						<p>
							Your hosting request is being reviewed.
							<br />
							Our party experts will get in touch with you shortly!
						</p>
						<div className="d-flex justify-content-center">
							<button
								className="btn btn-primary text-uppercase fw-bold px-4 mt-2 mb-3 mx-auto"
								type="submit"
								onClick={handleCloseRequestSentModal}
							>
								Okay
							</button>
						</div>
					</div>
				</Modal>
			</Default>

			<Mobile>
				<Modal
					size="lg"
					show={show}
					onHide={handleClose}
					backdrop="static"
					centered
				>
					<Auth handleClose={handleClose} handleHost={openHostModal} />
				</Modal>
				<Modal
					size="lg"
					show={showQuery}
					onHide={closeModal}
					backdrop="static"
					centered
					style={{ display: showRequestSentModal ? 'none' : 'block' }}
				>
					<Modal.Header>
						<button
							className={`btn text-dark float-right `}
							style={{
								left: '100%',
								position: 'relative',
								margin: ' 0 -4em',
							}}
							onClick={() => setShowQuery(false)}
						>
							X
						</button>
					</Modal.Header>
					<PlanningOverview closeModal={closeModal} />
				</Modal>
				<Modal
					show={showRequestSentModal}
					backdrop="static"
					onHide={handleCloseRequestSentModal}
				>
					<div
						className={'d-flex flex-column justify-content-center text-center'}
					>
						<img src={ThankyouImage} alt="Request-sent-image" />
						<h5>Party Request sent</h5>
						<p>
							Your hosting request is being reviewed.
							<br />
							Our party experts will get in touch with you shortly!
						</p>
						<div className="d-flex justify-content-center">
							<button
								className="btn btn-primary text-uppercase fw-bold px-4 mt-2 mb-3 mx-auto"
								type="submit"
								onClick={handleCloseRequestSentModal}
							>
								Okay
							</button>
						</div>
					</div>
				</Modal>
			</Mobile>
		</>
	);
});
