import {
	AUTHENTICATE_USER_START,
	AUTHENTICATE_USER_SUCCESS,
	AUTHENTICATE_USER_FAILURE,
} from '../actions/types';

const initialState = {
	data: {},
	loading: false,
	isAuthenticated: false,
	bookClicked: false,
	errors: [],
};

export default function (state = initialState, action) {
	switch (action.type) {
		case AUTHENTICATE_USER_START:
			return {
				...state,
				loading: true,
			};
		case AUTHENTICATE_USER_SUCCESS:
			return {
				...state,
				data: action.payload,
				loading: false,
				isAuthenticated: true,
				errors: [],
			};
		case AUTHENTICATE_USER_FAILURE:
			return {
				...state,
				errors: action.payload,
				loading: false,
				// isAuthenticated: false,
			};

		case 'LOGOUT_USER':
			return {
				...state,
				data: action.payload,
				isAuthenticated: false,
				loading: false,
			};

		case 'BOOK_CLICKED_TRUE':
			return {
				...state,
				bookClicked: true,
			};

		case 'BOOK_CLICKED_FALSE':
			return {
				...state,
				bookClicked: false,
			};

		default:
			return {
				...state,
			};
	}
}
