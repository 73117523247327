import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Container, Row } from 'react-bootstrap';
import ScrollContainer from 'react-indiana-drag-scroll';
import { Link } from 'react-router-dom';
import axios from 'axios';

import { fetchPartyListing, fetchEventInfo } from '../../../api/requests';
import { EventCard } from '../../modules/content';
import { useSelector } from 'react-redux';
import { RenderUserObj } from '../../helpers';
import { Skeleton } from '../../modules/common';

function Attend(props) {
	let [token, setToken] = useState(null);
	const rand = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
	const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
	const userObj = RenderUserObj(useSelector(state => state.auth.data));
	useEffect(() => {
		if (isAuthenticated) {
			if (userObj !== undefined) setToken(userObj.token);
		}
		// console.log(info);
	}, [props, isAuthenticated, userObj]);

	const { data, isLoading } = useQuery(
		['partyListing', 10, '', token],
		fetchPartyListing,
		{
			staleTime: 5000,
			refetchOnWindowFocus: false,
		}
	);

	// const { info, isInfoLoading } = useQuery(['eventInfo'], fetchEventInfo, {
	// 	staleTime: 5000,
	// 	refetchOnWindowFocus: false,
	// });
	// console.log(data);
	if (data && data.data.length === 0 && !isLoading) {
		return <></>;
	}
	return (
		<section className="attend-section mTop">
			{props.title ? (
				<>
					<h2 className="mb-5">{props.title}</h2>
					<ScrollContainer
						className="scroll-container"
						style={{ padding: '0 1.2rem 1.2rem 0.2rem' }}
						nativeMobileScroll={true}
						horizontal={true}
					>
						<Row className="attend-no-wrap">
							{isLoading && data === undefined
								? rand.map((item, index) => {
										return <Skeleton key={index} />;
								  })
								: data.data.map((item, index) => {
										return (
											<EventCard
												classes="col-md-4 col-sm-10 col-9 mx-2"
												key={index}
												type="party"
												{...item}
											/>
										);
								  })}
						</Row>
					</ScrollContainer>
					{props.button ? (
						<div className="text-center mt-4 mb-5">
							<Link
								to="/parties"
								className="btn btn-reg btn-outline-primary fw-bold btn-padding"
							>
								Explore more parties
							</Link>
						</div>
					) : null}
				</>
			) : (
				<>
					<Container>
						<h2>House parties to attend near you</h2>
						<p className="mb-5 text-muted font-weight-normal">
							Experience a new and comfortable way of socializing.
						</p>
						<ScrollContainer
							className="scroll-container"
							style={{ padding: '0 1.2rem 1.2rem 0.2rem' }}
						>
							<Row className="attend-no-wrap">
								{isLoading && data === undefined
									? rand.map((item, index) => {
											return <Skeleton key={index} />;
									  })
									: data.data.map((item, index) => {
											return (
												<EventCard
													classes="col-md-4 col-sm-10 col-12 mx-2"
													key={index}
													type="party"
													isSection="attend"
													{...item}
												/>
											);
									  })}
							</Row>
						</ScrollContainer>
					</Container>
					{props.button ? (
						<div className="text-center mt-4 mb-5">
							<Link
								to="/parties/page/1"
								className="btn btn-reg btn-outline-primary fw-bold btn-padding"
							>
								Explore more parties
							</Link>
						</div>
					) : null}
				</>
			)}
		</section>
	);
}

export default Attend;
