import React from "react";
import { Row, Col } from "react-bootstrap";
// import axios from "axios";
// import { URL } from "../../../../api/requests";

import { useSelector } from "react-redux";
import { Default, Mobile } from "../../../helpers/Breakpoints";
// import { Loader } from "../../../helpers";

import PartyGuestList from "./Parties/PartyGuestList";
import SpaceGuestList from "./Spaces/SpaceGuestList";
import "./index.scss";

function GuestList() {
  // let [isLoading, setisLoading] = useState(true);
  // let [parties, setParties] = useState(null);

  const auth = JSON.parse(useSelector((state) => state.auth.data));

  // useEffect(() => {
  //   async function fetchParties() {
  //     await axios
  //       .get(`${URL}v1/parties/hosting`, {
  //         headers: {
  //           Authorization: `Bearer ${auth.token}`,
  //         },
  //       })
  //       .then((response) => {
  //         setisLoading(false);
  //         setParties(response.data.data);
  //         console.log(response.data.data);
  //         response.data.data.map((party)=>{
  //           return fetchGuestList(party.event_id, 1);
  //         })
  //         // return console.log(response.data);
  //       })
  //       .catch((errors) => {
  //         return console.log(errors.message);
  //       });
  //   }
  //   fetchParties();

  //   async function fetchGuestList(event_id, status) {
  //     await axios
  //       .get(
  //         `${URL}v1/parties/guestlist?event_id=${event_id}&status=${status}`,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${auth.token}`,
  //           },
  //         }
  //       )
  //       .then((response) => {
  //         console.log(response.data);
  //       })
  //       .catch((errors) => console.log(errors.message));
  //   }
  // }, [auth.token]);

  // // console.log(parties);

  // if (isLoading && parties === null) {
  //   return (
  //     <div className="loading-overlay">
  //       <Loader />
  //     </div>
  //   );
  // }
  return (
    <>
      <Default>
        <Row>
          <Col md={6} sm={12}>
            <PartyGuestList token={auth.token} />
          </Col>
          <Col md={6} sm={12}>
            <SpaceGuestList token={auth.token} />
          </Col>
        </Row>
      </Default>
      <Mobile>
        <Row>
          <Col sm={12} className="mx-4 mb-4">
            <PartyGuestList token={auth.token} />
          </Col>
          <Col sm={12} className="mx-4">
            <SpaceGuestList token={auth.token} />
          </Col>
        </Row>
      </Mobile>
    </>
  );
}

export function GuestListCard({ children, ...props }) {
  return (
    <div className={`shadow rounded-lg bg-white guest-card-${props.classes}`}>
      <div
        className={`nametag ${
          props.nametag === "House Parties" ? `bg-primary` : `bg-secondary`
        }`}
      >
        <p className="m-0 text-uppercase fw-bold float-right">
          {props.nametag}
        </p>
      </div>
      {children}
    </div>
  );
}

export default GuestList;
