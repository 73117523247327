import React, { useState } from 'react';
import { Container, Row, Col, Modal, Form } from 'react-bootstrap';
import { Default, Mobile } from '../../helpers/Breakpoints';
import Auth from '../../modules/auth/Auth';
import Img from 'react-cool-img';
import styles from './ListASpace.module.scss';
import { useQuery } from 'react-query';
import { fetchPropertyCategories, URL } from '../../../api/requests';
import { useSelector } from 'react-redux';
import { v4 } from 'uuid';
import Axios from 'axios';
import { useHistory } from 'react-router';
// import { Redirect } from "react-router";

//RENDER THE PAGE AND SHOW MODAL, SEND REQUEST
export default function ListASpace() {
	return (
		<>
			<Default>
				<div className="mt-5">
					<div className={`${styles['top-section']} p-5`}>
						<Container className="text-center px-5 pt-5 w-75">
							<ShowListModal>
								<h3 className="mb-4 text-uppercase">
									Earn from <span className="text-primary">your Space!</span>
								</h3>
								<p>
									With Playace every significant part of your space gets paid
									separately. Be it the living room, the bedroom, the backyard
									or the side lawn. Get your space discovered by the people who
									are in search for it to meet, teach, celebrate or create
									moments full of Fun and Happiness
								</p>
							</ShowListModal>
						</Container>
					</div>
					<div className="mx-auto text-center">
						<h3 className="d-inline-block text-uppercase mt-5 underline">
							How and why to <span className="text-primary">list with us?</span>
						</h3>
					</div>
					<div className={`${styles['list-property-top-section']} px-5 pb-5`}>
						<Container className="w-75">
							<Row className="align-items-center">
								<Col md={6} sm={12}>
									<h4 className="text-uppercase mt-5">
										It's easy and <span className="text-primary">free.</span>
									</h4>
									<p>
										Listing with Playace is Simple and Free. Just Set a Price,
										Add Photos, a few details and your Space is Ready to Reach
										people in search for it.
									</p>
								</Col>
								<Col md={6} sm={12} className="text-center">
									<Img
										src="https://playace.co/assets/images/Add-Property13.png"
										className="img-fluid d-block ml-auto"
										width="60%"
									/>
								</Col>
							</Row>
						</Container>
					</div>
					<div className={`px-5 pb-5`}>
						<Container className="w-75">
							<Row className="align-items-center">
								<Col md={6} sm={12} className="text-center">
									<Img
										src="https://playace.co/assets/images/Add-Property14.png"
										className="img-fluid d-block mr-auto"
										width="40%"
									/>
								</Col>
								<Col md={6} sm={12}>
									<h4 className="text-uppercase mt-5">
										Review <span className="text-primary">guest profiles</span>
									</h4>
									<p>
										Get to know your guests from their Playace profile before
										accepting their requests.
									</p>
								</Col>
							</Row>
						</Container>
					</div>
					<div
						className={`${styles['list-property-bottom-section']} px-5 pb-5`}
					>
						<Container className="w-75">
							<Row className="align-items-center">
								<Col md={6} sm={12}>
									<h4 className="text-uppercase mt-5">
										Get <span className="text-primary">paid in full</span>
									</h4>
									<p>
										Let Playace manage your payments with its secured payment
										systems. Guests are charged before arrival and your are paid
										the whole amount automatically after successful check-in.
									</p>
								</Col>
								<Col md={6} sm={12}>
									<Img
										src="https://playace.co/assets/images/3.png"
										className="img-fluid d-block ml-auto"
										width="40%"
									/>
								</Col>
							</Row>
						</Container>
					</div>
					<div className={`${styles['background-grey']} text-center py-5`}>
						<ShowListModal>
							<h3 className="mb-4 text-uppercase">
								Earn from <span className="text-primary">your Space!</span>
							</h3>
						</ShowListModal>
					</div>
				</div>
			</Default>
			<Mobile>
				<div className="mt-5">
					<div className={`${styles['top-section']} p-5`}>
						<Container className="text-center px-5 pt-5">
							<ShowListModal>
								<h3 className="mb-4 text-uppercase">
									Earn from <span className="text-primary">your Space!</span>
								</h3>
								<p>
									With Playace every significant part of your space gets paid
									separately. Be it the living room, the bedroom, the backyard
									or the side lawn. Get your space discovered by the people who
									are in search for it to meet, teach, celebrate or create
									moments full of Fun and Happiness
								</p>
							</ShowListModal>
						</Container>
					</div>
					<div className="mx-auto text-center">
						<h3 className="d-inline-block text-uppercase mt-5 underline">
							How and why to <span className="text-primary">list with us?</span>
						</h3>
					</div>
					<div className={`${styles['list-property-top-section']} px-3 pb-5`}>
						<Container>
							<Row className="align-items-center">
								<Col md={6} sm={12}>
									<h4 className="text-uppercase mt-5">
										It's easy and <span className="text-primary">free.</span>
									</h4>
									<p>
										Listing with Playace is Simple and Free. Just Set a Price,
										Add Photos, a few details and your Space is Ready to Reach
										people in search for it.
									</p>
								</Col>
								<Col md={6} sm={12} className="text-center">
									<Img
										src="https://playace.co/assets/images/Add-Property13.png"
										className="img-fluid d-block ml-auto"
										width="60%"
									/>
								</Col>
							</Row>
						</Container>
					</div>
					<div className={`px-3 pb-5`}>
						<Container>
							<Row className="align-items-center">
								<Col md={6} sm={12} className="text-center order-2">
									<Img
										src="https://playace.co/assets/images/Add-Property14.png"
										className="img-fluid d-block ml-auto"
										width="40%"
									/>
								</Col>
								<Col md={6} sm={12} className="order-1">
									<h4 className="text-uppercase mt-5">
										Review <span className="text-primary">guest profiles</span>
									</h4>
									<p>
										Get to know your guests from their Playace profile before
										accepting their requests.
									</p>
								</Col>
							</Row>
						</Container>
					</div>
					<div
						className={`${styles['list-property-bottom-section']} px-3 pb-5`}
					>
						<Container>
							<Row className="align-items-center">
								<Col md={6} sm={12}>
									<h4 className="text-uppercase mt-5">
										Get <span className="text-primary">paid in full</span>
									</h4>
									<p>
										Let Playace manage your payments with its secured payment
										systems. Guests are charged before arrival and your are paid
										the whole amount automatically after successful check-in.
									</p>
								</Col>
								<Col md={6} sm={12}>
									<Img
										src="https://playace.co/assets/images/3.png"
										className="img-fluid d-block ml-auto"
										width="45%"
									/>
								</Col>
							</Row>
						</Container>
					</div>
					<div className={`${styles['background-grey']} text-center py-5`}>
						<ShowListModal>
							<h3 className="mb-4 text-uppercase">
								Earn from <span className="text-primary">your Space!</span>
							</h3>
						</ShowListModal>
					</div>
				</div>
			</Mobile>
		</>
	);
}

function ShowListModal({ children, ...props }) {
	let [show, setShow] = useState(false);
	let [category, setCategory] = useState('Terrace');
	let [location, setLocation] = useState('');
	let history = useHistory();
	const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
	const auth = useSelector(state => state.auth.data);
	let user = isAuthenticated ? JSON.parse(auth) : {};
	// const defaultToken = process.env.REACT_APP_BEARER_TOKEN;

	const { data, isLoading } = useQuery(
		['FETCH_PROPERTY_CATEGORIES', user.token],
		fetchPropertyCategories,
		{
			refetchOnWindowFocus: false,
		}
	);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const handleSubmit = e => {
		e.preventDefault();
		const listProperty = async () => {
			await Axios.post(
				`${URL}v1/property/list`,
				{
					location: location,
					category: category,
				},
				{
					headers: {
						Authorization: `Bearer ${user.token}`,
					},
				}
			)
				.then(response => {
					console.log(response.data);
					handleClose();
					history.push('/?enquiry=true');
				})
				.catch(errors => {
					console.log(errors);
				});
		};
		if (isAuthenticated) {
			listProperty();
		}
	};

	return (
		<>
			{children}
			{/* {console.log(user.token)} */}
			<button
				onClick={handleShow}
				className="btn btn-primary text-uppercase my-3 px-5"
			>
				List your space
			</button>
			{isAuthenticated ? (
				<Modal
					className={styles.fade}
					show={show}
					backdrop="static"
					onHide={handleClose}
					animation={true}
				>
					<Modal.Header closeButton>
						<Modal.Title>List your space</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Form onSubmit={handleSubmit}>
							<Form.Group>
								<Form.Label>Type of space</Form.Label>
								<Form.Control
									as="select"
									onChange={e => {
										setCategory(e.target.value);
										// console.log(category);
									}}
									value={category}
								>
									{!isLoading &&
										data &&
										data.data.map(category => {
											return (
												<option key={v4()} value={category.category_name}>
													{category.category_name}
												</option>
											);
										})}
								</Form.Control>
							</Form.Group>
							<Form.Group>
								<Form.Label>Location</Form.Label>
								<Form.Control
									type="text"
									placeholder="Enter Location"
									onChange={e => {
										setLocation(e.target.value);
									}}
									pattern="[A-Za-z]*"
									minLength="3"
									title="City name"
									required
								/>
							</Form.Group>
							<button
								type="submit"
								className="mx-auto d-block my-3 btn btn-primary"
							>
								Send Request
							</button>
						</Form>
					</Modal.Body>
				</Modal>
			) : (
				<>
					<Default>
						<Modal
							size="lg"
							show={show}
							onHide={handleClose}
							dialogClassName="modal-dimensions"
							backdrop="static"
							centered
						>
							<Auth handleClose={handleClose} />
						</Modal>
					</Default>
					<Mobile>
						<Modal
							size="lg"
							show={show}
							onHide={handleClose}
							backdrop="static"
							centered
						>
							<Auth handleClose={handleClose} />
						</Modal>
					</Mobile>
				</>
			)}
		</>
	);
}

//
