import React, { useEffect, useState } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import ReactPixel from 'react-facebook-pixel';
import { Helmet } from 'react-helmet';
import { useLocation, Link } from 'react-router-dom';
import { Container, Row, Col, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { Default, Mobile } from '../../helpers/Breakpoints';
import { loadRazorpay } from '../../modules/payments/Razorpay';
import { fetchPartyById } from '../../../api/requests';
import checked from '../../../assets/ic_checked.png';
import coupon from '../../../assets/ic_coupon.png';
import { applyCouponCode } from '../../../api/requests';
import styles from '../../modules/auth/Auth.module.scss';
import avatar from '../../../assets/square_logo.png';

import { useQuery } from 'react-query';

import {
	RenderUserObj,
	RenderDate,
	Loader,
	calculateDiscount,
} from '../../helpers';
import BookTicket from '../../modules/common/parties/BookTicket';

import { useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router';

export default function BookParty() {
	const [couponCode, setCouponCode] = useState('');
	const [couponDiscount, setCouponDiscount] = useState(0);
	const [allowedDiscount, setAllowedDiscount] = useState(0);
	const [message, setMessage] = useState('');
	const [couponLimit, setCouponLimit] = useState(0);
	const [showLoader, setShowLoader] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);
	const [ticketModal, setTicketModal] = useState(false);
	const [ticketsUpdated, setTicketsUpdated] = useState(false);
	const [currentTickets, setCurrentTickets] = useState(0);
	const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
	const userObj = RenderUserObj(useSelector(state => state.auth.data));
	let [guests, setGuests] = useState([
		{
			id: userObj.id,
			guest_name: userObj.first_name,
			guest_mobile: userObj.phone,
			guest_gender: userObj.gender === 2 ? 'Female' : 'Male',
		},
	]);

	const token = (userObj && userObj.token) || null;
	const { id } = useParams();
	const history = useHistory();

	const { data, isLoading } = useQuery(
		['partyDetail', id, token],
		fetchPartyById,
		{
			refetchOnWindowFocus: false,
		}
	);
	let amount = ((data && data.data.event_amount) || 0) * guests.length;

	let TaxAmount = parseFloat(amount * 0.05 + amount * 0.05 * 0.18);

	let finalAmount = parseFloat(amount + TaxAmount);
	let sessionTimer;
	useEffect(() => {
		loadRazorpay();
		ReactPixel.init('1890035134379309');
		ReactPixel.track('PageView');
		reroutePath();
		if (data && data.data.event_updates.tickets_available <= 1)
			checkGuestCount();
		timerSet();
		return () => {
			clearTimeout(sessionTimer);
		};
	}, []);

	useEffect(() => {
		reroutePath();
		if (data) sessionTimeout();
		if (data && data.data.event_updates.tickets_available <= 1)
			checkGuestCount();
	}, [data]);

	useEffect(() => {
		sessionTimeout();
	}, [guests]);

	useEffect(() => {
		// Update coupon discount on adding guests
		if (data !== undefined && couponCode !== '') {
			let discount = calculateDiscount(
				couponLimit,
				allowedDiscount,
				data.data.event_amount,
				guests.length
			);
			setCouponDiscount(discount);
		}
		// let data.data.event_updates.tickets_available = 4;
		if (data !== undefined) {
			if (
				guests.length >= data.data.event_updates.tickets_available ||
				guests.length >= 5
			) {
				checkGuestCount();
				timerSet();
			}
		}
	}, [guests]);

	const sessionTimeout = () => {
		// If the tickets are less than the guest count then it opens the warning modal
		if (data) {
			if (guests.length > data.data.event_updates.tickets_available) {
				setTicketModal(true);
			}
		}
	};

	const checkCheckoutTicketsAvailable = () => {
		fetchPartyById('partyDetail', id, token).then(res => {
			setCurrentTickets(res.data.event_updates.tickets_available);
			setTicketsUpdated(true);
		});
		setTicketModal(true);
	};

	const timerSet = () => {
		// Timer starts and after 2 minutes modal comes up
		function finalCount() {
			sessionTimer = setTimeout(() => {
				if (data) {
					if (guests.length > data.data.event_updates.tickets_available) {
						setTicketModal(true);
					} else {
						timerSet();
					}
				}
			}, 120000);
		}
		finalCount();
	};

	const handleTicketModal = () => {
		setTicketModal(false);
	};

	const handleTicketReroute = () => {
		sessionTimer && clearTimeout(sessionTimer);
		// if (data.data.event_updates.tickets_available > 0) {
		if (data.data.event_updates.tickets_available > 0) {
			handleTicketModal();
			window.location.reload();
		} else history.push(`/parties/page/1`);
	};

	const checkGuestCount = () => {
		if (data !== undefined) {
			if (guests.length <= 5) {
				if (data.data.event_updates.tickets_available <= 1) {
					setErrorMessage('This House Party is almost sold out');
				} else if (guests.length <= data.data.event_updates.tickets_available) {
					if (data.data.event_updates.tickets_available <= 5)
						setErrorMessage(
							'Cannot book for more people! This House Party is almost sold out.'
						);
					else if (
						data.data.event_updates.tickets_available > 5 &&
						guests.length === 5
					)
						setErrorMessage('Maximum guest limit reached');
				}
			}
		}
	};
	console.log(sessionTimer);
	const reroutePath = () => {
		if (data !== undefined && data.data.user_event_status !== '') {
			history.push(`/parties/${id}`);
		}
	};
	if (isLoading) {
		return (
			<div
				style={{ margin: '7rem 0 0 0', textAlign: 'center', height: '80vh' }}
			>
				<Loader />
			</div>
		);
	}

	// function AddGuest() {
	//   // console.log("added");
	//   // setGuests([...guests, { id: Date.now(), name: "", phone: "" }]);
	//   // // console.log(guests);
	//   // setGuests((prev) => [...prev, { id: Date.now(), name: "", phone: "" }]);
	//   // console.log(guests);
	// }
	function RemoveGuest(id) {
		setGuests(guests.filter(guest => guest.id !== id));
	}

	const handleReroute = () => {
		if (data.data.user_event_status !== '') {
			history.push(`/booking/party/${id}`);
		}
	};

	const handleCoupon = e => {
		setCouponDiscount(0);
		setMessage('');
		setCouponCode(e.target.value);
	};

	const handleBooking = () => {
		setShowLoader(true);
	};

	const couponCodeApply = () => {

		let partyData = {
			event_id: id,
			coupon_code: couponCode,
		}

		applyCouponCode(token, partyData).then(res => {
			if (
				couponCode.length > 0 &&
				res.data.message === "Coupon code doesn't exists."
			)
				setMessage('Invalid coupon code');
			else if (
				couponCode.length <= 0 &&
				res.data.message === "Coupon code doesn't exists."
			)
				setMessage('Please enter a valid coupon code');
			else if (
				res.data.message === 'Coupon code applied successfully.' &&
				res.data
			) {
				// Calculate discount for mobile UI
				let discount = calculateDiscount(
					res.data.data.coupon_limit,
					res.data.data.coupon_discount_percent,
					data.data.event_amount,
					guests.length
				);
				setAllowedDiscount(res.data.data.coupon_discount_percent);
				setCouponLimit(res.data.data.coupon_limit);
				setCouponDiscount(discount);
			}
		});
	};

	if (isLoading) {
		return (
			<div>
				<Loader />
			</div>
		);
	}

	return (
		<>
			<Helmet>
				<script>
					{`
					!function(f,b,e,v,n,t,s)
					{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
					n.callMethod.apply(n,arguments):n.queue.push(arguments)};
					if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version=‘2.0’;
					n.queue=[];t=b.createElement(e);t.async=!0;
					t.src=v;s=b.getElementsByTagName(e)[0];
					s.parentNode.insertBefore(t,s)}(window, document,‘script’,
					‘https://connect.facebook.net/en_US/fbevents.js’);
					fbq(‘init’, ‘1890035134379309’);
					fbq(‘track’, ‘PageView’);
					`}
				</script>
				<noscript>
					{`
					<img height=“1” width=“1" style=“display:none”
					src=“https://www.facebook.com/tr?id=1890035134379309&ev=PageView&noscript=1”
					/>
					`}
				</noscript>
			</Helmet>
			<Modal
				backdrop="static"
				show={ticketModal}
				onHide={handleTicketModal}
				centered
			>
				<button
					className={`${styles.closebtn} ${data.data.event_updates.tickets_available > 0 ? 'd-none' : 'd-block'
						} bg-primary text-white rounded border-0`}
					onClick={handleTicketReroute}
				>
					X
				</button>
				<Container className=" text-center py-5">
					<img
						src={avatar}
						alt="payment successful playace"
						height="150"
						width="150"
					/>
					<h3 className="fw-bold text-uppercase">
						{data.data.event_updates.tickets_available > 0
							? 'Request timed out'
							: 'Party sold out'}
					</h3>
					<p>
						{data.data.event_updates.tickets_available > 0
							? `Oops.. the party is almost sold out. Only ${data.data.event_updates.tickets_available > 1
								? `${ticketsUpdated
									? currentTickets
									: data.data.event_updates.tickets_available
								} invites`
								: `${ticketsUpdated
									? currentTickets
									: data.data.event_updates.tickets_available
								} invite`
							}   left`
							: 'The party you were trying to book has been sold out.'}
					</p>
					<button
						onClick={handleTicketReroute}
						className="btn btn-reg btn-primary fw-bold btn-padding"
					>
						{data.data.event_updates.tickets_available > 0
							? 'Okay'
							: 'Explore more parties'}
					</button>
				</Container>
			</Modal>
			<Default>
				<Container style={{ margin: '7rem auto', minHeight: '60vh' }}>
					<Row>
						<Col md={8}>
							<h3 className="mb-4">Guest Details</h3>
							<Row>
								<form className="w-100">
									{guests.map((guest, index) => {
										return (
											<React.Fragment key={guest.id}>
												<Col md={2}>
													<h5 className="pt-3">Guest {index + 1}</h5>
												</Col>
												<Col md={10}>
													<Row>
														<Col md={10}>
															{index === 0 ? (
																<div>
																	<input
																		placeholder="Name"
																		classes="my-3 w-100"
																		defaultValue={guest.guest_name}
																		disabled
																	/>
																	<input
																		placeholder="Phone"
																		classes="my-3 w-100"
																		defaultValue={guest.guest_mobile}
																		disabled
																	/>
																	<input
																		placeholder="Gender"
																		classes="my-3 w-100"
																		defaultValue={guest.guest_gender}
																		disabled
																	/>
																</div>
															) : (
																<div>
																	<input
																		placeholder="Name"
																		classes="my-3 w-100"
																		name={'guest_name'}
																		value={guest.guest_name}
																		onChange={e => {
																			e.persist();
																			setGuests(prev =>
																				prev.map(item => {
																					if (item.id === guest.id) {
																						return {
																							...item,
																							[e.target.name]: e.target.value,
																						};
																					} else {
																						return { ...item };
																					}
																				})
																			);
																		}}
																	/>
																	<input
																		placeholder="Phone"
																		type="number"
																		classes="my-3 w-100"
																		name={'guest_mobile'}
																		value={guest.guest_mobile}
																		onChange={e => {
																			e.persist();
																			setGuests(prev =>
																				prev.map(item => {
																					if (item.id === guest.id) {
																						return {
																							...item,
																							[e.target.name]: e.target.value,
																						};
																					} else {
																						return { ...item };
																					}
																				})
																			);
																		}}
																		onInput={e =>
																		(e.target.value = e.target.value.slice(
																			0,
																			10
																		))
																		}
																	/>
																	<div classes="my-3 w-100">
																		<select
																			name={'guest_gender'}
																			className="py-2 w-100 px-1"
																			onChange={e => {
																				e.persist();
																				setGuests(prev =>
																					prev.map(item => {
																						if (item.id === guest.id) {
																							return {
																								...item,
																								[e.target.name]: e.target.value,
																							};
																						} else {
																							return { ...item };
																						}
																					})
																				);
																			}}
																		>
																			<option value="">Gender</option>
																			<option value="Male">Male</option>
																			<option value="Female">Female</option>
																		</select>
																	</div>
																	{/* <input
																		placeholder="Gender"
																		type="text"
																		classes="my-3 w-100"
																		name={'gender'}
																		value={guest.phone}
																		
																		onInput={e =>
																			(e.target.value = e.target.value.slice(
																				0,
																				10
																			))
																		}
																	/> */}
																</div>
															)}
														</Col>
														<Col md={2} className="align-self-center">
															{guest.id === userObj.id ? (
																''
															) : (
																<FontAwesomeIcon
																	onClick={() => {
																		setGuests(
																			guests.filter(item => {
																				return item.id !== guest.id;
																			})
																		);
																		setErrorMessage('');
																	}}
																	icon={faTrash}
																	className="text-danger make-pointer"
																	size="lg"
																></FontAwesomeIcon>
															)}
														</Col>
													</Row>
												</Col>
											</React.Fragment>
										);
									})}
								</form>
							</Row>
							<div className="d-flex flex-column align-items-start">
								<button
									className="btn btn-reg btn-primary fw-bold btn-padding mt-5"
									onClick={() => {
										setGuests(prev => [
											...prev,
											{
												id: Date.now(),
												guest_name: '',
												guest_mobile: '',
												guest_gender: '',
											},
										]);
									}}
									disabled={
										guests.length >= 5 ||
										guests.length >= data.data.event_updates.tickets_available
									}
								>
									Add Guest
								</button>
								<small className="text-primary my-2">
									{errorMessage}
									{/* {guests.length > 4 ||
									guests.length > data.data.event_updates.tickets_available - 1
										? 'Maximum 4 user guests allowed'
										: null} */}
								</small>
							</div>
						</Col>
						<Col md={4}>
							<BookTicket
								view="receipt"
								classes="shadow rounded-lg"
								amount={amount}
								TaxAmount={TaxAmount}
								couponDiscount={couponDiscount}
								isAuthenticated={isAuthenticated}
								userObj={userObj}
								party={data}
								guestCount={guests.length}
								guests={guests}
								finalAmount={finalAmount}
								isBooking={handleBooking}
								handleReroute={handleReroute}
								handleTicket={checkCheckoutTicketsAvailable}
							/>
						</Col>
					</Row>
				</Container>
			</Default>
			<Mobile>
				<LoadingOverlay active={showLoader} spinner text="">
					<div style={{ display: 'none' }} className="loader-booking-container">
						<div className="loader-booking"></div>
					</div>
					<div
						className="bg-primary text-white py-2 px-3 d-flex justify-content-between align-items-end"
						style={{ margin: '4.1rem 0 0 0' }}
					>
						{showLoader && (
							<div>
								<Loader />
							</div>
						)}
						<div>
							<h5 className="fw-bold mb-3">{data.data.event_heading}</h5>
							{data && data.data.event_timings && data.data.location_details ? (
								<>
									<small>
										{data.data.location_details.event_locality},{' '}
										{data.data.location_details.city_name}
									</small>{' '}
									<br />
									<small>
										{RenderDate(data.data.event_timings.event_date)}
									</small>{' '}
									<br />
									<small>
										{data.data.event_timings.event_time_from} -{' '}
										{data.data.event_timings.event_time_to}
									</small>
								</>
							) : null}
						</div>
						<div>
							{data.data.event_amount ? (
								<small>
									Contribution <br /> &#x20b9; {data.data.event_amount} per
									person
								</small>
							) : (
								<>
									<small>Event Type:</small>
									<p className="fw-bold m-0">Free event</p>
								</>
							)}
						</div>
					</div>
					<div className="px-3">
						<h3 className="my-4">Guest Details</h3>
						<Row>
							{guests.map((guest, index) => {
								return (
									<React.Fragment key={guest.id}>
										<Col md={2}>
											<h5 className="pt-3">Guest {index + 1}</h5>
										</Col>
										<Col md={10}>
											<Row>
												<Col md={10}>
													{/* <Input
                          placeholder="Name"
                          classes="mb-3 mt-3 w-100"
                          value={guest.name}
                        />
                        <Input
                          placeholder="Phone number"
                          classes="mb-3 w-100"
                          value={guest.phone}
                        /> */}

													{index === 0 ? (
														<div>
															<input
																placeholder="Name"
																classes="my-3 w-100"
																defaultValue={guest.guest_name}
																disabled
															/>
															<input
																placeholder="Phone"
																classes="my-3 w-100"
																defaultValue={guest.guest_mobile}
																disabled
															/>
															<input
																placeholder="Gender"
																classes="my-3 w-100"
																defaultValue={guest.guest_gender}
																disabled
															/>
														</div>
													) : (
														<div>
															<input
																placeholder="Name"
																classes="my-3 w-100"
																name={'guest_name'}
																value={guest.guest_name}
																onChange={e => {
																	e.persist();
																	setGuests(prev =>
																		prev.map(item => {
																			if (item.id === guest.id) {
																				return {
																					...item,
																					[e.target.name]: e.target.value,
																				};
																			} else {
																				return { ...item };
																			}
																		})
																	);
																}}
															/>
															<input
																type="number"
																placeholder="Phone"
																classes="my-3 w-100"
																name={'guest_mobile'}
																value={guest.guest_mobile}
																onChange={e => {
																	e.persist();
																	setGuests(prev =>
																		prev.map(item => {
																			if (item.id === guest.id) {
																				return {
																					...item,
																					[e.target.name]: e.target.value,
																				};
																			} else {
																				return { ...item };
																			}
																		})
																	);
																}}
																maxLength="10"
																minLength="10"
																onInput={e =>
																	(e.target.value = e.target.value.slice(0, 10))
																}
															/>

															<div classes="my-3 w-100">
																<select
																	name={'guest_gender'}
																	className="py-2 w-100 px-1"
																	onChange={e => {
																		e.persist();
																		setGuests(prev =>
																			prev.map(item => {
																				if (item.id === guest.id) {
																					return {
																						...item,
																						[e.target.name]: e.target.value,
																					};
																				} else {
																					return { ...item };
																				}
																			})
																		);
																	}}
																>
																	<option value="">Gender</option>
																	<option value="Male">Male</option>
																	<option value="Female">Female</option>
																</select>
															</div>
														</div>
													)}
												</Col>
												<Col md={2} className="align-self-center">
													{guest.id === userObj.id ? (
														''
													) : (
														<FontAwesomeIcon
															style={{ cursor: 'pointer' }}
															onClick={() => {
																RemoveGuest(guest.id);
																setErrorMessage('');
															}}
															icon={faTrash}
															className="text-danger mt-2"
															size="lg"
														></FontAwesomeIcon>
													)}
												</Col>
											</Row>
										</Col>
									</React.Fragment>
								);
							})}
						</Row>
						<div className="d-flex flex-column align-items-start">
							<button
								className="btn btn-block btn-primary fw-bold btn-padding mt-3"
								onClick={() => {
									setGuests(prev => [
										...prev,
										{
											id: Date.now(),
											guest_name: '',
											guest_mobile: '',
											guest_gender: '',
										},
									]);
								}}
								disabled={
									guests.length >= 5 ||
									guests.length >= data.data.event_updates.tickets_available
								}
							>
								Add Guest
							</button>
							<small className="text-primary my-2">
								{errorMessage}
								{/* {guests.length > 4 ||
								guests.length > data.data.event_updates.tickets_available - 1
									? `Maximum ${guests.length} user guests allowed`
									: null} */}
							</small>
						</div>
					</div>
					<div className="px-3 my-4">
						<h5>Contribution</h5>
						<div className="d-flex justify-content-between align-items-center my-3">
							<div className="d-flex flex-column">
								<small className="text-muted d-block">
									Item total ({data.data.event_amount} x {guests.length})
								</small>
								<small
									style={{
										display: couponDiscount ? 'block' : 'none',
										color: '#ff1844',
									}}
								>
									Promo - ({couponCode})
								</small>
								<small
									style={{
										display: couponDiscount ? 'block' : 'none',
										color: '#ff1844',
										fontSize: '10px',
									}}
								>
									{allowedDiscount}% off {couponLimit && `upto ${couponLimit}`}
								</small>
							</div>
							<small className="text-muted d-block">
								&#x20b9; {amount.toFixed(2)}
							</small>
						</div>
						<div className="d-flex justify-content-between align-items-center my-3">
							<small className="text-muted d-block">Taxes and charges</small>
							<small className="text-muted d-block">
								&#x20b9;{' '}
								{data.data.event_amount * guests.length == couponDiscount
									? 0
									: TaxAmount.toFixed(2)}
							</small>
						</div>
						<div className="d-flex justify-content-between align-items-center my-3">
							<small className="text-muted d-block">Final contribution</small>
							<small className="fw-bold d-block">
								&#x20b9;
								{
									data.data.event_amount * guests.length == couponDiscount
										? 0
										: // couponDiscount
										(amount + TaxAmount - couponDiscount).toFixed(2)
									// : (amount + TaxAmount).toFixed(2)
								}
							</small>
						</div>
						<div className="d-flex align-items-center justify-content-between">
							<div
								className={`group w-100 ${couponDiscount ? 'd-none' : 'd-flex'
									} align-items-center`}
							>
								<img className="mr-2" style={{ width: '20px' }} src={coupon} />
								<div className="w-100">
									<input
										className={`mb-0`}
										type="text"
										required
										value={couponCode}
										onChange={handleCoupon}
									/>
									<span className="highlight"></span>
									<span className="bar"></span>
									<label className="ml-4">Enter Coupon code</label>
								</div>
							</div>
							{/* </div> */}
							{/* <a href="/#" > Apply</a> */}
							{/* <button type="button" >Apply</button> */}
							<div
								className={`${couponDiscount ? 'd-flex' : 'd-none'
									} align-items-center`}
							>
								<img className="mr-2" style={{ width: '20px' }} src={checked} />
								<div>
									<p style={{ margin: 0, fontWeight: '700' }}>
										Code {couponCode ? couponCode : ''} applied!
									</p>
									<small>Promo code applied successfully.</small>
								</div>
							</div>
							<button
								type="button"
								className="border-0 bg-white"
								onClick={couponCodeApply}
								style={{
									display: couponDiscount <= 0 ? 'block' : 'none',
									color: '#ff1844',
								}}
							>
								Apply
							</button>
							<div
								className={`${couponDiscount ? 'd-flex' : 'd-none'
									} flex-column align-items-end`}
								style={{ color: '#ff1844' }}
							>
								<small>-Rs {couponDiscount}</small>
								<button
									className="bg-white border-0 p-0"
									style={{ color: '#ff1844' }}
									onClick={() => {
										setCouponDiscount(0);
										setCouponCode('');
									}}
								>
									<small>remove</small>
								</button>
							</div>
						</div>
						<small
							className="d-flex align-items-baseline justify-content-between px-4"
							style={{ color: '#ff1844' }}
						>
							{message.length > 0 && message}
						</small>
						<hr className="my-3 w-100" />
						{/* </div> */}
					</div>
					<div className="d-flex fixed-bottom ticket justify-content-between align-items-center px-2 py-1">
						<div>
							{finalAmount === 0 ? (
								<span className="fw-bolder">Free Event</span>
							) : (
								<span>Contribution</span>
							)}
							<br />
							<span className="fw-bolder">
								&#x20b9;
								{
									data.data.event_amount * guests.length == couponDiscount
										? 0
										: // couponDiscount
										(amount + TaxAmount - couponDiscount).toFixed(2)
									// : amount + TaxAmount
								}
								/- ({guests.length} Guest
								{guests.length > 1 ? 's' : null})
							</span>
						</div>
						{finalAmount === 0 ? (
							<BookTicket
								view="sticky"
								isAuthenticated={isAuthenticated}
								userObj={userObj}
								text="Book Now"
								party={data}
								guests={guests}
								guestCount={guests.length}
								finalAmount={finalAmount}
								isBooking={handleBooking}
								handleReroute={handleReroute}
								handleTicket={checkCheckoutTicketsAvailable}
							/>
						) : (
							<BookTicket
								view="sticky"
								isAuthenticated={isAuthenticated}
								userObj={userObj}
								text="Pay Now"
								handleTicket={checkCheckoutTicketsAvailable}
								party={data}
								guestCount={guests.length}
								guests={guests}
								finalAmount={finalAmount}
								amount={amount}
								TaxAmount={TaxAmount}
								couponDiscount={couponDiscount}
								handleReroute={handleReroute}
								isBooking={handleBooking}
							/>
						)}
					</div>
				</LoadingOverlay>
			</Mobile>
		</>
	);
}
