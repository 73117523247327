import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import axios from 'axios';
import { URL } from '../../../api/requests';
import InputField from '../common/InputField';
import { useEffect } from 'react';
import OTPComponent from '../common/OTPComponent';

/*DEFAULT IS FIRST SHOWN 

VIEW SWITCHES ACCORDING TO CODE FROM AUTH.JS
*/

export function Default(props) {
	return (
		<div>
			<h3 className="m-0 text-uppercase fw-bold">
				{props.newUser ? <span>Join Us</span> : <span>Login</span>}
			</h3>
			<small className="mb-3 d-block text-muted">
				Host/Attend House Parties
			</small>
			<form onSubmit={props.handleSubmit}>
				<InputField
					value={props.phone}
					handleChange={props.handleChange}
					label="Enter Mobile Number"
					pattern="[0-9]*"
					maxLength="10"
					minLength="10"
					autofocus={true}
				/>
				<Button type="submit" className="btn btn-primary mt-5 py-2">
					{props.newUser ? <>Continue</> : <>Request OTP</>}
				</Button>
			</form>
			<small
				className="d-block text-primary make-pointer mt-5"
				onClick={() => {
					props.setNewUser(!props.newUser);
				}}
			>
				{!props.newUser ? (
					<>New to playace? Create an account</>
				) : (
					<>Already have an account? Login.</>
				)}
			</small>
		</div>
	);
}

export function Login(props) {
	let [resendOTP, setresendOTP] = useState(false);

	useEffect(() => {
		props.setNewUser(false);
	}, [props]);

	function resendOTPRequest() {
		axios
			.post(`${URL}v1/otp/send`, {
				opt_type: 'mobile',
				email: props.email || '',
				mobile: props.phone,
			})
			.then(() => {
				setresendOTP(true);
			});
	}
	// console.log(props);
	return (
		<div>
			<h3 className="m-0 text-uppercase fw-bold">Login</h3>
			<small className="mb-5 d-block text-muted">
				Host/Attend House Parties
			</small>
			<OTPComponent
				phone={props.phone}
				handleClose={props.handleClose}
				handleHost={props.handleHost}
			/>
			<p
				className="text-primary mt-3 make-pointer"
				style={{ textDecoration: 'underline' }}
				onClick={() => {
					resendOTPRequest();
				}}
			>
				Resend OTP
			</p>
			{resendOTP && (
				<small className="text-success">OTP has been resent.</small>
			)}
		</div>
	);
}

export function Signup(props) {
	let [email, setEmail] = useState('');
	let [phone, setPhone] = useState('');
	let [firstName, setFirstName] = useState('');
	let [lastName, setLastName] = useState('');
	let [gender, setGender] = useState('');
	let [phoneLabel, setPhoneLabel] = useState('Enter Mobile Number');
	let [firstNameLabel, setFirstNameLabel] = useState('First Name');
	let [lastNameLabel, setLastNameLabel] = useState('Last Name');
	let [emailLabel, setEmailLabel] = useState('Enter Email');

	// const [mutate, info] = useMutation(registerNewUser);

	useEffect(() => {
		props.setNewUser(true);
	}, [props]);

	function handleSubmit(e) {
		e.preventDefault();
		if (props.phone) {
			// console.log(props.phone, email, firstName, lastName, gender);
			axios
				.post(`${URL}v1/register`, {
					first_name: firstName,
					last_name: lastName,
					mobile: props.phone,
					email: email,
					gender: gender,
					link: ' ',
				})
				.then(response => {
					// console.log(response.data);
					axios
						.post(`${URL}v1/otp/send`, {
							opt_type: 'mobile',
							email: email,
							mobile: props.phone,
						})
						.then(() => {
							props.setOTPSent(true);
						})
						.catch(errors => {
							console.log(errors);
						});
				})
				.catch(error => {
					console.log(error);
				});
			// const createNewUser = async () => {
			//   try {
			//     await mutate(
			//       {
			//         first_name: firstName,
			//         last_name: lastName,
			//         mobile: props.phone,
			//         email: email,
			//         link: "test",
			//         gender: gender,
			//         location: "test",
			//         description: "test",
			//         image: "test.jpg",
			//         device: "test",
			//       },
			//       {
			//         onSuccess: () => {
			//           return <Redirect to="/" />;
			//         },
			//       }
			//     );
			//     console.log(info);
			//   } catch (e) {
			//     console.log(e);
			//   }
			// };
			// createNewUser();
		} else {
			console.log(phone, email);
		}
	}

	function handleEmailChange(e) {
		setEmail(e.target.value);
	}

	function handlePhoneChange(e) {
		setPhone(e.target.value);
	}
	function handlefirstNameChange(e) {
		setFirstName(e.target.value);
	}

	function handleLastNameChange(e) {
		setLastName(e.target.value);
	}
	console.log(gender);
	return (
		<div>
			<h3 className="m-0 text-uppercase fw-bold">Sign up</h3>
			<small className="text-primary mb-5 d-block">Create new account.</small>
			<Form onSubmit={handleSubmit}>
				<InputField
					value={props.phone}
					handleChange={handlePhoneChange}
					label={!props.phone ? phoneLabel : ''}
					pattern="[0-9]*"
					maxLength="10"
					minLength="10"
					required
				/>
				<InputField
					label={!firstName ? firstNameLabel : ''}
					value={firstName}
					handleChange={handlefirstNameChange}
					type="text"
					pattern="^[a-zA-Z\s]{3,20}$"
					required
				/>
				<InputField
					label={!lastName ? lastNameLabel : ''}
					value={lastName}
					handleChange={handleLastNameChange}
					type="text"
					pattern="^[a-zA-Z\s]{3,20}$"
					required
				/>

				<InputField
					label={!email ? emailLabel : ''}
					type="email"
					value={email}
					handleChange={handleEmailChange}
					pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
				/>

				<Form.Group className="pt-3">
					<Form.Check
						// inline
						id="Male"
						type="radio"
						label="Male"
						value="Male"
						onClick={() => setGender('1')}
						checked={gender === '1' ? true : false}
					/>
					<Form.Check
						// inline
						id="Female"
						type="radio"
						label="Female"
						value="Female"
						onClick={() => setGender('2')}
						checked={gender === '2' ? true : false}
					/>
				</Form.Group>

				<Button type="submit" className="btn btn-primary mt-3 float-right py-2">
					Sign up
				</Button>
			</Form>
		</div>
	);
}
