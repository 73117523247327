import React from "react";
import ReactDOM from "react-dom";
import "./assets/index.scss";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";

import ReactGA from 'react-ga';
const TRACKING_ID = "UA-163435677-1";

ReactGA.initialize(TRACKING_ID);

ReactDOM.render(
  <BrowserRouter>
      <Provider store={store}>
        <App />
      </Provider>    
  </BrowserRouter>,
  document.getElementById("root")
);
