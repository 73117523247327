import React, { Suspense, useState, useEffect, lazy } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import ReactPixel from 'react-facebook-pixel';
import { Helmet } from 'react-helmet';
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faStar } from "@fortawesome/free-solid-svg-icons";
// import axios from 'axios';

import CTA from '../Home/CTA';
import Attend from '../Home/Attend';
import { PhotoGrid } from '../../modules/common';
import BookTicket from '../../modules/common/parties/BookTicket';
import { Default, Mobile } from '../../helpers/Breakpoints';
import { RenderUserObj, RenderDate, Loader } from '../../helpers';
import { fetchPartyById } from '../../../api/requests';

import AHE from '../../assets/img/attend host earn.png';

import About from '../../modules/content/EventDetails/About';
// import Amenities from "./Amenities";
// import Reviews from '../../modules/content/EventDetails/Reviews';
import Expander from '../../modules/content/EventDetails/Expander';

import { useSelector } from 'react-redux';

import { useParams, Link } from 'react-router-dom';
import { useQuery } from 'react-query';
const Amenities = lazy(() => import('./Amenities'));

export default function PartiesDetail(props) {
	const { id } = useParams();

	const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
	const userObj = RenderUserObj(useSelector(state => state.auth.data));
	const token = (userObj && userObj.token) || null;

	console.log("Is user authenticated : " + isAuthenticated);

	useEffect(() => {
		if (props.match) {
			// window.location.reload();
		}
		ReactPixel.init('1890035134379309');
		ReactPixel.track('PageView');
	}, []);

	const { data, isLoading } = useQuery(
		['partyDetail', id, token],
		fetchPartyById,
		{
			//console.log("Party object : " + data.)
			// refetchOnWindowFocus: false,
		}
	);

	const getPartyHosted = (data) => {

	}

	if (isLoading) {
		return (
			<div
				style={{ minHeight: '70vh', margin: '7rem auto', textAlign: 'center' }}
			>
				<Loader height="80px" width="80px" />
			</div>
		);
	}
	console.log(data);

	return (
		<>
			<Helmet>
				<script>
					{`!function(f,b,e,v,n,t,s)
					{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
					n.callMethod.apply(n,arguments):n.queue.push(arguments)};
					if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version=‘2.0’;
					n.queue=[];t=b.createElement(e);t.async=!0;
					t.src=v;s=b.getElementsByTagName(e)[0];
					s.parentNode.insertBefore(t,s)}(window, document,‘script’,
					‘https://connect.facebook.net/en_US/fbevents.js’);
					fbq(‘init’, ‘1890035134379309’);
					fbq(‘track’, ‘PageView’);`}
				</script>
				<noscript>
					{`<img height=“1” width=“1" style=“display:none”
					src=“https://www.facebook.com/tr?id=1890035134379309&ev=PageView&noscript=1”
					/>`}
				</noscript>
			</Helmet>
			<Default>
				<div style={{ margin: '7rem 0 0 0' }}>
					<Container>
						<Row>
							<Col lg={8} md={7}>
								<PhotoGrid
									src={
										data && data.data.event_cover_image
											? data.data.event_cover_image
											: null
									}
								/>
								<About
									title="About the Party"
									content={data.data.event_description}
									type="party"
								/>
								<Suspense fallback={<p>Loading...</p>}>
									<Amenities amenities={data.data.event_amenities} />
								</Suspense>
								<Expander
									title="House Rules"
									data={`${data.data.event_rules}`}
								/>
								<Expander
									title="How it works"
									data="<p>A. Send a request to attend the house party.<br/>
  										B.  Process the payment and wait for the approval from the host.<br/>
  										C.  If your request is approved, you will receive the party details on your registered email. Simultaneously, you can also check the status of your request on your dashboard.<br/>
  										D.  If your request is not approved, you will receive a 100% refund within 5-7days.</p>"
								/>
								<Expander
									title="Cancellation Policy"
									data="<p>A. 100% refund <br/>
											<p>&emsp; i.   If you cancel 48 hours before to the party start time.
											<br/>&emsp; ii.  If the host cancels the party</p>
										B. 50% refund if you cancel 24 hours before the party start time. <br/>
								C. No refunds if you cancel within 24 hours of the party start time. </p>"
								/>
								{/* <Reviews /> */}
							</Col>
							<Col lg={4} md={5}>
								<BookTicket
									isAuthenticated={isAuthenticated}
									userObj={userObj}
									party={data}
									sold={data.data.event_sold_out}
									isLoading={isLoading}
								/>
							</Col>
						</Row>
						<Attend title="You may also like" button={false} />
						<div className="my-5" />
						<CTA />
					</Container>
				</div>
			</Default>
			<Mobile>
				{/* //Main */}
				<div className="mx-4 mTop" style={{ marginTop: '5rem' }}>
					{/* {party && data.data.event_image ? console.log(`${data.data.event_image.image_url}${data.data.event_image.image_file}?q=65&auto=compress`) : null} */}
					<PhotoGrid
						src={
							data && data.data.event_cover_image
								? data.data.event_cover_image
								: null
						}
					/>
					<div className="d-flex justify-content-between mx-2 my-4">
						<div className="d-flex flex-column">
							<h3 className="fw-bold">{data.data.event_heading}</h3>
							{data && data.data.event_timings && data.data.location_details ? (
								<>
									<small className="d-block">
										{data.data.location_details.event_locality},{' '}
										{data.data.location_details.city_name}
									</small>{' '}
									<small className="d-block">
										{RenderDate(data.data.event_timings.event_date)}
									</small>{' '}
									<small className="d-block">
										{data.data.event_timings.event_time_from} -{' '}
										{data.data.event_timings.event_time_to}
									</small>
								</>
							) : null}
						</div>
						<div className="text-center">
							{data.data.host_info ? (
								<>
									<p className="mb-1 fw-bold">Hosted by</p>
									<img
										src={data.data.host_info.image}
										alt="profile-user"
										className="rounded-circle"
										height="40"
										width="40"
									/>
									<br />
									<small>{data.data.host_info.first_name}</small>
									<br />
									{ // Get the number of parties hosted
										(() => {
										switch (data.data.host_info.parties_hosted.toString()) {
											case '1': return <small> 'First Time Host' </small>;
											case '2': return <small> {data.data.host_info.parties_hosted - 1} party hosted</small>;
											default: return <small> {data.data.host_info.parties_hosted - 1} parties hosted</small>;
										}
										})()
									}
									<br />
								</>
							) : null}
						</div>
					</div>
					<BookTicket
						view="mobile"
						isAuthenticated={isAuthenticated}
						userObj={userObj}
						party={data}
						sold={data.data.event_sold_out}
						isLoading={isLoading}
					/>
					<div className="mt-5">
						<About
							title="About the party"
							type="party"
							content={data.data.event_description}
						/>
					</div>
					<Suspense fallback={<p>Loading...</p>}>
						<Amenities amenities={data.data.event_amenities} />
					</Suspense>
					<Expander title="House Rules" data={`${data.data.event_rules}`} />
					<Expander
						title="How it works"
						data="<p>A. Send a request to attend the house party.<br/>
  							B.  Process the payment and wait for the approval from the host.<br/>
  							C.  If your request is approved, you will receive the party details on your registered email. Simultaneously, you can also check the status of your request on your dashboard.<br/>
  							D.  If your request is not approved, you will receive a 100% refund within 5-7days.</p>"
					/>
					<Expander
						title="Cancellation Policy"
						data="<p>A. 100% refund <br/>
									<p>&emsp; i. If you cancel 48 hours before to the party start time.
									<br/>&emsp; ii. If the host cancels the party</p>
								B. 50% refund if you cancel 24 hours before the party start time. <br/>
								C. No refunds if you cancel within 24 hours of the party start time. </p>"
					/>
					{/* <Reviews /> */}
					<Attend title="You may also like" button={false} />
					<div className="p-3 rounded" style={{ border: '2px solid #eaeaea' }}>
						<div className="d-flex justify-content-between align-items-center">
							<div>
								<img src={AHE} className="w-75" alt="attend host earn" />
							</div>
							<div>
								<p className="text-muted mb-2">
									Have a great idea for a party?
								</p>
								<h5 className="fw-bold">HOST YOUR OWN PARTY</h5>
							</div>
						</div>
						<div className="text-center">
							<Link to="/host-party" className="btn btn-primary text-center">
								Get started
							</Link>
						</div>
					</div>
				</div>
				{/* //Footer ticket */}
				<div className="d-flex fixed-bottom ticket justify-content-between align-items-center px-2 py-1">
					{data.data.event_amount ? (
						<div>
							<span>Contribution</span>
							<br />
							<span className="fw-bolder">
								Rs.{data.data.event_amount}/- per person
							</span>
						</div>
					) : (
						<h4 className="fw-bold m-0">Free Event</h4>
					)}
					<BookTicket
						view="sticky"
						isAuthenticated={isAuthenticated}
						userObj={userObj}
						text="Request to book"
						party={data}
						sold={data.data.event_sold_out}
						isLoading={isLoading}
					/>
				</div>
			</Mobile>
		</>
	);
}
