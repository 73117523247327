import { createStore, applyMiddleware, compose } from 'redux';
import logger from 'redux-logger';
import thunk from 'redux-thunk';
import { rootReducer } from './reducers';

//ADD LOGGER TO SEE GLOBAL STATE CHANGES
const middleware = [logger, thunk];

//Save to localstorage
function saveToLocalStorage(state) {
	try {
		const serializedState = JSON.stringify(state);
		localStorage.setItem('state', serializedState);
	} catch (e) {
		console.log(e);
		return undefined;
	}
}

//Load from localstorage
function loadFromLocalStorage() {
	try {
		const serializedState = localStorage.getItem('state');
		if (serializedState === null) {
			return undefined;
		}
		return JSON.parse(serializedState);
	} catch (e) {
		console.log(e);
		return undefined;
	}
}

//Update the changes in localStorage
const persistedState = loadFromLocalStorage();

const store = createStore(
	rootReducer,
	persistedState,
	compose(
		applyMiddleware(...middleware)
		// window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
	)
);

//Subscribe to store and check for changes
store.subscribe(() =>
	saveToLocalStorage({
		auth: store.getState().auth,
	})
);

export default store;
