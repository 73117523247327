import React, { useEffect } from 'react';
import { Default, Mobile } from '../../helpers/Breakpoints';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
	Form,
	FormGroup,
	FormControl,
	Col,
	Row,
	Container,
} from 'react-bootstrap';
import BookTicket from '../../modules/common/spaces/BookTicket';

import { fetchSpaceById } from '../../../api/requests';
import { Loader, RenderUserObj } from '../../helpers';
import { loadRazorpay } from '../../modules/payments/Razorpay';

export default function BookSpace() {
	const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
	const userObj = RenderUserObj(useSelector(state => state.auth.data));
	const { id } = useParams();

	const { isLoading, data } = useQuery(['spaceDetail', id], fetchSpaceById, {
		refetchOnWindowFocus: false,
	});

	useEffect(() => {
		loadRazorpay();
	}, []);

	if (isLoading) {
		return (
			<div
				style={{ margin: '7rem 0 0 0', textAlign: 'center', height: '80vh' }}
			>
				<Loader />
			</div>
		);
	}

	let TaxAmount = parseFloat(data.rate * 0.05 + data.rate * 0.05 * 0.18);

	let finalAmount = +data.rate + +TaxAmount;

	return (
		<>
			<Default>
				<Container style={{ margin: '7rem auto' }}>
					<Row>
						<Col md={8}>
							<h1>Space Booking</h1>
							<div className="d-flex align-items-center">
								<h4 className="text-secondary">{data.name}</h4>
								<h4 className="ml-3 p-2 badge badge-secondary fw-bolder text-uppercase">
									{data.category_name}
								</h4>
							</div>
							<Form className="mt-4">
								<FormGroup>
									<Form.Label>Enter date:</Form.Label>
									<FormControl type="date" placeholder="Select date" />
								</FormGroup>
								<Form.Row>
									<FormGroup as={Col} md="6" xs="6">
										<Form.Label>Enter time from:</Form.Label>
										<FormControl type="time" placeholder="Select time" />
									</FormGroup>
									<FormGroup as={Col} md="6" xs="6">
										<Form.Label>Enter time to:</Form.Label>
										<FormControl type="time" placeholder="Select time" />
									</FormGroup>
								</Form.Row>
							</Form>
						</Col>
						<Col md={4}>
							<BookTicket
								view="receipt"
								classes="shadow rounded-lg"
								space={data}
								isAuthenticated={isAuthenticated}
								userObj={userObj}
								finalAmount={finalAmount}
								TaxAmount={TaxAmount}
							/>
						</Col>
					</Row>
				</Container>
			</Default>
			<Mobile>
				<div>
					<div className="mTop bg-secondary text-white py-4 px-3 d-flex justify-content-between align-items-end">
						<div>
							<h5 className="fw-bold mb-2">{data.name}</h5>
							{data && data.timings && data.address ? (
								<>
									<small>{data.address.locality}</small> <br />
									<small>Availability: {data.timings}</small>
									<br />
								</>
							) : null}
							<small>
								{data.limit} - {data.category_name}
							</small>
						</div>
						<small className="d-block text-nowrap">
							&#x20b9;{data.rate}/hour
						</small>
					</div>
					<Form className="mx-4 my-4">
						<FormGroup>
							<Form.Label>Enter date:</Form.Label>
							<FormControl type="date" placeholder="Select date" />
						</FormGroup>
						<Form.Row>
							<FormGroup as={Col} md="6" xs="6">
								<Form.Label>Enter time from:</Form.Label>
								<FormControl type="time" placeholder="Select time" />
							</FormGroup>
							<FormGroup as={Col} md="6" xs="6">
								<Form.Label>Enter time to:</Form.Label>
								<FormControl type="time" placeholder="Select time" />
							</FormGroup>
						</Form.Row>
					</Form>
					<BookTicket
						view="receipt"
						space={data}
						isAuthenticated={isAuthenticated}
						userObj={userObj}
						finalAmount={finalAmount}
						TaxAmount={TaxAmount}
					/>
					<div className="d-flex fixed-bottom ticket justify-content-between align-items-center px-2 py-1">
						<div>
							<span>All rates on hourly basis</span>
							<br />
							<span className="fw-bolder">&#x20b9;{finalAmount} per hour</span>
						</div>
						<BookTicket
							view="sticky"
							isAuthenticated={isAuthenticated}
							userObj={userObj}
							text="Pay Now"
							space={data}
							finalAmount={finalAmount}
						/>
					</div>
				</div>
			</Mobile>
		</>
	);
}
