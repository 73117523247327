import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import {
  Row,
  Col,
  Container,
  Nav,
  InputGroup,
  Button,
  FormControl,
} from "react-bootstrap";

import { Mobile, Default } from "../../helpers/Breakpoints";

import { useLocation, NavLink } from "react-router-dom";

export default function Footer() {
  const location = useLocation();
  return (
    <footer
      className={
        location.pathname === "/contact" ||
          location.pathname === "/referral" ||
          location.pathname === "/dashboard" ||
          location.pathname === "/list-space" ||
          location.pathname === "/host-party"
          ? `bg-primary text-white pt-2`
          : `bg-primary text-white pt-2 mTop`
      }
    >
      <Container>
        <Row className="mt-5">
          <Default>
            <Col md={3} sm={12}>
              <Nav as="ul" className="flex-column footer-nav">
                {/* <Nav.Item as="li">
                  <Nav.Link
                    as={NavLink}
                    to="/faq"
                    className="text-white fw-bold"
                  >
                    FAQ
                  </Nav.Link>
                </Nav.Item> */}
                <Nav.Item as="li">
                  <Nav.Link
                    as={NavLink}
                    to="/about"
                    className="text-white fw-bold"
                  >
                    ABOUT US
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Nav.Link
                    as={NavLink}
                    to="/terms"
                    className="text-white fw-bold"
                  >
                    TERMS AND CONDITIONS
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Nav.Link
                    as={NavLink}
                    to="/referral"
                    className="text-white fw-bold">
                    REFER A FRIEND
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Nav.Link
                    href="https://www.youtube.com/channel/UC8L2VO2huuwOJKqlMEOauBA"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-white fw-bold"
                  >
                    VIDEO GALLERY
                  </Nav.Link>
                </Nav.Item>
                {/* <Nav.Item as="li">
                  <Nav.Link
                    as={NavLink}
                    to="/contact"
                    className="text-white fw-bold"
                  >
                    CAREERS
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Nav.Link href="/#" className="text-white fw-bold">
                    BLOG
                  </Nav.Link>
                </Nav.Item> */}
              </Nav>
            </Col>
            <Col md={3} sm={12}>
              <Nav as="ul" className="flex-column footer-nav">
                <Nav.Item as="li">
                  <Nav.Link
                    as={NavLink}
                    to="/privacy"
                    className="text-white fw-bold"
                  >
                    PRIVACY POLICY
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Nav.Link
                    as={NavLink}
                    to="/refunds"
                    className="text-white fw-bold"
                  >
                    REFUNDS
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Nav.Link
                    as={NavLink}
                    to="/disclaimer"
                    className="text-white fw-bold"
                  >
                    DISCLAIMER
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col md={1} sm={12}></Col>
            <Col md={5} sm={12} className="mt-2">
              <p className="m-0 mb-1 line-height-footer">
                Keep yourself updated about the latest parties!
              </p>
              <InputGroup className="mb-3 text-muted">
                <FormControl
                  placeholder="Email address"
                  aria-label="Email address"
                  size="lg"
                />
                <InputGroup.Append>
                  <Button variant="light" size="lg" className="text-muted">
                    Subscribe
                  </Button>
                </InputGroup.Append>
              </InputGroup>
              <div className="social d-flex justify-content-between align-items-center align-content-center">
                <p className="line-height-footer">Follow us on:</p>
                <a
                  href="https://www.facebook.com/playaceapp/"
                  className="d-block mb-3"
                >
                  <FontAwesomeIcon
                    icon={faFacebook}
                    size="2x"
                    className="text-white"
                  ></FontAwesomeIcon>
                </a>
                <a
                  href="https://twitter.com/playaceapp"
                  className="d-block mb-3"
                >
                  <FontAwesomeIcon
                    icon={faTwitter}
                    size="2x"
                    className="text-white"
                  ></FontAwesomeIcon>
                </a>
                <a
                  href="https://www.instagram.com/playaceapp/"
                  className="d-block mb-3"
                >
                  <FontAwesomeIcon
                    icon={faInstagram}
                    size="2x"
                    className="text-white"
                  ></FontAwesomeIcon>
                </a>
              </div>
            </Col>
          </Default>
          <Mobile>
            <Col xs={6}>
              <Nav as="ul" className="flex-column footer-nav">
                {/* <Nav.Item as="li">
                  <Nav.Link
                    as={NavLink}
                    to="/faq"
                    className="text-white fw-bold"
                  >
                    FAQ
                  </Nav.Link>
                </Nav.Item> */}
                <Nav.Item as="li">
                  <Nav.Link
                    as={NavLink}
                    to="/about"
                    className="text-white fw-bold"
                  >
                    ABOUT US
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Nav.Link
                    as={NavLink}
                    to="/terms"
                    className="text-white fw-bold"
                  >
                    TERMS AND CONDITIONS
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Nav.Link
                    as={NavLink}
                    to="/referral"
                    className="text-white fw-bold"
                  >
                    REFER A FRIEND
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Nav.Link href="https://www.youtube.com/channel/UC8L2VO2huuwOJKqlMEOauBA" className="text-white fw-bold">
                    VIDEO GALLERY
                  </Nav.Link>
                </Nav.Item>
                {/* <Nav.Item as="li">
                  <Nav.Link
                    as={NavLink}
                    to="/contact"
                    className="text-white fw-bold"
                  >
                    CAREERS
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Nav.Link href="/#" className="text-white fw-bold">
                    BLOG
                  </Nav.Link>
                </Nav.Item> */}
              </Nav>
            </Col>
            <Col xs={6}>
              <Nav as="ul" className="flex-column footer-nav">
                <Nav.Item as="li">
                  <Nav.Link
                    as={NavLink}
                    to="/privacy"
                    className="text-white fw-bold"
                  >
                    PRIVACY POLICY
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Nav.Link href="/refunds" className="text-white fw-bold">
                    REFUNDS
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Nav.Link
                    as={NavLink}
                    to="/disclaimer"
                    className="text-white fw-bold"
                  >
                    DISCLAIMER
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col sm={12} className="mt-5">
              <p className="m-0">
                Keep yourself updated about the latest parties!
              </p>
              <InputGroup className="mb-3 text-muted">
                <FormControl
                  placeholder="Email address"
                  aria-label="Email address"
                  size="lg"
                />
                <InputGroup.Append>
                  <Button variant="light" size="lg" className="text-muted">
                    Subscribe
                  </Button>
                </InputGroup.Append>
              </InputGroup>
              <div className="social d-flex justify-content-start align-items-center align-content-center">
                <p>Follow us on:</p>
                <p>
                  <FontAwesomeIcon
                    icon={faFacebook}
                    size="2x"
                    className="text-white mx-4"
                  ></FontAwesomeIcon>
                </p>
                <p>
                  <FontAwesomeIcon
                    icon={faTwitter}
                    size="2x"
                    className="text-white mx-4"
                  ></FontAwesomeIcon>
                </p>
                <p>
                  <FontAwesomeIcon
                    icon={faInstagram}
                    size="2x"
                    className="text-white mx-4"
                  ></FontAwesomeIcon>
                </p>
              </div>
            </Col>
          </Mobile>
        </Row>
        <hr className="border-bottom" />
        <p className="m-0 pb-3 text-center">
          &copy; 2020 Schindae Technologies Pvt. Ltd. All Rights Reserved.
        </p>
      </Container>
    </footer>
  );
}
