import axios from 'axios';

//Razorpay request in components/modules/payments

export const auth = {
	headers: {
		Authorization: "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6OTc1NywiaWF0IjoxNjM4MTgwMjExfQ.k7jPse58Jf0JksYMjnPbk5f1jl-Fw8gKYe3qUIGrf6U",
	},
};

export const defaulttoken = "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6OTc1NywiaWF0IjoxNjM4MTgwMjExfQ.k7jPse58Jf0JksYMjnPbk5f1jl-Fw8gKYe3qUIGrf6U";

export const URL = 'https://api-dev.playace.co/';

//export const URL = 'http://localhost:5000/';

export const FIREBASE_KEY = process.env.REACT_APP_FIREBASE_KEY;

// //REGISTER - currently not being used
export const registerNewUser = async user => {
	// );
	const { data } = await axios.post(`${URL}v1/register`, {
		first_name: user.firstName,
		last_name: user.lastName,
		mobile: user.mobile,
		email: user.email,
		link: user.link,
		gender: user.gender,
		location: user.location,
		description: user.description,
		image: user.image,
		device: user.device,
	});
	return data;
};

//GET ALL PARTIES

export const fetchPartyListing = async (key, limit, filter, usertoken) => {
	const { data } = await axios.get(
		`${URL}v1/event/list/1?limit/1&filter=${filter}`,
		{
			headers: {
				Authorization: usertoken ? `Bearer ${usertoken}` : `${defaulttoken}`,
			},
		}
	);
	console.log(data);
	return data;
};

export const fetchPartyById = async (key, id, usertoken) => {
	// console.log(defaulttoken);
	const { data } = await axios.get(`${URL}v1/event/detail/${id}`, {
		headers: {
			Authorization: usertoken ? `Bearer ${usertoken}` : `${defaulttoken}`,
		},
	});
	return data.data;
};

//GET ALL SPACES
//TODO: ERROR HANDLING ON THE SCREEN
export function getAllProperties(setProperties) {
	axios
		.get(`${URL}v1/property/list`, auth)
		.then(response => {
			// console.log(response.data);
			setProperties(response.data.data);
		})
		.catch(errors => {
			console.log(errors);
		});
}

export const fetchProperties = async (key, usertoken) => {
	// token);
	const { data } = await axios.get(`${URL}v1/property/list`, {
		headers: {
			Authorization: usertoken ? `Bearer ${usertoken}` : `${defaulttoken}`,
		},
	});
	return data.data;
};

//GET SPACE BY ID
export const fetchSpaceById = async (key, id) => {
	const { data } = await axios.get(`${URL}v1.2/property/${id}`, auth);
	return data.data;
};

//REQUEST TO ATTEND PARTY
export const requestToAttend = async event => {
	// console.log(event);
	const { data } = await axios.post(
		`${URL}v1/event/attend/request`,
		{
			event_id: event.event_id,
			payment_gateway_id: '',
			payment_id: '',
			playace_code: null,
			user_guests: event.user_guests,
		},
		{
			headers: {
				Authorization: `Bearer ${event.user_token}`,
			},
		}
	);
	console.log(data);
	return data;
};

//UPCOMING PARTIES - host
export const fetchUpcomingPartiesAsHost = async (key, usertoken) => {
	// token);
	const { data } = await axios.get(`${URL}v1/parties/hosting`, {
		headers: {
			Authorization: `Bearer ${usertoken}`,
		},
	});
	return data;
};

//UPCOMING PARTIES - guest
export const fetchUpcomingPartiesAsGuest = async (key, usertoken) => {
	//console.log(key, usertoken);
	const { data } = await axios.get(`${URL}v1.2/parties/booked`, {
		headers: {
			Authorization: `Bearer ${usertoken}`,
		},
	});
	return data;
};

//FETCH USER
export const fetchUser = async (key, usertoken) => {
	console.log(usertoken);
	const { data } = await axios.get(`${URL}v1/user/profile`, {
		headers: {
			Authorization: `Bearer ${usertoken}`,
		},
	});
	return data;
};

//UPDATE USER DETAILS
export const updateUser = async (body, usertoken) => {
	//console.log(body);
	axios
		.patch(`${URL}v1/user/profile`, body, {
			headers: {
				Authorization: `Bearer ${usertoken}`,
				'Content-Type': 'application/json',
			},
		})
		.then(response => {
			console.log(response.data);
			window.location.reload();
		})
		.catch(errors => {
			console.log(errors);
		});
};

//GET GUESTLIST FOR HOST PARTIES
export const fetchGuestlist = async (key, event_id, status, usertoken) => {
	const { data } = await axios.get(
		`${URL}v1.2/parties/guestlist?event_id=${event_id}&status=${status}`,
		{
			headers: {
				Authorization: `Bearer ${usertoken}`,
			},
		}
	);
	return data;
};

//FETCH BOOKED PROPERTIES
export const fetchPropertiesBooked = async (key, usertoken) => {
	const { data } = await axios.get(`${URL}v1/property/booked`, {
		headers: {
			Authorization: `Bearer ${usertoken}`,
		},
	});
	return data;
};

//FETCH PROPERTY CATEGORIES
export const fetchPropertyCategories = async (key, usertoken) => {
	// token);
	const { data } = await axios.get(`${URL}v1/property/category-list`, {
		headers: {
			Authorization: (usertoken && `Bearer ${usertoken}`) || defaulttoken,
		},
	});
	return data;
};

//SEND ENQUIRY
export const sendEnquiry = async (
	space,
	usertoken,
	enquiryData,
	setShowEnquiry,
	setShowModal
) => {
	axios
		.post(
			`${URL}v1/property/send-enquiry`,
			{
				property_id: space.id,
				property_name: space.name,
				property_rate: space.rate,
				...enquiryData,
			},
			{
				headers: {
					Authorization: `Bearer ${usertoken}`,
				},
			}
		)
		.then(response => {
			console.log(response.data);
			if (response.status === 'success') {
				setShowModal(true);
				// setShowEnquiry(false);
				// window.location.href = `${process.env.REACT_APP_BASE_URL}`;
				// window.location.href = "http://localhost:3001/"
			} else {
				// setShowEnquiry(false);
				// window.location.href = `${process.env.REACT_APP_BASE_URL}`;
				// window.location.href = "http://localhost:3001/"
			}
		})
		.catch(errors => {
			console.log(errors);
		});
};

//FAVORITE AND UNFAVORITE ON PARTY CAARDS //////////////////////////////
export const postFavorite = async (event_id, usertoken) => {
	axios
		.post(
			`${URL}v1/event/favorite`,
			{
				event_id: event_id,
				status: 1,
			},
			{
				headers: {
					Authorization: `Bearer ${usertoken}`,
				},
			}
		)
		.then(response => {
			console.log(response.data);
		});
};

export const postUnfavorite = async (event_id, usertoken) => {
	axios
		.post(
			`${URL}v1/event/favorite`,
			{
				event_id: event_id,
				status: 2,
			},
			{
				headers: {
					Authorization: `Bearer ${usertoken}`,
				},
			}
		)
		.then(response => {
			console.log(response.data);
		});
};
///////////////////////

//For image uploads
//////////////////////////////////////
export const uploadCoverImage = async usertoken => {
	axios
		.post(
			`${URL}v1/admins/events/image`,
			{},
			{
				headers: {
					Authorization: `Bearer ${usertoken}`,
				},
			}
		)
		.then(response => {
			console.log(response);
		})
		.catch(errors => {
			console.log(errors);
		});
};

//SEND HOST A PARTY REQUEST
//////////////////////////////////////////////////////
export const sendHostPartyRequest = async (usertoken, partyData) => {
	axios
		.post(`${URL}v1/host-party`, partyData, {
			headers: {
				Authorization: `Bearer ${usertoken}`,
			},
		})
		.then(response => {
			console.log(response);
		})
		.catch(errors => {
			console.log(errors);
		});
};

//GET BANK DETAILS
//////////////////////////////////////////////////////////////
export const getBankDetails = async (key, usertoken) => {
	const { data } = await axios.get(`${URL}v1/user/bank-details`, {
		headers: {
			Authorization: `Bearer ${usertoken}`,
		},
	});
	//console.log(data);
	return data;
};

//ADD NEW BANK DETAILS
///////////////////////////////////////////////////////////////
export const addNewBank = async (usertoken, bankData) => {
	// console.log(bankData);
	await axios
		.post(`${URL}v1/user/bank-details`, bankData, {
			headers: {
				Authorization: `Bearer ${usertoken}`,
			},
		})
		.then(response => {
			console.log(response);
			window.location.reload();
		})
		.catch(errors => {
			console.log(errors);
		});
};

//GENERATE SDK TOKEN FOR ONFIDO
//////////////////////////
export const postUserData = async userData => {
	const { data } = await axios.post(
		`${URL}v1/onfido/generate-sdk-token`,
		userData
	);
	return data;
};

//////////GENERATE REFERRAL LINK
export const generateReferralLink = async () => {
	return await axios
		.post(
			`https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${FIREBASE_KEY}`,
			{
				longDynamicLink:
					'https://playace.page.link/?link=https://play.google.com/store/apps/details?id=co.playace.playace_android&apn=co.playace.playace_android&ofl=https://playace.co',
			}
		)
		.then(
			response =>
				// console.log(response.data);
				response.data.shortLink
		);
};

//////////////////FETCH BANNER ADS
export const fetchBannerAds = async () => {
	const { data } = await axios.get(`${URL}v1/banner-ads`, auth);
	return data.data;
};

//////////////////FETCH CITIES
export const fetchCities = async () => {
	// const { data } = await axios.get(`${URL}v1/event/cities`, auth);
	// return data.data;
	return new Promise((resolve, reject) => {
		axios.get(`${URL}v1/event/cities`, auth).then(res => {
			console.log(res);
			resolve(res);
		});
	});
};

//SEND HOST A PARTY REQUEST
//////////////////////////////////////////////////////
export const sendPartyRequest = async partyData => {
	axios
		.post(`${URL}v1/host-party-request`, partyData, auth)
		.then(response => {
			console.log(response);
		})
		.catch(errors => {
			console.log(errors);
		});
};

//APPLY COUPON CODE
//////////////////////////////////////////////////////
export const applyCouponCode = async (defaulttoken, partyData) => {
	// console.log(defaulttoken, partyData);
	return new Promise((resolve, reject) => {
		let options = {
			method: 'POST',
			data: partyData,
			headers: {
				Authorization: `Bearer ${defaulttoken}`,
			},
		};
		axios(`${URL}v1/event/coupon-apply`, options).then(res => {
			console.log(res);
			resolve(res);
		});
	});
};

//////////////////FETCH USER STATS
export const fetchUserStats = async (key, usertoken) => {
	const { data } = await axios.get(`${URL}v1/event/user-stats`, {
		headers: {
			Authorization: `Bearer ${usertoken}`,
		},
	});
	return data.data;
};

//////////////////FETCH USER STATS
export const fetchHostingRequests = async (key, usertoken) => {
	const { data } = await axios.get(`${URL}v1/event/hosting-requests?status=0`, {
		headers: {
			Authorization: `Bearer ${usertoken}`,
		},
	});
	return data.data;
};

//////////////////FETCH EVENTS
export const fetchEventInfo = async key => {
	// console.log(usertoken);
	const { data } = await axios.get(`${URL}/v1/event/list/1`, {
		headers: {
			Authorization: `Bearer ${defaulttoken}`,
		},
	});
	console.log(data);
	return data;
};
