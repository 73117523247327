import axios from 'axios';
import {
	AUTHENTICATE_USER_START,
	AUTHENTICATE_USER_SUCCESS,
	AUTHENTICATE_USER_FAILURE,
	BOOK_CLICKED_TRUE,
	BOOK_CLICKED_FALSE,
} from '../actions/types';

import { URL } from '../api/requests';

export const setBookClickedTrue = () => ({
	type: BOOK_CLICKED_TRUE,
});

export const setBookClickedFalse = () => ({
	type: BOOK_CLICKED_FALSE,
});

export const authenticateUser = () => ({
	type: AUTHENTICATE_USER_START,
});

export const logoutUser = data => ({
	type: 'LOGOUT_USER',
	payload: {},
});

export const authenticateUserSuccess = data => ({
	type: AUTHENTICATE_USER_SUCCESS,
	payload: data,
});

export const authenticateUserFailure = errors => ({
	type: AUTHENTICATE_USER_FAILURE,
	payload: errors,
});

export const authenticateUserRequest = (mobile, otp) => {
	console.log(mobile, otp, typeof otp);
	return dispatch => {
		axios
			.post(`${URL}v1/login`, {
				mobile: mobile,
				otp: otp,
			})
			.then(response => {
				console.log(response);
				if (response.data.data !== undefined) {
					// console.log(response.data.data);
					const userData = JSON.stringify(response.data.data);
					dispatch(authenticateUserSuccess(userData));
				} else {
					// console.log(response.data);
					dispatch(authenticateUserFailure(response.data));
				}
			})
			.catch(errors => {
				console.log(errors);
				dispatch(authenticateUserFailure(errors));
			});
	};
};

export const unloadCurrentUser = state => {
	return dispatch => {
		dispatch(logoutUser());
	};
};
