import Food from './Pink icons-14.svg';
import NonVeg from './Pink icons-09.svg';
import Smoking from './Pink icons-15.svg';
import Alcohol from './Pink icons-10.svg';
import Veg from './Pink icons-08.svg';
import Parking from './Pink icons-04.svg';
import Kids from './Playace icon Swatches-26.svg';
import Elevator from './Pink icons-17.svg';
import AC from './Pink icons-03.svg';
import Pets from './Pink icons-02.svg';
import GuestPets from './Pink icons-06.svg';
import Footwear from './Playace icon Color Swatches-22.svg';
import Valet from './Playace icon Color Swatches-23.svg';
import Music from './Pink icons-21.svg';

const PartiesRegImages = {
    Food, NonVeg, Smoking, Alcohol, Veg, Parking, Kids, Elevator, AC, Pets, GuestPets, Footwear, Valet, Music
};

export default PartiesRegImages;