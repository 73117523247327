import axios from 'axios';
import { URL } from '../../../api/requests';
import { init } from 'onfido-sdk-ui';

//START VERIFICATION FLOW FOR ONFIDO
export async function startOnFidoVerification(userObj) {
	if (document.getElementById('loader') !== null) {
		document.getElementById('loader').classList.add('show-loader');
		// document.getElementById('review').classList.add('hide-loader');
	}
	if (document.getElementById('loader-mobile') !== null) {
		document.getElementById('loader-mobile').classList.add('show-loader');
		// document.getElementById('review-mobile').classList.add('hide-loader');
	}
	if (document.getElementById('add-governmentId') !== null) {
		document.getElementById('add-governmentId').classList.remove('show-loader');
		// document.getElementById('review').classList.add('hide-loader');
	}
	if (document.getElementById('add-governmentId-mobile') !== null) {
		document
			.getElementById('add-governmentId-mobile')
			.classList.remove('show-loader');
		// document.getElementById('review-mobile').classList.add('hide-loader');
	}
	await axios
		.post(`${URL}v1/onfido/generate-sdk-token`, {
			first_name: userObj.first_name,
			last_name: userObj.last_name,
		})
		.then(response => {
			if (document.getElementById('loader') !== null) {
				document.getElementById('loader').classList.remove('show-loader');
				document.getElementById('loader').classList.add('hide-loader');
				document
					.getElementById('add-governmentId')
					.classList.add('show-loader');
			}
			if (document.getElementById('loader-mobile') !== null) {
				document
					.getElementById('loader-mobile')
					.classList.remove('show-loader');
				document.getElementById('loader-mobile').classList.add('hide-loader');
				document
					.getElementById('add-governmentId-mobile')
					.classList.add('show-loader');
			}

			console.log(response.data.data);
			//pass sdk token from response and open modal
			let onfido = init({
				token: response.data.data.token,
				containerId: 'onfido-mount',
				useModal: true,
				isModalOpen: true,
				shouldCloseOnOverlayClick: false,
				onModalRequestClose: function () {
					// Update options with the state of the modal
					onfido.setOptions({ isModalOpen: false });
					console.log('hi');

					// document.getElementById('loader').classList.toggle('show-loader');
				},
				onComplete: () => {
					console.log('A completion message');
					onfido.setOptions({ isModalOpen: false });
					//ask the server to create and submit check after submission on client is completed
					axios
						.post(
							`${URL}v1/onfido/submit-check`,
							{
								applicant_Id: response.data.data.applicant_id
							},
							{
								headers: {
									Authorization: `Bearer ${userObj.token}`,
								}
							}
						)
						.then(response => {
							console.log(response.data);
						})
						.catch(errors => {
							console.log(errors);
						});
					axios
						.get('https://api-dev.playace.co/v1/user/govt-verification', {
							headers: {
								Authorization: `Bearer ${userObj.token}`,
							},
						})
						.then(res => {
							console.log(res);
							if (res.data.data.onfido_check_id !== '') {
								if (document.getElementById('add-governmentId') !== null) {
									document
										.getElementById('add-governmentId')
										.classList.remove('show-loader');
									document
										.getElementById('add-governmentId')
										.classList.add('hide-loader');
								}
								if (
									document.getElementById('add-governmentId-mobile') !== null
								) {
									document
										.getElementById('add-governmentId-mobile')
										.classList.remove('show-loader');
									document
										.getElementById('add-governmentId-mobile')
										.classList.add('hide-loader');
								}
							}
						});
				},
				steps: [
					{
						type: 'welcome',
						options: {
							title: 'Verify your details',
							descriptions: [
								'To start hosting and attending parties with Playace, you need to verify your identity. It only takes a few minutes.',
							],
						},
					},
					{
						type: 'document',
						options: {
							documentTypes: {
								passport: false,
								driving_licence: false,
								national_identity_card: true,
							},
						},
					},
					'face',
					'complete',
				],
			});
			let resp = response;
			return resp;
		});
}
