import React, { useState } from "react";
import parse from "html-react-parser";

export default function About({ title, content, ...props }) {
  let [show, setShow] = useState(false);

  function truncate(content, chars) {
    if (content !== undefined) {
      if (content.length <= chars) {
        return parse(content);
      }
      return parse(content.slice(0, chars) + "...");
    }
  }

  return (
    <div className="mt-5">
      <h3>{title}</h3>
      <p
        style={{ display: "block" }}>
        {parse(`${content}`)}
      </p>
    </div>
  );
}
