import React, { useEffect, useState, useCallback } from "react";
import { Container, Row, Col, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import About from "../../modules/content/EventDetails/About";

import { useForm } from "react-hook-form";
import { Default, Mobile } from "../../helpers/Breakpoints";
import { Loader } from "../../helpers";
import {
  updateHostPartyObject,
  sendHostPartyRequest,
} from "../../../actions/hostPartyActions";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import { useQuery } from "react-query";
import { getBankDetails, addNewBank } from "../../../api/requests";
import { v4 } from "uuid";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import styles from "./BankingDetails.module.scss";

export default function BankingDetails() {
  let [show, setShow] = useState(false);
  let [banks, setBanks] = useState([]);
  const dispatch = useDispatch();
  const userObj = JSON.parse(useSelector((state) => state.auth.data));
  const state = useSelector((state) => state.hostParty);
  const auth = JSON.parse(useSelector((state) => state.auth.data));
  // const isAuthenticated = useSelector((state) => state.isAuthenticated);
  const { data, isLoading } = useQuery(
    ["getBankDetails", userObj.token],
    getBankDetails,
    {
      retry: false,
      refetchOnWindowFocus: false
    }
  );

  const objWithActive = useCallback(() => {
    return (
      !isLoading &&
      data.data.map((item) => {
        return { ...item, active: false };
      })
    );
  }, [data, isLoading]);

  useEffect(() => {
    setBanks(objWithActive);
    //console.log("is running");
  }, [objWithActive]);

  const toggleSelected = (receivedId) => {
    // console.log("toggling");
    setBanks(
      banks.map((item) => {
        if (receivedId === item.bank_info_id) {
          //update hostparty store here
          // console.log(item, banks);
          //setResult(item);
          dispatch(updateHostPartyObject(item));
          return { ...item, active: !item.active };
        } else {
          return { ...item, active: false };
        }
      })
    );
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const submitRequest = () => {
    //dispatch(updateHostPartyObject(state.hostParty));
    dispatch(sendHostPartyRequest(state.data, auth.token));
  };

  //console.log(banks, objWithActive);

  if (state.loading) {
    return (
      <div
        style={{
          height: "100vh",
          width: "100vw",
          background: "rgba(0, 0, 0, 0.5)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Loader height="80px" width="80px" />
      </div>
    );
  }

  return (
    <Container style={{ margin: "6rem auto 0 auto" }}>
      <h2 className="text-center my-5">Review your party</h2>
      <Container>
        <Col md={2}></Col>
        <Col md={8} className="mx-auto">
          <div className="card p-4">
            <Default>
              <div className="d-flex justify-content-between align-items-center">
                <h2 className="m-0">{state.data.name}</h2>
                <div>
                  {state.data.type === 1 ? (
                    <h4 className="m-0">&#x20B9; {state.data.price}</h4>
                  ) : (
                    <h4 className="badge badge-primary px-3 py-2 m-0">FREE</h4>
                  )}
                  <div className="mt-3 text-center">
                    <FontAwesomeIcon icon={faUser} size="1x" className="mr-2" />
                    <span>{state.data.accommodate_people}</span>
                  </div>
                </div>
              </div>
              <About
                title="About"
                content={state.data.description}
                type="party"
              />
              <div className="text-muted">
                <small>{state.data.party_date}</small>
                <br />
                <small>
                  {state.data.start_time} - {state.data.end_time}
                </small>
              </div>
            </Default>
            <Mobile>
              <div className="d-flex justify-content-between align-items-center">
                <h4 className="m-0">{state.data.name}</h4>
                <div>
                  {state.data.type === 1 ? (
                    <h5 className="m-0 fw-bold">&#x20B9; {state.data.price}</h5>
                  ) : (
                    <h5 className="badge badge-primary px-3 py-2 m-0 fw-bold">
                      FREE
                    </h5>
                  )}
                  <div className="mt-3 text-center">
                    <FontAwesomeIcon icon={faUser} size="sm" className="mr-2" />
                    <span>{state.data.accommodate_people}</span>
                  </div>
                </div>
              </div>
              {/* <About
              title="About"
              content={state.data.description}
              type="party"
            /> */}
              <div className="my-5">
                <h4>About</h4>
                <p>{state.data.description}</p>
                <div className="text-muted">
                  <small>{state.data.party_date}, </small>
                  <small>
                    {state.data.start_time} - {state.data.end_time}
                  </small>
                </div>
              </div>
            </Mobile>
            {/* <div className="d-flex justify-content-between align-items-center">
              <h2 className="m-0">{state.data.name}</h2>
              <div>
                {state.data.type === 1 ? (
                  <h4 className="m-0">&#x20B9; {state.data.price}</h4>
                ) : (
                  <h4 className="badge badge-primary px-3 py-2 m-0">FREE</h4>
                )}
                <div className="mt-3 text-center">
                  <FontAwesomeIcon icon={faUser} size="1x" className="mr-2" />
                  <span>{state.data.accommodate_people}</span>
                </div>
              </div>
            </div>
            <About
              title="About"
              content={state.data.description}
              type="party"
            />
            <div className="text-muted">
              <small>{state.data.party_date}</small>
              <br />
              <small>
                {state.data.start_time} - {state.data.end_time}
              </small>
            </div> */}
            <div className="mb-4">
              <Row>
                <Col md="4">
                  <p className="d-flex justify-content-between border-bottom fw-bold">
                    <span>Alcohol </span>{" "}
                    {(() => {
                      const alcoholArray =
                        state.data.alcohol && JSON.parse(state.data.alcohol);

                      if (alcoholArray === undefined) {
                        return <span></span>;
                      }
                      if (
                        alcoholArray.includes("1") &&
                        alcoholArray.includes("0")
                      ) {
                        return <span className="text-primary">Yes</span>;
                      }
                      if (
                        alcoholArray.includes("1") &&
                        alcoholArray.includes("3")
                      ) {
                        return <span className="text-primary">Yes, BYOB</span>;
                      }
                      if (
                        alcoholArray.includes("2") &&
                        alcoholArray.includes("0")
                      ) {
                        return <span className="text-primary">No</span>;
                      }
                      if (
                        alcoholArray.includes("2") &&
                        alcoholArray.includes("3")
                      ) {
                        return <span className="text-primary">No, BYOB</span>;
                      }
                    })()}
                  </p>
                </Col>
                <Col md="4">
                  <p className="d-flex justify-content-between fw-bold border-bottom">
                    <span>Smoking </span>{" "}
                    {(() => {
                      switch (state.data.smoking) {
                        case 1:
                          return <span className="text-primary">Yes</span>;
                        case 2:
                          return <span className="text-primary">No</span>;
                        case 3:
                          return <span className="text-primary">Outdoor</span>;
                        default:
                          break;
                      }
                    })()}
                  </p>
                </Col>
                <Col md="4">
                  <p className="d-flex justify-content-between fw-bold border-bottom">
                    <span>Parking </span>{" "}
                    {(() => {
                      switch (state.data.parking) {
                        case 1:
                          return <span className="text-primary">Yes</span>;
                        case 2:
                          return <span className="text-primary">No</span>;
                        case 3:
                          return <span className="text-primary">Valet</span>;
                        case 4:
                          return (
                            <span className="text-primary">On the street</span>
                          );
                        default:
                          break;
                      }
                    })()}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <p className="d-flex justify-content-between fw-bold border-bottom">
                    <span>Pets</span>{" "}
                    {(() => {
                      switch (state.data.pet) {
                        case 1:
                          return <span className="text-primary">Yes</span>;
                        case 2:
                          return <span className="text-primary">No</span>;
                        default:
                          break;
                      }
                    })()}
                  </p>
                </Col>
                <Col md="4">
                  <p className="d-flex justify-content-between fw-bold border-bottom">
                    <span>Guest pets </span>{" "}
                    {(() => {
                      switch (state.data.guest_pet) {
                        case 1:
                          return <span className="text-primary">Yes</span>;
                        case 2:
                          return <span className="text-primary">No</span>;
                        default:
                          break;
                      }
                    })()}
                  </p>
                </Col>
                <Col md="4">
                  <p className="d-flex justify-content-between fw-bold border-bottom">
                    <span>Food Type </span>{" "}
                    {(() => {
                      switch (state.data.food_type) {
                        case 1:
                          return <span className="text-primary">Nibbles</span>;
                        case 2:
                          return <span className="text-primary">Starters</span>;
                        case 3:
                          return <span className="text-primary">Main</span>;
                        case 4:
                          return <span className="text-primary">Desserts</span>;
                        case 5:
                          return (
                            <span className="text-primary">Bring your own</span>
                          );
                        default:
                          return <span className="text-primary">None</span>;
                      }
                    })()}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <p className="d-flex justify-content-between fw-bold border-bottom">
                    <span>Food </span>{" "}
                    {(() => {
                      switch (state.data.food) {
                        case 1:
                          return <span className="text-primary">Veg</span>;
                        case 2:
                          return <span className="text-primary">Non Veg</span>;
                        case 3:
                          return <span className="text-primary">Both</span>;
                        default:
                          break;
                      }
                    })()}
                  </p>
                </Col>
                <Col md="4">
                  <p className="d-flex justify-content-between fw-bold border-bottom">
                    <span>Child friendly </span>{" "}
                    {(() => {
                      switch (state.data.child) {
                        case 1:
                          return <span className="text-primary">Yes</span>;
                        case 2:
                          return <span className="text-primary">No</span>;
                        default:
                          break;
                      }
                    })()}
                  </p>
                </Col>
                <Col md="4">
                  <p className="d-flex justify-content-between fw-bold border-bottom">
                    <span>Elevator </span>{" "}
                    {(() => {
                      switch (state.data.elevator) {
                        case 1:
                          return <span className="text-primary">Yes</span>;
                        case 2:
                          return <span className="text-primary">No</span>;
                        case 3:
                          return (
                            <span className="text-primary">Not required</span>
                          );
                        default:
                          break;
                      }
                    })()}
                  </p>
                </Col>
              </Row>
            </div>
            <Link
              className="btn btn-block btn-primary"
              to="/host-party/planning-overview"
            >
              Edit
            </Link>
          </div>
          <h2 className="my-4">Bank details</h2>
          <Default>
            <div className="d-flex flex-wrap">
              <div className="d-flex flex-wrap">
                {banks &&
                  banks.map((bank) => {
                    return (
                      <div
                        key={v4()}
                        onClick={() => {
                          // console.log("clicked " + bank.bank_info_id);
                          toggleSelected(bank.bank_info_id);
                        }}
                      >
                        <div
                          className={
                            bank.active
                              ? `card py-3 pl-2 pr-5 d-inline-block mr-2 mt-3 make-pointer ${styles.bank} ${styles.picked}`
                              : `card py-3 pl-2 pr-5 d-inline-block mr-2 mt-3 make-pointer ${styles.bank}`
                          }
                        >
                          <p className="m-0">
                            <strong>Account Holder:</strong>{" "}
                            {bank.account_holder_name}
                          </p>
                          <p className="m-0">
                            <strong>Account No.</strong> {bank.account_number}
                          </p>
                          <p className="m-0">
                            <strong>IFSC Code:</strong> {bank.bank_ifsc}
                          </p>
                          <p className="m-0">
                            <strong>Bank Name:</strong> {bank.bank_name}
                          </p>
                          <p className="m-0">
                            <strong>Branch:</strong> {bank.branch_name}
                          </p>
                          <p className="m-0">
                            <strong>Pan Card:</strong> {bank.pan_card}
                          </p>
                        </div>
                      </div>
                    );
                  })}
              </div>
              <div
                className={`card text-center d-flex justify-content-center align-items-center px-5 py-3 make-pointer flex-grow-1 mt-3 ${styles.addButton}`}
                onClick={handleShow}
              >
                <FontAwesomeIcon icon={faPlus} size="2x"></FontAwesomeIcon>
              </div>
            </div>
          </Default>
          <Mobile>
            <div>
              {banks &&
                banks.map((bank) => {
                  return (
                    <div
                      key={v4()}
                      onClick={() => {
                        // console.log("clicked " + bank.bank_info_id);
                        toggleSelected(bank.bank_info_id);
                      }}
                    >
                      <div
                        className={
                          bank.active
                            ? `card py-3 pl-2 pr-5 d-block mx-auto make-pointer ${styles.picked}`
                            : `card py-3 pl-2 pr-5 d-block mx-auto make-pointer`
                        }
                      >
                        <p className="m-0">
                          <strong>Account Holder:</strong>{" "}
                          {bank.account_holder_name}
                        </p>
                        <p className="m-0">
                          <strong>Account No.</strong> {bank.account_number}
                        </p>
                        <p className="m-0">
                          <strong>IFSC Code:</strong> {bank.bank_ifsc}
                        </p>
                        <p className="m-0">
                          <strong>Bank Name:</strong> {bank.bank_name}
                        </p>
                        <p className="m-0">
                          <strong>Branch:</strong> {bank.branch_name}
                        </p>
                        <p className="m-0">
                          <strong>Pan Card:</strong> {bank.pan_card}
                        </p>
                      </div>
                    </div>
                  );
                })}
              {/* <BankCard /> */}
              <div
                className="card text-center d-flex justify-content-center align-items-center px-5 py-3 make-pointer"
                onClick={handleShow}
              >
                <FontAwesomeIcon icon={faPlus} size="2x"></FontAwesomeIcon>
              </div>
            </div>
          </Mobile>
        </Col>
        <Col md={2}></Col>
        <div className="d-flex justify-content-center">
          <button
            className="btn btn-primary text-uppercase fw-bold px-4 mt-5 mx-auto"
            onClick={() => {
              submitRequest();
              // console.log("submitted");
            }}
          >
            Submit
          </button>
        </div>
      </Container>
      <Modal
        centered
        backdrop="static"
        size="lg"
        show={show}
        onHide={handleClose}
      >
        <AddNewBank />
      </Modal>
    </Container>
  );
}

function AddNewBank() {
  // const dispatch = useDispatch();
  const auth = JSON.parse(useSelector((state) => state.auth.data));
  const state = useSelector((state) => state.hostParty);
  const { handleSubmit, errors, register } = useForm({
    defaultValues: {
      account_holder_name: state.data.account_holder_name,
      account_number: state.data.account_number,
      bank_name: state.data.bank_name,
      branch_name: state.data.branch_name,
      bank_ifsc: state.data.bank_ifsc,
      pancard: state.data.pancard,
    },
  });

  const onSubmit = (values) => {
    // console.log(values);
    addNewBank(auth.token, values);
    // dispatch(updateHostPartyObject(values));
    // dispatch(sendHostPartyRequest(state.data, auth.token));
  };
  return (
    <>
      <Modal.Header closeButton>
        <h2>Add New Bank</h2>
      </Modal.Header>
      <Modal.Body className="px-3">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col md={6}>
              <label>
                <h5>Account Holder Name</h5>
              </label>
              <input
                name="account_holder_name"
                placeholder="Account holder name"
                type="text"
                ref={register({
                  required: "Required",
                  minLength: 3,
                })}
              />
              <p className="text-danger">
                {errors.account_holder_name &&
                  errors.account_holder_name.message}
              </p>
              <label>
                <h5>Account Number</h5>
              </label>
              <input
                name="account_number"
                type="text"
                placeholder="Account number"
                ref={register({
                  required: "Required",
                  pattern: {
                    value: /^\d{9,18}$/,
                    message: "Minimum 9-18 digits",
                  },
                })}
              />
              <p className="text-danger">
                {errors.account_number && errors.account_number.message}
              </p>
              <label>
                <h5>Bank name</h5>
              </label>
              <input
                name="bank_name"
                type="text"
                placeholder="Bank Name"
                ref={register({
                  required: "Required",
                  minLength: 3,
                  maxLength: 32,
                })}
              />
              <p className="text-danger">
                {errors.bank_name && errors.bank_name.message}
              </p>
            </Col>
            <Col md={6}>
              <label>
                <h5>Bank branch</h5>
              </label>
              <input
                name="branch_name"
                type="text"
                placeholder="Bank branch"
                ref={register({
                  required: "Required",
                  minLength: 3,
                  maxLength: 32,
                })}
              />
              <p className="text-danger">
                {errors.branch_name && errors.branch_name.message}
              </p>
              <label>
                <h5>IFSC code</h5>
              </label>
              <input
                name="bank_ifsc"
                type="text"
                placeholder="IFSC code"
                ref={register({
                  required: "Required",
                  pattern: {
                    value: /^[A-Z]{4}0[A-Z0-9]{6}$/,
                    message: "Invalid IFSC code",
                  },
                })}
              />
              <p className="text-danger">
                {errors.bank_ifsc && errors.bank_ifsc.message}
              </p>
              <label>
                <h5>PAN Card</h5>
              </label>
              <input
                name="pancard"
                type="text"
                placeholder="PAN card number"
                ref={register({
                  required: "Required",
                  pattern: {
                    value: /[A-Z]{5}[0-9]{4}[A-Z]{1}/,
                    message: "Invalid PAN card number",
                  },
                })}
              />
              <p className="text-danger">
                {errors.pancard && errors.pancard.message}
              </p>
            </Col>
          </Row>
          <button
            type="submit"
            className="btn btn-primary text-uppercase fw-bold px-4 mt-3 d-flex justify-content-center mx-auto"
          >
            Submit
          </button>
        </form>
      </Modal.Body>
    </>
  );
}
