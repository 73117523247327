import React, { useEffect, useState } from 'react';
import { Col, Row, Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
// import { /*Link, useHistory,*/ useLocation } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { Default, Mobile } from '../../../helpers/Breakpoints';
import SquareLogo from '../../../../assets/square_logo.png';
import Auth from '../../../modules/auth/Auth';

// import { sendRazorpayRequest } from "../../../modules/payments/Razorpay";

import SendEnquiry from './SendEnquiry';

export default function BookTicket({ space, ...props }) {
	let [showLoginModal, setShowLoginModal] = useState(false);
	let [bookClicked, setbookClicked] = useState(false);
	let [showEnquiry, setShowEnquiry] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const history = useHistory();
	const [timings, setTimings] = useState('');
	const handleClose = () => {
		setShowLoginModal(false);
	};

	const handleEnquiryClose = () => {
		setShowEnquiry(false);
	};
	// const history = useHistory();
	// const location = useLocation();

	useEffect(() => {
		let timings = space.timings.split(' - ');
		let time;
		let spaceTimings = timings.map(i => {
			time = i.toString().match(/^([01]\d|2[0-4])(:)([0-5]\d)(:[0-5]\d)?$/) || [
				time,
			];

			if (time.length > 1) {
				// If time format correct
				time = time.slice(1); // Remove full string match value
				time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
				time[0] = +time[0] % 12 || 12; // Adjust hours
			}
			return time.join('');
		});
		setTimings(spaceTimings.join(' - '));
	}, []);
	useEffect(() => {
		if (props.isAuthenticated) {
			setShowLoginModal(false);
		}

		if (props.isAuthenticated && bookClicked) {
			setShowLoginModal(false);
			setbookClicked(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.isAuthenticated, bookClicked]);

	function handleClick(e) {
		e.preventDefault();
		setbookClicked(true);
		if (props.isAuthenticated) {
			setShowEnquiry(true);
		} else {
			setShowLoginModal(true);
			setbookClicked(true);
			setShowEnquiry(false);
		}
	}

	const handleShow = () => setShowModal(true);
	const handleCloseModal = () => {
		setShowModal(false);
		history.push('/');
	};
	// function handlePayment(e) {
	//   e.preventDefault();
	//   sendRazorpayRequest(props.userObj, props.finalAmount);
	// }
	//PAYMENT IS REMOVED FOR SPACES, SEND ENQUIRY IS BEING USED INSTEAD
	//For mobile
	if (props.view === 'sticky') {
		return (
			<div>
				<Modal show={showLoginModal} onHide={handleClose}>
					<Auth />
				</Modal>

				<button
					onClick={handleClick}
					className="btn btn-reg btn-secondary fw-bold btn-padding"
				>
					{props.text}
				</button>
				<Modal show={showModal} backdrop="static" onHide={handleCloseModal}>
					<div className="d-flex flex-column justify-content-center text-center">
						<img
							src={SquareLogo}
							alt="Request-sent-image"
							className="w-50 m-auto"
						/>
						<h5>Space booked successfully</h5>
						<p>
							Your booking is being reviewed by the host.
							<br />
							You will receive a notification shortly!
						</p>
						<div className="d-flex justify-content-center">
							<button
								className="btn btn-secondary text-uppercase fw-bold px-4 mt-2 mb-3 mx-auto"
								type="submit"
								onClick={handleCloseModal}
							>
								Done
							</button>
						</div>
					</div>
				</Modal>
				<Modal show={showEnquiry} backdrop="static" onHide={handleEnquiryClose}>
					<SendEnquiry
						space={space}
						setShowEnquiry={setShowEnquiry}
						setShowModal={setShowModal}
					/>
				</Modal>
			</div>
		);
	}

	//For mobile
	if (props.view === 'mobile') {
		return (
			<div
				className="text-center p-3 rounded"
				style={{ border: '2px solid #eaeaea' }}
			>
				<h5 className="fw-bold">
					Starts from &#x20b9; {space.rate}/- per hour
				</h5>
				{/* <h6 className="text-secondary mb-4 fw-bold">Few spots left!</h6> */}
				<button
					onClick={handleClick}
					className="btn btn-reg btn-secondary fw-bold btn-padding"
				>
					Request to Book
				</button>
				<Modal show={showModal} backdrop="static" onHide={handleCloseModal}>
					<div className="d-flex flex-column justify-content-center text-center">
						<img
							src={SquareLogo}
							alt="Request-sent-image"
							className="w-50 m-auto"
						/>
						<h5>Space booked successfully</h5>
						<p>
							Your booking is being reviewed by the host.
							<br />
							You will receive a notification shortly!
						</p>
						<div className="d-flex justify-content-center">
							<button
								className="btn btn-secondary text-uppercase fw-bold px-4 mt-2 mb-3 mx-auto"
								type="submit"
								onClick={handleCloseModal}
							>
								Done
							</button>
						</div>
					</div>
				</Modal>
				<Modal show={showEnquiry} backdrop="static" onHide={handleEnquiryClose}>
					<SendEnquiry
						space={space}
						setShowEnquiry={setShowEnquiry}
						setShowModal={setShowModal}
					/>
				</Modal>
				{/* <Modal show={showLoginModal} onHide={handleClose}>
          <Auth />
        </Modal> */}
				<Default>
					<Modal
						show={showLoginModal}
						onHide={handleClose}
						dialogClassName="modal-dimensions"
						backdrop="static"
						centered
					>
						<Auth handleClose={handleClose} />
					</Modal>
				</Default>
				<Mobile>
					<Modal
						size="lg"
						show={showLoginModal}
						onHide={handleClose}
						backdrop="static"
						centered
					>
						<Auth handleClose={handleClose} />
					</Modal>
				</Mobile>
			</div>
		);
	}

	return (
		<div className="sticky-ticket" id="sticky-ticket">
			<div className="shadow rounded-lg">
				<Row className="px-4 pt-4 pb-3">
					<Col>
						<p className="mb-0">
							<span className="badge badge-secondary p-2 text-uppercase rounded-sm">
								{space.category_name}
							</span>
						</p>
						<h5 className="fw-bold my-2">{space.name}</h5>
						<small>
							{space.location.locality} | {space.location.city_name}
						</small>{' '}
						<br />
						{/* <small>Nov 16 | Wed</small> <br /> */}
						<div className="d-flex flex-column">
							<small className="mb-1">{timings}</small>
							<small>
								<FontAwesomeIcon
									icon={faUser}
									size="md"
									className="text-dark"
								></FontAwesomeIcon>{' '}
								{space.limit}
							</small>{' '}
						</div>
					</Col>
					<Col>
						<div className="float-right text-center">
							<p>Listed by</p>
							{space.owner ? (
								<>
									<img
										src={space.owner.image}
										alt="profile-user"
										className="rounded-circle"
										height="40"
										width="40"
									/>
									<br />
									<small>{space.owner.first_name}</small>
									<br />
								</>
							) : null}
							{/* <small>
								<FontAwesomeIcon
									icon={faStar}
									size="lg"
									className="text-secondary"
								></FontAwesomeIcon>{' '}
								4
							</small> */}
						</div>
					</Col>
				</Row>
				<hr className="my-3 w-100" />
				<div className="text-center px-4 pt-3 pb-4">
					<h5>
						Starts from: <br />
					</h5>
					<h4>Rs {space.rate}/- per hour</h4>
					<br/>
					<button
						onClick={handleClick}
						className="btn btn-reg btn-secondary fw-bold btn-padding">
						Request to Book
					</button>
				</div>
			</div>
			<Modal show={showModal} backdrop="static" onHide={handleCloseModal}>
				<div className="d-flex flex-column justify-content-center text-center">
					<img
						src={SquareLogo}
						alt="Request-sent-image"
						className="w-50 m-auto"
					/>
					<h5>Space booked successfully</h5>
					<p>
						Your booking is being reviewed by the host.
						<br />
						You will receive a notification shortly!
					</p>
					<div className="d-flex justify-content-center">
						<button
							className="btn btn-secondary text-uppercase fw-bold px-4 mt-2 mb-3 mx-auto"
							type="submit"
							onClick={handleCloseModal}
						>
							Done
						</button>
					</div>
				</div>
			</Modal>
			<Modal show={showEnquiry} backdrop="static" onHide={handleEnquiryClose}>
				<SendEnquiry
					space={space}
					setShowEnquiry={setShowEnquiry}
					setShowModal={setShowModal}
				/>
			</Modal>
			{/* <Modal show={showLoginModal} backdrop="static" onHide={handleClose}>
        <Auth />
      </Modal> */}
			<Default>
				<Modal
					show={showLoginModal}
					onHide={handleClose}
					dialogClassName="modal-dimensions"
					backdrop="static"
					centered
				>
					<Auth handleClose={handleClose} />
				</Modal>
			</Default>
			<Mobile>
				<Modal
					size="lg"
					show={showLoginModal}
					onHide={handleClose}
					backdrop="static"
					centered
				>
					<Auth handleClose={handleClose} />
				</Modal>
			</Mobile>
		</div>
	);
}
