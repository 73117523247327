import React, { useState, useEffect } from 'react';

import { Row, Col } from 'react-bootstrap';
import { useQuery } from 'react-query';

import { fetchPartyListing } from '../../../../api/requests';
import { Skeleton } from '../../../modules/common';
import { EventCard } from '../../../modules/content';
import { RenderUserObj } from '../../../helpers';
import { useSelector } from 'react-redux';

import { useParams /*useHistory, useLocation*/ } from 'react-router-dom';

export default function Favorites({ setPage, ...props }) {
	const { pagenumber } = useParams();
	// const history = useHistory();
	// const location = useLocation();

	let [token, setToken] = useState(null);

	const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
	const userObj = RenderUserObj(useSelector(state => state.auth.data));

	const rand = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

	useEffect(() => {
		setPage(pagenumber);
		if (isAuthenticated) {
			if (userObj !== undefined) setToken(userObj.token);
		}
	}, [pagenumber, setPage, isAuthenticated, userObj]);

	const { data, isLoading, error } = useQuery(
		['partyListing', '', 'favorites', token],
		fetchPartyListing,
		{
			refetchOnWindowFocus: false,
		}
	);

	console.log(data);
	if (data && data.data.length === 0) {
		return (
			<>
				{error && <h5>Failed to load</h5>}
				{isLoading && (
					<div className="grid-container">
						{rand.map((item, index) => {
							return <Skeleton key={index} />;
						})}
					</div>
				)}
				<div className="text-center mt-5" style={{ height: '50vh' }}>
					<h2>No favorite parties :(</h2>
				</div>
			</>
		);
	}

	return (
		<div>
			{error && <h5>Failed to load</h5>}
			{isLoading && (
				<div className="grid-container">
					{rand.map((item, index) => {
						return <Skeleton key={index} />;
					})}
				</div>
			)}
			<Row>
				{data &&
					data.data.map((item, index) => {
						return (
							<Col md={4} key={index}>
								<EventCard type="party" {...item} />
							</Col>
						);
					})}
			</Row>
		</div>
	);
}
