import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";

export default function AlcoholComponent({ setAmenities, amenities }) {
  let [byob, setByob] = useState("0");
  let [alcohol, setAlcohol] = useState("1");
  let array = [];

  useEffect(() => {
    array.push(alcohol, byob);
    setAmenities((prev) => ({ ...prev, alcohol: JSON.stringify(array) }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alcohol, byob]);

  const reduxAlcohol = amenities.alcohol && JSON.parse(amenities.alcohol);

  // console.log(reduxAlcohol, alcohol)
  return (
    <div>
      <label>
        <h4>Alcohol Availability</h4>
      </label>
      <div className="d-flex justify-content-between mb-3">
        <Button
          variant={
            reduxAlcohol[0] === "1" ? `primary shadow shadow-sm` : `shadow shadow-sm`
          }
          size="reg"
          block
          onClick={() => {
            setAlcohol("1");
          }}
        >
          Yes
        </Button>
        <Button
          variant={
            reduxAlcohol[0] === "2" ? `primary shadow shadow-sm` : `shadow shadow-sm`
          }
          size="reg"
          className="m-0"
          block
          onClick={() => {
            setAlcohol("2");
          }}
        >
          No
        </Button>
        <Button
          variant={
            reduxAlcohol[1] === "3" ? `primary shadow shadow-sm` : `shadow shadow-sm`
          }
          size="reg"
          className="m-0"
          block
          onClick={() => {
            setByob((prev) => (prev === "3" ? "0" : "3"));
          }}
        >
          BYOB
        </Button>
      </div>
    </div>
  );
}
