import React, { useState, useEffect, Suspense, lazy } from 'react';
import { useQuery } from 'react-query';
import { Container, Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import AHE from '../../assets/img/attend host earn.png';

import { Default, Mobile } from '../../helpers/Breakpoints';
import { ScrollToTop } from '../../helpers';

import { PhotoGrid } from '../../modules/common';
import About from '../../modules/content/EventDetails/About';
// import Amenities from './Amenities';
// import Reviews from '../../modules/content/EventDetails/Reviews';
import Expander from '../../modules/content/EventDetails/Expander';
import Spaces from '../Home/Spaces';
import BookTicket from '../../modules/common/spaces/BookTicket';

import { RenderUserObj, Loader, convertTime } from '../../helpers';
import { fetchSpaceById } from '../../../api/requests';
const Amenities = lazy(() => import('./Amenities'));

export default function SpacesDetail({ space, state, ...props }) {
	const [timings, setTimings] = useState('');
	const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
	const userObj = RenderUserObj(useSelector(state => state.auth.data));

	const { id } = useParams();

	const { isLoading, data } = useQuery(['spaceDetail', id], fetchSpaceById, {
		refetchOnWindowFocus: false,
	});

	useEffect(() => {
		if (data !== undefined) {
			let spaceTimings = convertTime(data);
			setTimings(spaceTimings.join(' - '));
		}
	}, [data]);

	if (isLoading) {
		return (
			<div style={{ margin: '7rem 0 0 0', textAlign: 'center' }}>
				<Loader />
			</div>
		);
	}

	return (
		<>
			<ScrollToTop />
			<Default>
				<div style={{ margin: '7rem 0 0 0' }}>
					<Container>
						<Row>
							<Col lg={8} md={7}>
								<PhotoGrid
									src={data && data.cover_image ? data.cover_image : null}
								/>
								<About
									title="About the space"
									content={data.description}
									type="data"
								/>
								<Suspense fallback={<p>Loading...</p>}>
									<Amenities amenities={data.amenities} />
								</Suspense>
								<Expander title="House Rules" data={data.house_rules} />
								<Expander
									title="Cancellation Policy"
									data="
                                1. Cancellation before 72 hours of the booking - 100% refund minus the convenience charges.<br>
                                2. Cancellation before 48 hours of the booking - 50% refund minus the convenience charges.<br>
                                3. Cancellation before 24 hours of the booking 25% refund minus the convenience charges.<br>
                                4. Cancellation within 24 hours of the booking - Non-refundable<br>
                                5. If the hosts cancels the booking - 100% refunds."
								/>
								{/* <Reviews /> */}
							</Col>
							<Col lg={4} md={5}>
								<BookTicket
									price="300"
									isAuthenticated={isAuthenticated}
									userObj={userObj}
									space={data}
								/>
							</Col>
						</Row>
						<Spaces title="You may also like" button={false} />
						<div className="my-5" />
						{/* <CTA /> */}
					</Container>
				</div>
			</Default>
			<Mobile>
				{/* //Main */}
				<div className="mx-4" style={{ marginTop: '5rem' }}>
					<PhotoGrid src={data && data.cover_image ? data.cover_image : null} />
					<div className="d-flex justify-content-between mx-2 my-4">
						<div className="d-flex flex-column justify-content-between">
							<div className="d-flex flex-column h-100 justify-content-between">
								<h3 className="fw-bold m-0">{data.name}</h3>
								<div className="d-flex flex-column justify-content-between mb-2">
									<small className="mb-1">
										{data.location.locality} | {data.location.city_name}
									</small>
									{/* <small>{RenderDate(data.timings)}</small> <br /> */}
									<small className="mb-1">{timings}</small>
									<small>
										<FontAwesomeIcon
											icon={faUser}
											size="md"
											className="text-dark"
										></FontAwesomeIcon>{' '}
										{data.limit} | {data.category_name}
									</small>
								</div>
							</div>
						</div>
						<div className="text-center">
							{data.owner ? (
								<>
									<p className="mb-1" style={{ lineHeight: '1.5' }}>
										Listed by {data.owner.first_name} {data.owner.last_name}
									</p>
									<img
										src={data.owner.image}
										alt="profile-user"
										className="rounded-circle"
										height="40"
										width="40"
									/>
									<br />
									<small>{data.owner.first_name}</small>
									<br />
								</>
							) : null}
							{/* <small>
								<FontAwesomeIcon
									icon={faStar}
									size="sm"
									className="text-secondary"
								></FontAwesomeIcon>
								<FontAwesomeIcon
									icon={faStar}
									size="sm"
									className="text-secondary"
								></FontAwesomeIcon>
								<FontAwesomeIcon
									icon={faStar}
									size="sm"
									className="text-secondary"
								></FontAwesomeIcon>
								<FontAwesomeIcon
									icon={faStar}
									size="sm"
									className="text-secondary"
								></FontAwesomeIcon>
							</small> */}
						</div>
					</div>
					<BookTicket
						view="mobile"
						isAuthenticated={isAuthenticated}
						userObj={userObj}
						space={data}
					/>
					<div className="mt-5">
						<About title="About the Space" content={data.description} />
					</div>
					<Suspense fallback={<p>Loading...</p>}>
						<Amenities amenities={data.amenities} />
					</Suspense>
					<Expander title="House Rules" data={data.house_rules} />
					<Expander
						title="Cancellation Policy"
						data="1. Cancellation before 72 hours of the booking - 100% refund minus the convenience charges.<br>
                    2. Cancellation before 48 hours of the booking - 50% refund minus the convenience charges.<br>
                    3. Cancellation before 24 hours of the booking 25% refund minus the convenience charges.<br>
                    4. Cancellation within 24 hours of the booking - Non-refundable<br>
                    5. If the hosts cancels the booking - 100% refunds."
					/>
					{/* <Reviews /> */}
					<Spaces title="You may also like" button={false} />
					<div
						className="p-3 my-4 rounded"
						style={{ border: '2px solid #eaeaea' }}
					>
						<div className="d-flex justify-content-between align-items-center">
							<div>
								<img src={AHE} className="w-75" alt="attend host earn" />
							</div>
							<div>
								<p className="text-muted mb-2" style={{ lineHeight: '1.5' }}>
									Have a great idea for a party?
								</p>
								<h5 className="fw-bold">HOST YOUR OWN PARTY</h5>
							</div>
						</div>
						<div className="text-center">
							<a href="/#" className="btn btn-primary text-center">
								Get started
							</a>
						</div>
					</div>
				</div>
				{/* //Footer ticket */}
				<div className="d-flex fixed-bottom ticket justify-content-between align-items-center px-2 py-1">
					<div>
						<span>{data.timings}</span>
						<br />
						<span className="fw-bolder">&#x20b9;{data.rate}/- per hour</span>
					</div>
					<BookTicket
						view="sticky"
						isAuthenticated={isAuthenticated}
						userObj={userObj}
						text="Request to book"
						space={data}
					/>
				</div>
			</Mobile>
		</>
	);
}
