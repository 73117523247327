import React from 'react';
import { useQuery } from 'react-query';

import styles from './Dashboard.module.scss';

import { Card } from '../Profile';

// import { RenderDate } from '../../helpers';

import { useSelector } from 'react-redux';
import { Mobile, Default } from '../../../helpers/Breakpoints';
// import axios from 'axios';
import { fetchUser } from '../../../../api/requests';
import Verification from './Verification';
import PartiesInfo from './PartiesInfo';
import About from './About';
import Interests from './Interests';
import UpcomingEvents from './UpcomingEvents';
import SpacesInfo from './SpacesInfo';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';

function Dashboard() {
	const userObj = JSON.parse(useSelector(state => state.auth.data));
	const isAuthenticated = useSelector(state => state.auth);
	const history = useHistory();

	const { data, isLoading } = useQuery(
		['fetchUser', userObj.token],
		fetchUser,
		{
			retry: 3,
			refetchOnWindowFocus: false,
		}
	);

	useEffect(() => {
		if (!isAuthenticated) {
			history.push('/');
		}
	}, [isAuthenticated, history]);

	// console.log(data);

	return (
		<>
			<Default>
				<div className={styles.masonryGrid}>
					{/* //Verification */}
					<Card className={`${styles.verification} py-3 px-5`}>
						<Verification userObj={userObj} isLoading={isLoading} data={data} />
					</Card>
					{/* //About */}
					<Card className={`${styles.about} py-3 px-5`}>
						{isLoading || <About data={data.data} />}
					</Card>
					{/* //Parties Info */}
					<Card className={`${styles['parties-info']} text-center px-5 py-3`}>
						<PartiesInfo token={userObj.token} />
					</Card>
					<Card className={`${styles['spaces-info']} text-center px-5  pt-3`}>
						<SpacesInfo />
					</Card>
					{/* //Upcoming Events */}
					{/* <Card className={`${styles.upcoming} py-3 px-3`}>
            <UpcomingEvents />
          </Card> */}
					{/* //Interests */}
					{/* <Card className={`${styles.interests} py-3 px-5`}>
            <Interests data={data} />
          </Card> */}
					{/* //Spaces info */}
				</div>
			</Default>
			<Mobile>
				<div className="mx-3">
					<Card className={`${styles.verification} p-3`}>
						<Verification userObj={userObj} isLoading={isLoading} data={data} />
					</Card>
					{/* //Upcoming Events */}
					{/* <Card className={`${styles.upcoming} p-3`}>
            <UpcomingEvents />
          </Card> */}
					{/* //About */}
					<Card className={`${styles.about} p-3`}>
						{isLoading || <About data={data.data} />}
					</Card>
					{/* //Interests */}
					{/* <Card className={`${styles.interests} p-3`}>
            <Interests data={data} />
          </Card> */}
					{/* //Parties Info */}
					<Card className={`${styles['parties-info']} py-3 px-5`}>
						<PartiesInfo token={userObj.token} />
					</Card>
					{/* //Spaces info */}
					<Card className={`${styles['spaces-info']} py-3`}>
						<SpacesInfo />
					</Card>
				</div>
			</Mobile>
		</>
	);
}

export default Dashboard;
