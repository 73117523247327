import React, { useState } from 'react';
import parse from 'html-react-parser';


import arrow from '../../../assets/arrow.svg';

export default function Expander(props) {

    let [showtext, setShowText] = useState(false);

    let cursorStyle = {
        cursor: "pointer",
        userSelect: "none"
    }

    return (
        <div className="mt-5">
            <div onClick={() => setShowText(!showtext)} className="d-flex justify-content-between border-bottom pb-2" style={cursorStyle}>
                <h3>{props.title}</h3>
                {showtext ?  <img src={arrow} style={{transform: "rotate(180deg)"}} alt="arrow upward" /> : <img src={arrow} alt="arrow downward"/>}
            </div>
            {showtext
                ? <div className="mt-4">
                    <p>{parse(props.data)}</p>
                </div>
                : null}
        </div>
    );
}