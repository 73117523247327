import React, { useState } from 'react';
import { Modal, Form, Button, Row, Col } from 'react-bootstrap';
import TimePicker from 'react-gradient-timepicker';
import { useHistory } from 'react-router-dom';
import axios from 'axios';

import { useSelector } from 'react-redux';
import { RenderUserObj } from '../../../helpers';
import ThankyouImage from '../../../../assets/request_sent.png';

import styles from './SendEnquiry.module.scss';
import { sendEnquiry } from '../../../../api/requests';

export default function SendEnquiry({ space, ...props }) {
	const [show, setShow] = useState(false);
	let [enquiryData, setenquiryData] = useState({
		booking_date: null,
		time_from: null,
		time_to: null,
		booking_status: 0,
		booking_purpose: null,
		booking_guest_no: 0,
		alcohol_availability: null,
	});
	const userObj = RenderUserObj(useSelector(state => state.auth.data));
	const history = useHistory();
	function handleSubmit(e) {
		e.preventDefault();
		// sendEnquiry(space, userObj.token, enquiryData, props.setShowEnquiry, props.setShowModal);
		axios
			.post(
				`https://api-dev.playace.co/v1/property/send-enquiry`,
				{
					property_id: space.id,
					property_name: space.name,
					property_rate: space.rate,
					...enquiryData,
				},
				{
					headers: {
						Authorization: `Bearer ${userObj.token}`,
					},
				}
			)
			.then(response => {
				console.log(response.data);
				if (response.data.success) {
					props.setShowModal(true);
					props.setShowEnquiry(false);
					// window.location.href = `${process.env.REACT_APP_BASE_URL}`;
					// window.location.href = "http://localhost:3001/"
				} else {
					props.setShowEnquiry(false);
					// window.location.href = `${process.env.REACT_APP_BASE_URL}`;
					// window.location.href = "http://localhost:3001/"
				}
			})
			.catch(errors => {
				console.log(errors);
			});
	}

	function handleChange(e) {
		setenquiryData({ ...enquiryData, [e.target.name]: e.target.value });
	}
	const handleShow = () => setShow(true);
	const handleClose = () => {
		setShow(false);
		history.push('/');
	};
	return (
		<>
			<Modal.Header closeButton>
				<Modal.Title>{space && space.name}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="d-flex justify-content-between align-items-center">
					<div className={styles.timerate}>
						<h5>Availability</h5>
						<p>{space.timings}</p>
					</div>
					<div>
						<h5>Price starts from</h5>
						<h5 className="text-secondary fw-bold">
							&#x20B9; {space.rate}/hr*
						</h5>
					</div>
				</div>
				<Form onSubmit={handleSubmit}>
					{/* <div className="d-flex justify-content-center align-items-center mt-4"> */}
					<Row>
						<Col md={6} sm={12}>
							<Form.Label className="fw-bold">Date</Form.Label>
							<Form.Control
								type="date"
								placeholder="Enter date"
								name="booking_date"
								onChange={handleChange}
								required
							/>
						</Col>
						<Col md={3} sm={6}>
							<Form.Label className="fw-bold">Time from</Form.Label>
							<Form.Control
								type="time"
								placeholder="Enter time from"
								name="time_from"
								onChange={handleChange}
								required
							/>
							{/* <TimePicker
                // time="01:00"
                color1="#00bcd4"
                // theme="Bourbon"
                className="timepicker"
                placeholder="Enter time from"
                onSet={handleChange}
              /> */}
						</Col>
						<Col md={3} sm={6}>
							<Form.Label className="fw-bold">Time to</Form.Label>
							<Form.Control
								type="time"
								placeholder="Enter time to"
								name="time_to"
								onChange={handleChange}
								required
							/>
						</Col>
					</Row>
					{/* </div> */}
					<div className="w-100">
						<Form.Label className="fw-bold">Purpose of booking</Form.Label>
						<Form.Control
							type="text"
							placeholder="Enter purpose of booking"
							name="booking_purpose"
							onChange={handleChange}
							required
						></Form.Control>
					</div>
					<div className="my-3">
						<Form.Label className="fw-bold">
							Is alcohol going to be served at the venue?
						</Form.Label>
						<div className="d-flex">
							<Form.Check
								// inline
								id="yes"
								label="Yes"
								type="radio"
								aria-label="yes"
								value="yes"
								onClick={() => {
									setenquiryData({ ...enquiryData, alcohol_availability: 1 });
								}}
								checked={enquiryData.alcohol_availability === 1 ? true : false}
								className={`${styles['radio-button']}`}
							/>
							<Form.Check
								// inline
								id="no"
								label="No"
								type="radio"
								aria-label="no"
								value="no"
								onClick={() => {
									setenquiryData({ ...enquiryData, alcohol_availability: 2 });
								}}
								checked={enquiryData.alcohol_availability === 2 ? true : false}
								className={`${styles['radio-button']}`}
							/>
						</div>
					</div>
					<div className="w-100">
						<Form.Label className="fw-bold">No. of guests expected</Form.Label>
						<Form.Control
							type="text"
							placeholder="Enter no. of guests"
							name="booking_guest_no"
							onChange={handleChange}
							required
						></Form.Control>
					</div>
					<Button
						type="submit"
						className="d-block mx-auto btn btn-primary mt-5 mb-3 px-5"
					>
						Send enquiry
					</Button>
				</Form>
			</Modal.Body>
			{/* {props.view === "mobile" ?
        (<Modal
          size="md"
          show={show}
          onHide={handleClose}
          dialogClassName="modal-dimensions"
          backdrop="static"
          centered
        >
          <div className="d-flex flex-column justify-content-center text-center" style={{ height: "97vh" }}>
            <img src={ThankyouImage} alt="Request-sent-image" />
            <h5>Space booked successfully</h5>
            <p>Your booking is being reviewed by the host.<br />
          You will receive a notification shortly!
          </p>
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-secondary text-uppercase fw-bold px-4 mt-2 mb-3 mx-auto"
                type="submit"
                onClick={handleClose}
              >
                Done
            </button>
            </div>
          </div>
        </Modal>)
        :
        (<Modal
          size="md"
          show={show}
          onHide={handleClose}
          dialogClassName="modal-dimensions"
          backdrop="static"
        >
          <div className="d-flex flex-column justify-content-center text-center" style={{ paddingTop: "4.5em", paddingBottom: "4.5em" }}>
            <img src={ThankyouImage} alt="Request-sent-image" />
            <h5>Space booked successfully</h5>
            <p>Your booking is being reviewed by the host.<br />
          You will receive a notification shortly!
          </p>
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-secondary text-uppercase fw-bold px-4 mt-2 mb-3 mx-auto"
                type="submit"
                onClick={handleClose}
              >
                Done
            </button>
            </div>
          </div>
        </Modal>)} */}
		</>
	);
}
