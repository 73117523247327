import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import styles from  "./PlanningDetails.module.scss";
import { updateHostPartyObject } from "../../../actions/hostPartyActions";
import { useDispatch, useSelector } from "react-redux";
import AlcoholComponent from "./Amenities/AlcoholComponent";
import FoodComponent from "./Amenities/FoodComponent";
import SmokingComponent from "./Amenities/SmokingComponent";
import PetsComponent from "./Amenities/PetsComponent";
import ParkingComponent from "./Amenities/ParkingComponent";
import ElevatorComponent from "./Amenities/ElevatorComponent";
import KidsComponent from "./Amenities/KidsComponent";

export default function PlanningDetails() {
  const state = useSelector((state) => state.hostParty);
  const history = useHistory();
  const dispatch = useDispatch();
  const { handleSubmit } = useForm({
    defaultValues: {
      alcohol: state.data.alcohol,
      food: state.data.food,
      smoking: state.data.smoking,
      pet: state.data.pet,
      guest_pet: state.data.guest_pet,
      elevator: state.data.elevator,
      parking: state.data.parking,
      child: state.data.child,
      food_type: state.data.food_type,
    },
  });
  let [amenities, setAmenities] = useState({
    alcohol: state.data.alcohol,
    food: state.data.food,
    smoking: state.data.smoking,
    pet: state.data.pet,
    guest_pet: state.data.guest_pet,
    elevator: state.data.elevator,
    parking: state.data.parking,
    child: state.data.child,
    food_type: state.data.food_type,
  });

  // console.log(amenities);

  const updateObject = () => {
    dispatch(updateHostPartyObject({ ...state.data, ...amenities }));
    history.push("/host-party/banking-details");
  };

  return (
    <Container style={{ marginTop: "6rem" }}>
      <form onSubmit={handleSubmit(updateObject)}>
        <h2 className="text-center my-5">Planning Details</h2>
        <Container>
          <Row>
            <Col md={6}>
              <AlcoholComponent setAmenities={setAmenities} amenities={amenities} />
              <FoodComponent
                setAmenities={setAmenities}
                food={amenities.food}
                food_type={amenities.food_type}
              />
              <SmokingComponent
                setAmenities={setAmenities}
                smoking={amenities.smoking}
              />
            </Col>
            <Col md={6}>
              <PetsComponent
                setAmenities={setAmenities}
                amenities={amenities}
              />
              <ParkingComponent
                setAmenities={setAmenities}
                parking={amenities.parking}
              />
              <ElevatorComponent
                setAmenities={setAmenities}
                elevator={amenities.elevator}
              />
              <KidsComponent
                setAmenities={setAmenities}
                child={amenities.child}
              />
            </Col>
          </Row>
        </Container>
        <div className="d-flex justify-content-center">
          {/* {FOR MOBILE} */}
          <Link
            className={`btn btn-primary text-uppercase fw-bold px-4 ${styles.previous}`}
            to="/host-party/planning-overview"
          >
            Previous
          </Link>
          <button
            type="submit"
            className={`btn btn-primary text-uppercase fw-bold px-4 ${styles.next}`}
            onClick={()=>updateObject()}
          >
            Next
          </button>
        </div>
      </form>
    </Container>
  );
}
