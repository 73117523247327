import Food from './Teal icons-14.svg';
import NonVeg from './Teal icons-09.svg';
import Smoking from './Teal icons-15.svg';
import Alcohol from './Teal icons-10.svg';
import Veg from './Teal icons-08.svg';
import Parking from './Teal icons-04.svg';
import Kids from './Playace icon Swatches-28.svg';
import Elevator from './Teal icons-17.svg';
import AC from './Teal icons-03.svg';
import Pets from './Teal icons-02.svg';
import GuestPets from './Teal icons-06.svg';
import Footwear from './Playace icon Color Swatches-24.svg';
import Valet from './Playace icon Color Swatches-24.svg';
import Music from './Teal icons-21.svg';

const SpacesRegImages = {
    Food, NonVeg, Smoking, Alcohol, Veg, Parking, Kids, Elevator, AC, Pets, GuestPets, Footwear, Valet, Music
};

export default SpacesRegImages;