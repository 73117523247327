import React from "react";
import CarouselSlider from "react-carousel-slider";
import { useQuery } from "react-query";

import { fetchBannerAds } from "../../../api/requests";

function Slide() {
  const { data } = useQuery(["bannerAds"], fetchBannerAds, {
    retry: 1,
    refetchInterval: false,
    refetchOnWindowFocus: false
  });

  let manner = {
    autoSliding: { interval: "5s" },
    duration: "0.5s",
  };

  let sliderBoxStyle = {
    height: "300px",
    width: "100%",
    background: "transparent",
    border: "none",
  };

  let itemsStyle = {
    height: "100%",
    width: "auto",
    background: "transparent",
    border: "none",
    margin: "0px 8px",
    borderRadius: "10px",
  };

  let textBoxStyle = {
    display: "none",
  };

  let buttonSetting = {
    style: {
      left: {
        display: "none",
      },
      right: {
        display: "none",
      },
    },
  };

  let dotsSetting = {
    placeOn: "bottom",
    style: {
      dotSize: "5px",
      currDotColor: "#FF1844",
      marginTop: "2px",
    },
  };

  let banners =
    data &&
    data.map(function (item, index) {
      if (item.id === 51) {
        return (
          <a
            href="https://play.google.com/store/apps/details?id=co.playace.playace_android"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={item.image} alt="playace parties spaces house parties" width="522" height="294" />
          </a>
        );
      } else {
        return (
          <>
            <img src={item.image} alt="Playace house parties" width="522" height="294" />
          </>
        );
      }
    });

  let webBanners = banners && banners.slice(0, 6);

  // console.log(webBanners);

  return (
    <div className="slider">
      {webBanners && (
        <CarouselSlider
          slideCpnts={webBanners}
          manner={manner}
          sliderBoxStyle={sliderBoxStyle}
          dotsSetting={dotsSetting}
          buttonSetting={buttonSetting}
          itemsStyle={itemsStyle}
          textBoxStyle={textBoxStyle}
        ></CarouselSlider>
      )}
    </div>
  );
}

export default Slide;