import React from 'react';
import { Carousel } from 'react-bootstrap';

export default function PhotoGrid(props) {

    let [loading, setLoading] = React.useState(true);

    return (
        <>
            {loading ?
                <div
                    className="d-block w-100"
                    style={{ background: "#f7f7f7", height: "400px", width: "500px" }}
                    alt="First slide"
                /> : null}
            <Carousel className="photo-carousel" indicators={false} style={loading ? { display: 'none' } : null}>
                <Carousel.Item bsPrefix="carousel-item inner-item">
                    <img
                        className="img-fluid"
                        src={`${props.src}?ar=16:9&fit=crop`}
                        alt="First slide"
                        style={{ objectFit: "cover", width: "100%", objectPosition: "top" }}
                        // style={loading ? { display: 'none' } : null}
                        onLoad={() => { setLoading(false) }}
                    />
                </Carousel.Item>
            </Carousel>
        </>
    );
}