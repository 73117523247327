import React, { Suspense, lazy } from 'react';

import { Container, Col, Row } from 'react-bootstrap';

import { Default, Mobile } from '../../../helpers/Breakpoints';
import { PhotoGrid } from '../../common';
import BookTicket from '../../common/spaces/BookTicket';
import About from './About';
// import Amenities from './Amenities';
import Expander from './Expander';
// import Reviews from './Reviews';
import ShortDetails from './ShortDetails';
import CTA from '../../../pages/Home/CTA';
import Spaces from '../../../pages/Home/Spaces';
// import Attend from '../../../pages/Home/Attend';
const Amenities = lazy(() => import('./Amenities'));

export default function EventDetails(props) {
  return (
    <React.Fragment>
      <Default>
        <Container style={{ marginTop: "7rem" }}>
          <Row>
            <Col lg={8} md={7}>
              <PhotoGrid src={props.src} />
              <About title={props.aboutTitle} content={props.aboutContent} type={props.aboutType} />
              <Suspense fallback={<p>Loading...</p>}>
                <Amenities />
              </Suspense>
              <Expander title={props.partyRulesTitle} data={props.partyRulesData} />
              <Expander title={props.cancelPolicyTitle} data={props.cancelPolicyData} />
              {/* <Reviews /> */}
            </Col>
            <Col lg={4} md={5}>
              {/* //CHANGE THIS FOR SPACES */}
              <BookTicket price="350" isAuthenticated={props.isAuthenticated} userObj={props.userObj} />
            </Col>
          </Row>
          <div className="mt-5" />
          <CTA />
          <Spaces title="You may also like" button={false} />
          {/* <Attend /> */}
        </Container>
      </Default>
      <Mobile>
        <div style={{ margin: "5rem 1.5rem 0 1.5rem" }}>
          <PhotoGrid src={props.src} />
          <ShortDetails title={props.spacesDescription} location={props.location} />
          <About title={props.aboutTitle} content={props.aboutContent} />
          {/* //EXPANDABLE */}
          <Suspense fallback={<p>Loading...</p>}>
            <Amenities />
          </Suspense>
        </div>
        <CTA />
        <Spaces title="You may also like" button={false} />
        {/* //FOOTER TICKET */}
        <div className="d-flex fixed-bottom ticket justify-content-between align-items-center px-2 py-1">
          <div>
            <span>Contribution</span><br />
            <span className="fw-bolder">Rs.300/- per person</span>
          </div>
          {/* //CHANGE THIS FOR SPACES */}
          {/* <BookTicket view="sticky" price="350" isAuthenticated={props.isAuthenticated} userObj={props.userObj} text="Request to book" /> */}
        </div>
      </Mobile>
    </React.Fragment>
  );
}