import React from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import PartyImg from '../../../assets/party.png';
import { Default, Mobile } from '../../../helpers/Breakpoints';
import { Loader } from '../../../helpers';
import { fetchUserStats } from '../../../../api/requests';

export default function PartiesInfo(props) {
	const { data, isLoading } = useQuery(
		['FETCH_USER_STATS', props.token],
		fetchUserStats,
		{
			refetchOnWindowFocus: false,
		}
	);

	if (isLoading) {
		return (
			<div
				style={{ minHeight: '70vh', margin: '7rem auto', textAlign: 'center' }}
			>
				<Loader height="80px" width="80px" />
			</div>
		);
	}

	return (
		<>
			<Default>
				<img
					src={PartyImg}
					className="img-fluid"
					style={{ height: 'auto', width: '180px' }}
					alt="Party icon"
				/>
				<h4 className="text-uppercase text-primary fw-bold mt-4 text-center">
					Parties
				</h4>
				<hr />
				<div>
					<div className="d-flex justify-content-between text-center">
						<div className="mr-3">
							<h4 className="fw-bold text-primary">{data.parties_attended}</h4>
							<p className="m-0">Attended</p>
						</div>
						<div>
							<h4 className="fw-bold text-primary">{data.parties_attending}</h4>
							<p className="m-0">Attending</p>
						</div>
					</div>
					<hr />
					<div className="d-flex justify-content-between text-center">
						<div>
							<h4 className="fw-bold text-primary">{data.parties_hosted}</h4>
							<p className="m-0">Hosted</p>
						</div>
						<div>
							<h4 className="fw-bold text-primary">{data.parties_hosting}</h4>
							<p className="m-0">Hosting</p>
						</div>
					</div>
				</div>
				<Link
					to="host-party/planning-overview"
					className="btn btn-primary btn-reg fw-bold text-uppercase my-4"
				>
					Host now
				</Link>
			</Default>
			<Mobile>
				<img
					src={PartyImg}
					className="img-fluid"
					style={{ height: 'auto', width: '180px' }}
					alt="Party icon"
				/>
				<h4 className="text-uppercase text-primary fw-bold mt-4 text-center">
					Parties
				</h4>
				<hr />
				<div>
					<div className="d-flex justify-content-between text-center">
						<div className="mr-3">
							<h4 className="fw-bold text-primary">{data.parties_attended}</h4>
							<p className="m-0">Attended</p>
						</div>
						<div>
							<h4 className="fw-bold text-primary">{data.parties_attending}</h4>
							<p className="m-0">Attending</p>
						</div>
					</div>
					<hr />
					<div className="d-flex justify-content-between text-center">
						<div>
							<h4 className="fw-bold text-primary">{data.parties_hosted}</h4>
							<p className="m-0">Hosted</p>
						</div>
						<div>
							<h4 className="fw-bold text-primary">{data.parties_hosting}</h4>
							<p className="m-0">Hosting</p>
						</div>
					</div>
				</div>
			</Mobile>
		</>
	);
}
