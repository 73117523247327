import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Toast } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCompass, faCalendar } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-datepicker';
import LoadingOverlay from 'react-loading-overlay';
import { RenderUserObj } from '../../helpers';
import styles from './PlanningOverview.module.scss';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';
import PlacesAutocomplete, {
	geocodeByAddress,
	getLatLng,
} from 'react-places-autocomplete';

import { GoogleMapsAPI } from './client-config';

import 'react-datepicker/dist/react-datepicker.css';

const validateCity = city_id => {
	const city = typeof city_id === 'string' ? city_id.toLowerCase() : city_id;
	if (
		city === 'mumbai' ||
		city === 1 ||
		city === 'navi mumbai' ||
		city === 2 ||
		city === 'thane' ||
		city === 3 ||
		city === 'pune' ||
		city === 4 ||
		city === 'kochi' ||
		city === 5 ||
		city === 'lonavala' ||
		city === 'lonavla' ||
		city === 6 ||
		city === 'bengaluru' ||
		city === 'bangalore' ||
		city === 7 ||
		city === 'delhi' ||
		city === 'new delhi' ||
		city === 8 ||
		city === 'gurugram' ||
		city === 9 ||
		city === 'nashik' ||
		city === 'nasik' ||
		city === 10
	) {
		return true;
	} else {
		return false;
	}
};

function PlanningOverview(props) {
	const state = useSelector(state => state.hostParty);
	const [address, setAddress] = useState('');
	const [loading, setLoading] = useState(false);
	const [googleAddress, setGoogleAddress] = useState('');
	const [show, setShow] = useState(false);
	const [city, setCity] = useState('');
	const [area, setArea] = useState('');
	const [token, setToken] = useState(null);
	const [selectedState, setSelectedState] = useState('');
	const [pincode, setPincode] = useState('');
	const [blockNo, setBlockNo] = useState('');
	const [landmark, setLandmark] = useState('');
	const [date, setDate] = useState('');
	const [thirdStage, setThirdStage] = useState(false);
	const [openToast, setOpenToast] = useState(false);
	const [cityId, setCityId] = useState(0);
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [minDate, setMinDate] = useState(new Date());
	const [dateValue, setDateValue] = useState(new Date());
	const [mapPosition, setMapPosition] = useState({
		lat: 0,
		lng: 0,
	});
	const history = useHistory();

	const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
	const userObj = RenderUserObj(useSelector(state => state.auth.data));
	useEffect(() => {
		if (isAuthenticated) {
			if (userObj !== undefined) setToken(userObj.token);
		}
	}, [isAuthenticated, userObj]);

	useEffect(() => {
		var today = new Date();
		var dd = today.getDate();
		var mm = today.getMonth() + 1; //January is 0!
		var yyyy = today.getFullYear();
		if (dd < 10) {
			dd = '0' + dd;
		}
		if (mm < 10) {
			mm = '0' + mm;
		}

		today = yyyy + '-' + mm + '-' + dd;
		setMinDate(today);
	}, []);

	useEffect(() => {
		axios
			.get('https://api-dev.playace.co/v1/event/cities', {
				headers: {
					Authorization:
						'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNTgxNTc2NDc3fQ.DHX5A3jCcT5gqEnaOLxvoYue_LbCVEfqjMGJcH6z1jY',
				},
			})
			.then(res => {
				let cityList = [...res.data.data];
				let currentCity = cityList.filter(i => i.cityName === city);
				if (currentCity.length > 0)
					currentCity.map(i => {
						setCityId(i.cityID);
					});
			});
	}, [city]);

	const getCity = addressArray => {
		let city = '';
		for (let i = 0; i < addressArray.length; i++) {
			if (addressArray[i].types[0] && 'locality' === addressArray[i].types[0]) {
				city = addressArray[i].long_name;
				return city;
			}
		}
	};

	const getArea = addressArray => {
		let area = '';
		for (let i = 0; i < addressArray.length; i++) {
			if (addressArray[i].types[0]) {
				for (let j = 0; j < addressArray[i].types.length; j++) {
					if (
						'sublocality_level_1' === addressArray[i].types[j] ||
						'locality' === addressArray[i].types[j]
					) {
						area = addressArray[i].long_name;
						return area;
					}
				}
			}
		}
	};

	const getState = addressArray => {
		let state = '';
		for (let i = 0; i < addressArray.length; i++) {
			for (let i = 0; i < addressArray.length; i++) {
				if (
					addressArray[i].types[0] &&
					'administrative_area_level_1' === addressArray[i].types[0]
				) {
					state = addressArray[i].long_name;
					return state;
				}
			}
		}
	};

	const getPinCode = addressArray => {
		let state = '';
		for (let i = 0; i < addressArray.length; i++) {
			for (let i = 0; i < addressArray.length; i++) {
				if (
					addressArray[i].types[0] &&
					'postal_code' === addressArray[i].types[0]
				) {
					state = addressArray[i].long_name;
					return state;
				}
			}
		}
	};

	const { handleSubmit, errors, control, register, clearErrors } = useForm({
		defaultValues: {
			name: state.data.name,
			description: state.data.description,
			party_date: state.data.party_date,
			start_time: state.data.start_time,
			end_time: state.data.end_time,
			last_date: state.data.last_date,
			accommodate_people: parseInt(state.data.accommodate_people),
			price: parseInt(state.data.price),
			state_id: state.data.state_id,
			city_id: state.data.city_id,
			locality: state.data.locality,
			address: state.data.address,
			pincode: state.data.pincode,
		},
	});

	const onSubmit = e => {
		setLoading(true);
		console.log('clear', e);
		setIsSubmitted(true);
		clearErrors(['search']);
		let partyRequest = {
			location: {
				street_address: address,
				locality: landmark,
				pincode: pincode,
				city_id: cityId,
			},
			tentative_date: moment(date).format('yyyy-MM-DD'),
		};
		let options = {
			method: 'POST',
			headers: {
				Authorization: `Bearer ${token}`,
			},
			data: partyRequest,
		};

		axios('https://api-dev.playace.co/v1/host-party-request', options).then(
			res => {
				console.log(res);
				clearErrors(['search']);
				handleShow();
				setLoading(false);
				props.closeModal();
			}
		);
	};

	const getCurrentLocation = () => {
		// if (navigator.geolocation && navigator.geolocation) {
		navigator.geolocation.getCurrentPosition(position => {
			const KEY = 'AIzaSyA4CG-Jpp67P29xeRnia4hgSlbIc_GhzAU';
			axios
				.get(
					'https://maps.googleapis.com/maps/api/geocode/json?latlng=' +
						position.coords.latitude +
						',' +
						position.coords.longitude +
						'&key=' +
						KEY
				)
				.then(res => {
					console.log(res);
					const addressNew = res.data.results[0].formatted_address,
						addressArray = res.data.results[0].address_components,
						cityNew = getCity(addressArray),
						areaNew = getArea(addressArray),
						stateNew = getState(addressArray),
						pincodeNew = getPinCode(addressArray);

					const responseAddress = addressNew ? addressNew : '';
					const responseArea = areaNew ? areaNew : '';
					const responseState = stateNew ? stateNew : '';
					const responseCity = cityNew ? cityNew : '';
					const responsePincode = pincodeNew ? pincodeNew : '';

					setAddress(responseAddress);
					setCity(responseCity);
					setArea(responseArea);
					setSelectedState(responseState);
					setPincode(responsePincode);
					setMapPosition({
						lat: position.coords.latitude,
						lng: position.coords.longitude,
					});
					setGoogleAddress('');
					setThirdStage(true);
				})
				.catch(err => console.log(err));
		});
		// }
	};
	const validateAddress = async value => {
		const results = await geocodeByAddress(value);
		const latLng = await getLatLng(results[0]);

		const KEY = 'AIzaSyA4CG-Jpp67P29xeRnia4hgSlbIc_GhzAU';
		axios
			.get(
				'https://maps.googleapis.com/maps/api/geocode/json?latlng=' +
					latLng.lat +
					',' +
					latLng.lng +
					'&key=' +
					KEY
			)
			.then(res => {
				let addressArray = res.data.results[0].address_components;
				let cityNew = getCity(addressArray),
					addressNew = res.data.results[0].formatted_address,
					stateNew = getState(addressArray),
					pincodeNew = getPinCode(addressArray);
				console.log(addressNew, res);
				let isCityValidated = validateCity(cityNew);
				setCity(cityNew);
				setPincode(pincodeNew);
				setSelectedState(stateNew);
				setAddress(addressNew);

				if (!isCityValidated) {
					setOpenToast(true);
					setPincode('');
					setCity('');
					setSelectedState('');
				}
			});
	};

	const handleSelect = async value => {
		const results = await geocodeByAddress(value);
		const latLng = await getLatLng(results[0]);
		setArea(value.substr(0, value.indexOf(',')));
		setGoogleAddress('');
		setMapPosition(latLng);
		validateAddress(value);
		!thirdStage && setGoogleAddress('');
		setThirdStage(true);
	};

	const handleBlockNo = e => setBlockNo(e.target.value);
	const handleLandmark = e => setLandmark(e.target.value);
	const handlePincode = e => setPincode(e.target.value);
	const handleCity = e => setCity(e.target.value);
	const handleState = e => setSelectedState(e.target.value);
	const handleShow = () => setShow(true);
	return (
		<LoadingOverlay active={loading} spinner text="">
			<Container className="pb-md-5 pt-2">
				<h2 className="text-center">Planning overview</h2>
				<Container>
					<form onSubmit={handleSubmit(onSubmit)}>
						<Row>
							<Col
								md={12}
								className="d-flex flex-column align-items-center justify-content-between"
							>
								<Col md={12}>
									<p className={`${styles['message']}`}>
										Bookings close 1 hour prior to the party start time
									</p>
									<div className="d-flex flex-column position-relative">
										<label>
											<h4>Party Date</h4>
										</label>
										<Controller
											className="col-12 w-100"
											control={control}
											name="birthDate"
											rules={{ required: true }}
											defaultValue={null}
											render={props => (
												<DatePicker
													className="input"
													selected={props.value}
													minDate={new Date()}
													onChange={e => {
														props.onChange(e);
														setDate(e);
													}}
													placeholderText="dd/mm/yyyy"
												/>
											)}
										/>
										<p className="text-danger">
											{errors.birthDate && 'Required'}
										</p>
									</div>
									<div>
										<label>
											<h4>Party Address</h4>
										</label>
										<PlacesAutocomplete
											value={googleAddress}
											onChange={setGoogleAddress}
											onSelect={handleSelect}
										>
											{({
												getInputProps,
												suggestions,
												getSuggestionItemProps,
												loading,
											}) => (
												<div style={{ margin: 0 }}>
													<Controller
														className="col-12 w-100"
														control={control}
														name="search"
														// rules={{ required: true, minLength: 0 }}
														render={props => (
															<input
																{...getInputProps({
																	placeholder: 'Type and select your address from the list',
																	name: 'search',
																})}
															/>
														)}
													/>
													<p className="text-danger">
														{address === '' &&
															Object.keys(errors).length > 0 &&
															'Please select an address from the list'}
													</p>
													<div
														style={{
															boxShadow:
																suggestions.length > 0
																	? '0px 0px 4px 2px rgb(0 0 0 / 15%)'
																	: 'none',
															borderRadius: '5px',
															padding: '0 0.5em',
														}}
													>
														{loading ? <div>...loading</div> : null}

														{suggestions.map(suggestion => {
															const style = {
																backgroundColor: suggestion.active
																	? '#e7e7e7'
																	: '#fff',
																cursor: 'pointer',
																padding: '0.5em 0',
															};

															return (
																<div
																	{...getSuggestionItemProps(suggestion, {
																		style,
																	})}
																>
																	{suggestion.description}
																</div>
															);
														})}
													</div>
												</div>
											)}
										</PlacesAutocomplete>
										<div
											className={`${styles['select-location']} my-2`}
											onClick={getCurrentLocation}
										>
											<span className="d-flex align-items-center">
												<FontAwesomeIcon
													className="mr-2 bg-light text-primary"
													icon={faCompass}
												></FontAwesomeIcon>
												<p>Current Location using GPS</p>
											</span>
										</div>
									</div>
									<div
										style={{
											display: thirdStage ? 'block' : 'none',
											width: '100%',
										}}
									>
										<label>
											<h5>Selected Address</h5>
										</label>
										<textarea
											name="currentAddress"
											defaultValue={address}
											rows={2}
											cols={105}
											className={`${styles['address']}`}
										></textarea>
										<Toast
											show={openToast}
											className="d-flex justify-content-center m-auto"
											onClose={() => setOpenToast(false)}
											show={openToast}
											delay={4000}
											autohide
										>
											<Toast.Body
												className="text-center"
												style={{ color: '#ff1844' }}
											>
												This location isn't servicable yet.
											</Toast.Body>
										</Toast>
										{/* <div
											style={{
												position: 'relative',
												height: '500px',
												margin: '1em 0',
											}}
										>
											<Map
												google={props.google}
												zoom={17}
												initialCenter={{
													lat: mapPosition.lat,
													lng: mapPosition.lng,
												}}
												center={{
													lat: mapPosition.lat,
													lng: mapPosition.lng,
												}}
											>
												<Marker
													position={{
														lat: mapPosition.lat,
														lng: mapPosition.lng,
													}}
												/>
											</Map>
										</div> */}
										<label>
											<h5>House No</h5>
										</label>
										<input
											name="HouseNo"
											type="text"
											onChange={handleBlockNo}
											placeholder="House/Flat/Block No."
											ref={register({
												required: 'Required',
											})}
											value={blockNo}
										/>
										<p className="text-danger">
											{errors.HouseNo && errors.HouseNo.message}
										</p>
										<label>
											<h5>Landmark</h5>
										</label>
										<input
											name="landmark"
											type="text"
											onChange={handleLandmark}
											placeholder="Landmark"
											ref={register({
												required: 'Required',
											})}
											value={landmark}
										/>
										<p className="text-danger">
											{errors.landmark && errors.landmark.message}
										</p>
									</div>
									<div></div>
									<label>
										<h5>City</h5>
									</label>
									<select
										value={city}
										onChange={handleCity}
										className="w-100 py-3 border-top-0 border-right-0 border-left-0 mb-4 bg-white"
									>
										<option value="">City</option>
										<option value="Mumbai">Mumbai</option>
										<option value="Navi Mumbai">Navi Mumbai</option>
										<option value="Thane">Thane</option>
										<option value="Pune">Pune</option>
										<option value="Kochi">Kochi</option>
										<option value="Lonavala">Lonavala</option>
										<option value="Bengaluru">Bengaluru</option>
										<option value="Delhi">Delhi</option>
										<option
											value="New Delhi"
											style={{
												display: city === 'New Delhi' ? 'block' : 'none',
											}}
										>
											Delhi
										</option>
										<option value="Gurugram">Gurugram</option>
										<option value="Nashik">Nashik</option>
									</select>

									<label>
										<h5>State</h5>
									</label>
									<select
										value={selectedState}
										onChange={handleState}
										className="w-100 py-3 border-top-0 border-right-0 border-left-0 mb-4 bg-white"
									>
										<option value="">State</option>
										<option value="Maharashtra">Maharashtra</option>
										<option value="Kerala">Kerala</option>
										<option value="Karnataka">Karnataka</option>
										<option
											value="Delhi"
											style={{
												display: selectedState === 'Delhi' ? 'block' : 'none',
											}}
										>
											New Delhi
										</option>
										<option value="New Delhi">New Delhi</option>
										<option value="Haryana">Haryana</option>
									</select>

									<label>
										<h5>Pincode</h5>
									</label>
									<input
										name="pincode"
										type="text"
										onChange={handlePincode}
										placeholder="Pincode"
										value={pincode}
									/>
									<Button
										type="submit"
										className={`${styles['submit']} btn btn-primary mt-3 py-2 w-50`}
									>
										Submit
									</Button>
								</Col>
							</Col>
						</Row>
					</form>
				</Container>
			</Container>
		</LoadingOverlay>
	);
}

export default GoogleApiWrapper({
	apiKey: GoogleMapsAPI,
})(PlanningOverview);
