import React from 'react';
import Img from 'react-cool-img';

import AAP from '../../../assets/img/attend a party.png';
import styles from './Party.module.scss';

import { Link } from 'react-router-dom';

import moment from 'moment';

import { useQuery } from 'react-query';
import { fetchUpcomingPartiesAsGuest } from '../../../../api/requests';
import { Loader } from '../../../helpers';
import { v4 } from 'uuid';

export default function AsGuest({ token, ...props }) {
	const { data, isLoading } = useQuery(
		['FETCH_PARTIES_AS_GUEST', token],
		fetchUpcomingPartiesAsGuest,
		{
			retry: 1,
			refetchOnWindowFocus: false,
		}
	);
	console.log(data);
	// function isLive(date, time_from, time_to){
	//   console.log(moment(), date, time_from, time_to);
	// }
	return (
		<div className={styles['as-a-guest']}>
			<p className="fw-bold text-uppercase text-primary">As a guest</p>
			<div>
				{isLoading && (
					<div className={styles['loading-overlay']}>
						<Loader />
					</div>
				)}
				{data && data.data.length === 0 && <p>Not attending any parties.</p>}
				{data &&
					data.data.map(party => {
						if (party.review_status === 0 || party.review_status === 2) {
							return <PartyCardWithoutImage key={v4()} {...party} />;
						} else {
							return <PartyCardWithImage key={v4()} {...party} />;
						}
					})}
				<Link to="/parties/page/1 " className="btn btn-primary fw-bold mt-5">
					EXPLORE PARTIES
				</Link>
			</div>
		</div>
	);
}

function PartyCardWithImage(props) {
	const bookingStatus = props.event_booking_status.toLowerCase();
	return (
		<div
			className={`${styles['card-with-img']} ${bookingStatus === 'awaiting approval'
				? 'border-pending'
				: bookingStatus === 'disapproved'
					? null
					: 'border-approved'
				} shadow`}
		>
			<div className={styles['card-with-img__img']}>
				<Img
					style={{
						borderTopLeftRadius: '15px',
						borderBottomLeftRadius: '15px',
						objectFit: 'cover',
					}}
					src={props.event_image}
					error={AAP}
					alt="party attending"
				/>
			</div>
			<div className={`${styles['card-with-img__content']}  py-3 pr-3 ml-3`}>
				<div className="d-flex justify-content-between">
					<div>
						<h5 className="fw-bold">{props.event_heading}</h5>
						<small className="m-0 text-muted">
							{moment(props.event_date).format('MMM DD')} | {props.time_from}-
							{props.time_to}
						</small>
						<br />
						<small className="m-0 text-muted">
							{/* USE CITYNAME and LOCALITY */}
							{props.event_location && props.event_location.locality},{' '}
							{props.event_location && props.event_location.city}
						</small>
					</div>
					<div>
						<h5 className="fw-bold mr-1 price">
							{props.event_price ? <>&#8377; {props.event_price}</> : <>FREE</>}
						</h5>
					</div>
				</div>
				<p className="fw-bold text-primary m-0">
					{props.event_booking_status.toLowerCase() === 'awaiting approval' ? (
						<>PENDING</>
					) : props.event_booking_status.toLowerCase() === 'disapproved' ? (
						<>REJECTED</>
					) : (
						<>APPROVED</>
					)}
				</p>
			</div>
		</div>
	);
}

function PartyCardWithoutImage(props) {
	return (
		<div className={`${styles['card-without-img']} shadow`}>
			<div className={`${styles['card-without-img__content']}`}>
				<div className="d-flex justify-content-between p-3">
					<div>
						<h5 className="fw-bold">{props.event_heading}</h5>
						<small className="m-0 text-muted">
							{moment(props.event_date).format('MMM DD')} | {props.time_from}-
							{props.time_to}
						</small>
						<br />
						<small className="m-0 text-muted">Khar West, Mumbai</small>
					</div>
					<div className="d-flex flex-column justify-content-between">
						<h5 className="fw-bold text-right price">
							{props.event_price ? <>&#8377; props.event_price</> : <>FREE</>}
						</h5>
						<p className="fw-bold text-primary m-0">
							{props.event_booking_status.toLowerCase() ===
								'awaiting approval' ? (
								<>PENDING</>
							) : props.event_booking_status.toLowerCase() === 'disapproved' ? (
								<>REJECTED</>
							) : (
								<>APPROVED</>
							)}
						</p>
					</div>
				</div>
			</div>
		</div>
	);
}
