import React from 'react';

// import { BrowserRouter } from 'react-router-dom';

import { Header, Footer } from './components/pages/layout';

function App() {
	return (
		<>
			<Header />
			<Footer />
		</>
	);
}

export default App;
