import React, { useState, useRef, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import { useDispatch, useSelector, connect } from 'react-redux';
import { authenticateUserRequest } from '../../../actions/authActions';
import './OTPComponent.module.scss';

function OTPComponent(props) {
	let [otp, setOtp] = useState({
		digit_1: '',
		digit_2: '',
		digit_3: '',
		digit_4: '',
		digit_5: '',
		digit_6: '',
	});
	const errorObj = useSelector(state => state.auth);
	let [OTPError, setOTPError] = useState('');
	let [OTPVerified, setOTPVerified] = useState(false);
	const dispatch = useDispatch();

	const digit_1 = useRef(null);
	const digit_2 = useRef(null);
	const digit_3 = useRef(null);
	const digit_4 = useRef(null);
	const digit_5 = useRef(null);
	const digit_6 = useRef(null);
	const submitRef = useRef(null);

	useEffect(() => {
		const OTP = Object.values(otp).join('');
		if (errorObj !== undefined && OTP.length === 6 && OTPVerified) {
			if (
				errorObj.errors.message === 'OTP not match' ||
				errorObj.errors.message === 'Mobile no. already verified'
			) {
				setOTPError('Incorrect OTP');
			}
		} else if (OTP.length < 6 && OTPVerified) {
			setOTPError('OTP cannot be empty');
		}
	}, [errorObj, otp]);

	useEffect(() => {
		if (OTPVerified === false) {
			setOTPError('');
		}
	}, [OTPVerified]);

	const handleSubmit = e => {
		e.preventDefault();
		const OTP = Object.values(otp).join('');
		dispatch(authenticateUserRequest(props.phone, OTP));

		setOTPVerified(true);
	};

	const logChange = e => {
		e.persist();
		setOtp(prev => ({ ...prev, [e.target.name]: e.target.value }));
	};

	//   console.log(props);

	return (
		<div>
			<Form onSubmit={handleSubmit}>
				<p className="text-center">
					Please enter the OTP sent to {props.phone}
				</p>

				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						marginBottom: '2rem',
					}}
				>
					<input
						ref={digit_1}
						type="number"
						name="digit_1"
						className="otp-field"
						value={otp.digit_1}
						onChange={e => {
							logChange(e);
							if (e.target.value.length > 0) {
								digit_2.current.focus();
							}
						}}
						autoFocus
					/>
					<input
						ref={digit_2}
						type="number"
						name="digit_2"
						className="otp-field"
						value={otp.digit_2}
						onChange={e => {
							logChange(e);
							if (e.target.value.length > 0) {
								digit_3.current.focus();
							}
						}}
					/>
					<input
						ref={digit_3}
						type="number"
						name="digit_3"
						className="otp-field"
						value={otp.digit_3}
						onChange={e => {
							logChange(e);
							if (e.target.value.length > 0) {
								digit_4.current.focus();
							}
						}}
					/>
					<input
						ref={digit_4}
						type="number"
						name="digit_4"
						className="otp-field"
						value={otp.digit_4}
						onChange={e => {
							logChange(e);
							if (e.target.value.length > 0) {
								digit_5.current.focus();
							}
						}}
					/>
					<input
						ref={digit_5}
						type="number"
						name="digit_5"
						className="otp-field"
						value={otp.digit_5}
						onChange={e => {
							logChange(e);
							if (e.target.value.length > 0) {
								digit_6.current.focus();
							}
						}}
					/>
					<input
						ref={digit_6}
						type="number"
						name="digit_6"
						className="otp-field"
						value={otp.digit_6}
						onChange={e => {
							logChange(e);
							if (e.target.value.length > 0) {
								submitRef.current.focus();
							}
						}}
					/>
				</div>

				<Button
					type="submit"
					ref={submitRef}
					variant="primary"
					block
					className="mt-3 py-2"
					onClick={props.handleHost}
					disabled={Object.values(otp).join('').length < 6}
				>
					Verify & Login
				</Button>
				{OTPError !== '' ? (
					<p className="mt-3 py-2 text-center text-primary">{OTPError}</p>
				) : null}
				<p
					className="mt-3 py-2 text-center text-primary make-pointer"
					onClick={() => {
						setOtp({
							digit_1: '',
							digit_2: '',
							digit_3: '',
							digit_4: '',
							digit_5: '',
							digit_6: '',
						});
						setOTPVerified(false);
					}}
				>
					Clear
				</p>
			</Form>
		</div>
	);
}

const mapStateToProps = state => ({
	auth: state.auth,
});

export default connect(mapStateToProps, {})(OTPComponent);
