import React from 'react';
import Img from 'react-cool-img';
// import { EventCard } from './PartyBookingRequestReceived';
import { fetchUpcomingPartiesAsGuest } from '../../../../../api/requests';
import { useQuery } from 'react-query';
import { v4 } from 'uuid';
import { Link } from 'react-router-dom';
import moment from 'moment';

export default function PartyBookingRequestSent(props) {
	const { data, isLoading } = useQuery(
		['FETCH_BOOKED_PARTIES', props.token],
		fetchUpcomingPartiesAsGuest,
		{
			refetchOnWindowFocus: false,
		}
	);
	return (
		<div>
			<p className="text-uppercase text-primary">Booking Requests Sent</p>
			{console.log(data)}
			{!isLoading && data && data.data.length === 0 && (
				<p>No parties booked.</p>
			)}
			{!isLoading &&
				data &&
				data.data.map(party => {
					return (
						<React.Fragment key={v4()}>
							<EventCard {...party} />
							<hr />
						</React.Fragment>
					);
				})}
		</div>
	);
}

export function EventCard(props) {
	// console.log(props);
	return (
		<Link to={`/parties/${props.event_id}`} className="link-unstyled">
			<div className="d-flex justify-content-start align-items-center my-3">
				<div className="col-md-6 d-flex justify-content-center">
					<Img
						src={props.event_image}
						className="img-fluid"
						style={{ objectFit: 'contain', width: 'auto', height: '75px' }}
					/>
				</div>
				<div className="col-md-6 ml-3">
					<h6 className="fw-bold">{props.event_heading}</h6>
					<small className="text-muted d-block">
						{moment(props.date).format('MMM DD')} | {props.time_from} -
						{props.time_to}
					</small>
					<small className="text-muted d-block">
						{/* USE CITYNAME and LOCALITY */}
						{props.event_location && props.event_location.locality},
						{' '}
						{props.event_location && props.event_location.city}
					</small>
				</div>
			</div>
		</Link>
	);
}
