import React from "react";
import { Container } from "react-bootstrap";

export default function PrivacyPolicy() {
  return (
    <Container style={{ marginTop: "7rem" }}>
      <h4 align="center">PRIVACY POLICY </h4>
      <p>
        Welcome to Playace. We’ve created an online space to help you find a
        unique andpersonally-tailored means of connection through a system of
        hosting and attending events in privatespaces, making it easier than
        ever to meet new people and forge an individual sense of belonging.We
        have developed a simple set of guidelines for your convenience. Please
        take the time tocarefully go through them so it that you may navigate
        our platform with ease and understanding.
      </p>
      <p>
        We’re serious about guarding the privacy of our users. The outline of
        our privacy policy isdetermined to tackle all of your security concerns.
        This will be a brief in regards to how Playace.cocollects. Uses,
        processes and discloses your information. This policy applies to the
        collective host ofour platforms including our websites, mobile
        applications and our social media accounts. Our sitemay contain links to
        independent third party websites. Playace is not responsible for the
        privacypractices, provisions, utilities or contents of unaffiliated
        websites.
      </p>
      <p>
        By sharing your information with us through the listing of a house party
        or the search for one, as wellas any extraneous tools or services
        provided on this site, you are expressly agreeing to the terms of our
        privacy policy. If you have further concerns about our practices please
        feel free to review ourpolicy in full. We will be sure to take note of
        your feedback
      </p>
      <h5>Information We Gather</h5>
      <p>
        In order to render the highest quality services to our users, we may
        collect information about yourvisit. The information we gather includes
        your search history, IP address, screen resolution, browserused,
        operating system and settings, access times and referring URL. If you
        inquire, book, or post alisting on our web portal, we collect your
        personal information as follows: your full name, date ofbirth, email
        address and password. Please note that by providing us with your
        personal information,you are agreeing that you have the authorization to
        provide this information and that it is true,accurate and current
      </p>
      <h5>How We Use Your Personal Data</h5>
      <p>
        There are a number of reasons as to why we may collect your personal
        data. Our primary purpose ofcollecting your data is to provide you with
        the services you request and those which we believe willenhance your
        experience of our site. We may also collect it for the following reasons
      </p>
      <ul>
        <li>To allow you to register with, login, and use our services.</li>
        <li>To support your request, interest or inquiries.</li>
        <li>To display relevant recommendations.</li>
        <li>
          To contact you from time to time for recent updates and confirmation
          mails.
        </li>
        <li>
          To prevent, investigate ro prosecute activity we may think may be
          potentially illegal, unlawful or harmful.
        </li>
      </ul>
      <h5>Disclosure Of Information To Third Party Sources</h5>
      <p>
        We may disclose your personal data to carry out our policies where we
        are permitted or required todo so as applicable by law. Such as in
        response to a request by law enforcement or governmentalauthority, in
        connection with actual or proposed litigation, or to protect our
        property, people and otherrights or interests.
      </p>
      <p>
        *If substantially all of our assets are sold or merged with another
        company, we will immediately notifyyou of the transaction and the
        acquiring entity shall be given access to your personal information.
        Ifwe sell only a part of our business, the acquiring entity may still
        have access to your personalinformation without your consent and we will
        send you a notice regarding the sale.
      </p>
      <p>We may also disclose your information to:</p>
      <ul>
        <li>
          Any third part you have asked us to share your personal data with --
          such as Facebook, Instagram or Twitter.
        </li>
        <li>Our affiliates and companies within the Playace group.</li>
      </ul>
      <h5>How Do We Protect Your Personal Information?</h5>
      <p>
        We keep the information we collect stored in our systems protected from
        unauthorized access andmisuse. Our systems are configured with data
        encryption, as well as scrambling technologies andfirewalls constructed
        to industry standards. Your personal information may only be accessed
        byauthorized persons within our organizations, or our third party
        suppliers, who require the access tocarry out the uses indicated in this
        privacy policy. We take reasonable technical and organizationalmeasures
        to safeguard against unauthorized or illegal processing of your personal
        data and againstaccidental loss or destruction of, or damage to, your
        personal data. While no system is completelysecure, we believe the
        measures executed by our site lessen your vulnerability and
        susceptibility tosecurity problems to a level suitable to the type of
        data involved. We have security measures inplace to protect our user
        database and access to this database is internally restricted.
      </p>
      <h5>Use Of Your Photographs and Videos</h5>
      <p>
        There may be photographers and/or videographers hired by Playace.co to
        record and capturevideos and photographs of all the parties present
        and/or attending and/or participating in houseparties/events aggregated
        or organised by Playace.co. ‘Parties present’ refers to users who
        havesigned up with Playace.co and booked the house Party or event for
        themselves and the guestsaccompanying them i.e attendees whose
        entry/ticket is booked by the primary attendee throughmeans of payment,
        a Playace ambassador, or any person present during/at the event who is
        invitedby the host, in addition to any executives representing any
        brands associated to the houseparty/event or any individual present at
        the house party/event.
      </p>
      <p>
        If you are participating in a house party/event through any of the
        mediums or methods demonstratedabove, you agree to and grant us the
        following permissions:
      </p>
      <ol>
        <li>
          Use of your photographs and videos captured/recorded during these
          house parties/events for thepurpose of posting/uploading on the
          website and all social media accounts owned by Playace.co.
        </li>
        <li>
          Promoting your photographs and videos on the website, playace.co and
          all social media accountsowned by playace.co, organically as well as
          with targeted paid advertisement campaigns, not limitingits reach to
          any particular number of users of social media and social networking
          companies.
        </li>
        <li>
          {" "}
          Using your photographs and videos on marketing materials like fliers,
          standees, boards,presentations etc for offline marketing purposes.
        </li>
        <li>
          Sharing it with the Hosts, under the given terms and conditions of the
          host agreement, limitingthem to use it on their social media accounts
          both personal and/or business, for the sole purpose ofpromoting the
          house party/event hosted/organised by them.
        </li>
      </ol>
      <p>
        All the photographs and videos captured and recorded at the house
        parties/event, aggregated ororganised by Playace.co are sole property of
        Playace.co. We use these photographs and videos toexpand our network and
        to invite people to be a part of our growing community.
      </p>
      <p>
        If you are hesitant about your privacy and do not wish to be captured or
        recorded at the house parties or events aggregated or organised by
        Playace.co, you can request the photographers andthe videographers
        present at the venue not to do so. Simultaneously, you can also write to
        us atcrew@playace.co and request us to not use your photographs and/or
        videos on our website and inall or any of our social media accounts. It
        is highly recommended that you request the photographerand/or
        videographer present at the house party aggregated or organised by
        Playace.co not tocapture or record your footage if it makes you
        uncomfortable, as they may not be always hired byPlayace.co. They may
        also be present on the request of the host (this refers to the host of
        the eventor owner/organiser of the venue). Keep in mind that anyone
        present at our parties that may be hiredby the host would do so under
        mutual understanding amongst both parties (host and thephotographers
        and/or videographers). In such cases, the host should take your consent
        for capturingand recording videos and photographs and Playace.co will
        not be responsible for the photographsand videos captured at the event.
        Playace hosts must adhere to the host agreement, which sites thatthey
        must agree not to misuse any content generated at and through the event
        hosted and/ororganised at their home and/or venue, which may be owned,
        rented or leased. However, it isadvised to request the host or the
        photographers/videographers not to capture or record yourphotographs and
        videos for foreign uses and purposes.
      </p>
      <h5>Cookies and Tracking Tools</h5>
      <p>
        “Cookies” are a small files placed in your hard drive by us. They
        contains a string of alphanumericcharacters — to your computer that
        uniquely identifies your browser. This lets us help you log infaster and
        enhances your navigation through the site. Playace or Playace’s
        affiliates may usecookies for a number of purposes, which include
        keeping you logged in, showing you what you havepreviously viewed,
        analyzing your web page flow, customizing our services, remembering
        whichpage you were just on so you can be redirected back to it, and
        remembering your various selectedpreferences. We may use cookies to
        manage our users’ sessions and to store preferences,
        trackinginformation, and language selection. Cookies may be used
        regardless of whether you registered withus or not. This privacy policy
        solely covers Cookies used by Playace, and is not liable to cookiesused
        by third party sources. You have the ability to either accept or decline
        the use of cookies onyour computer, whether you are registered with us
        or not. Typically, you can configure your browserto not accept cookies.
        Web beacons may be used to track the traffic patterns of users from one
        page to another in order to maximize web traffic flow.
      </p>
      <h5>Phishing</h5>
      <p>
        If you receive an unsolicited email that appears to be from us or a
        party organizer that requestspersonal information (such as your credit
        card, login information, or password), or anything that asksyou to
        verify or confirm your account/other personal information by clicking on
        a link, that email waslikely to have been sent by someone trying to
        unlawfully obtain your information. This is sometimesreferred to as a
        "phisher" or a "spoofer." Please note that we do not ask for this type
        of information inan email. Do not provide the information or click on
        the link. Be sure to contact our customer supportat your earliest
        convenience if you get an email like this in order to alert us of the
        invasion.
      </p>
      <h5>How Long Will We Keep Yourr Personal Data?</h5>
      <p>
        We will retain your personal data only as long as is necessary for the
        purposes to which you consent under the Terms and Conditions and this
        Privacy Policy, or as is required by applicable law. Afterwhich, it will
        be removed from our system.
      </p>
      <h5>Changes To Privacy Policy</h5>
      <p>
        Playace.co. reserves the express right to change the terms of our
        privacy policy at our solediscretion. We will endeavor to notify you of
        material changes to how we use your personalinformation prior to
        implementing them, but will not be liable for any failure to do so. We
        may notifyyou by prominent notice on our website. However, we encourage
        you to review this privacy policyperiodically. Note that by using the
        services after we post these changes, you indicate that youaccept them
      </p>
      <h5>Your Consent</h5>
      <p>
        By using our website and signing up for our services, you consent to the
        collection and use of thepreviously mentioned information. This policy
        is subject to change. Privacy issues represent agrowing concern for all
        web users. To keep up-to-date with our policies, please check this
        pagefrequently. We reserve the right to change, modify, add or remove
        portions from this policy at anytime. By using our website, you hereby
        consent to our privacy policy and agree to its terms.
      </p>
    </Container>
  );
}
