import axios from 'axios';
import { URL } from '../../../api/requests';

//APPENDS THE RAZROPAY SCRIPT TO THE PAGE HTML BODY

export function loadRazorpay() {
	const script = document.createElement('script');
	script.src = 'https://checkout.razorpay.com/v1/checkout.js';
	document.body.appendChild(script);
}

//SHOWS THE RAZORPAY MODAL
export function showRazorpay(orderData, userObj, amount, event, guests) {
	//TODO: Edit options.key for dev and production
	console.log(orderData, event, event.data.event_heading, userObj.token);
	let paymentDiv = document.querySelector('.loader-booking-container');

	let paymentButton = document.querySelector('.payment');
	paymentButton.disabled = true;
	// console.log(`${process.env.REACT_APP_BASE_URL}?paid=true`);
	const options = {
		key: `${process.env.REACT_APP_RZP_KEY}`,
		amount: amount * 100,
		currency: 'INR',
		name: 'Playace.co',
		description: `${event.data.event_heading } | ${event.data.id}`,
		image: `${process.env.REACT_APP_BASE_URL}/static/media/logo.b76536f4.png`,
		order_id: orderData.data.id,
		handler: function (response) {
			const token = userObj.token;
			const auth = {
				headers: {
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + token,
				},
			};
			const payment_gateway_id = response.razorpay_payment_id;

			//console.log(response, orderData, token, auth);

			axios
				.post(
					`${URL}v1/order/store`,
					{
						type: 1,
						event_id: event.data.id,
						status: 1,
						amount: amount,
						receipt_number: orderData.data.receipt,
						order_id: response.razorpay_order_id,
						payment_id: response.razorpay_payment_id,
						signature: response.razorpay_signature,
					},
					auth
				)
				.then(response => {
					console.log(response.data);
					const payment_id = response.data.data.payment_id;
					if (response.data.message === 'Payment successfully.') {
						sendRequestToAttend(
							event,
							payment_id,
							payment_gateway_id,
							guests,
							auth
						);

						return;
					}
				})
				.catch(error => {
					console.log(error);
					return;
				});
		},
		prefill: {
			name: userObj.first_name + ' ' + userObj.last_name,
			email: userObj.email,
			contact: JSON.stringify(userObj.phone),
		},
		theme: {
			color: '#FF1844',
		},
		modal: {
			ondismiss: function () {
				if (paymentDiv !== null) paymentDiv.style.display = 'none';
				if (paymentButton != null) paymentButton.disabled = false;
			},
		},
	};
	let paymentObj = new window.Razorpay(options);
	paymentObj.open();
}

//SENDS THE REQUEST TO RAZORPAY AND CREATES AN ORDER ON THE BACKEND
export function sendRazorpayRequest(userObj, amount, event, guests) {
	console.log(userObj, amount, event, guests);
	if (document.querySelector('.loader-booking-container') !== null) {
		let paymentDiv = document.querySelector('.loader-booking-container');
		paymentDiv.style.display = 'block';
	}
	const token = userObj.token;
	const auth = {
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + token,
		},
	};

	axios
		.post(
			`${URL}v1/order/create`,
			{
				amount: amount * 100,
			},
			auth
		)
		.then(response => {
			//console.log(guests);
			showRazorpay(response.data, userObj, amount, event, guests);
			return;
		})
		.catch(error => {
			console.log(error);
		});
	return true;
}

export function sendRequestToAttend(
	event,
	payment_id,
	payment_gateway_id,
	guests,
	auth
) {
	let paymentDiv = document.querySelector('.loader-booking-container');
	let paymentButton = document.querySelector('.payment');
	const body = {
		event_id: event.data.id,
		payment_gateway_id: payment_gateway_id,
		payment_id: payment_id,
		playace_code: null,
		user_guests: guests,
	};
	// console.log(body, event, payment_id, payment_gateway_id, guests, auth);

	axios
		.post(`${URL}v1/event/attend/request`, body, auth)
		.then(response => {
			if (paymentDiv !== null) paymentDiv.style.display = 'none';
			if (paymentButton != null) paymentButton.disabled = false;
			console.log('attend email sent. Response: ' + response);
			window.location.href = `${process.env.REACT_APP_BASE_URL}?paid=true`;
			// window.location.href = '/?paid=true';
		})
		.catch(error => {
			console.log(error);
		});
}
