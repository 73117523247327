import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useQuery } from 'react-query';

import { Skeleton } from '../../modules/common';

import { fetchPartyListing } from '../../../api/requests';

// import Filter from './PartiesFilter';
import PartiesFilterNav from './PartiesFilterNav';

import { All, Today, Tomorrow, Mumbai, Pune, Favorites } from './Filters';

import { ScrollToTop } from '../../helpers';

import { useParams /*, useHistory, useLocation*/ } from 'react-router-dom';
import { Default, Mobile } from '../../helpers/Breakpoints';

function Parties() {
	// let [filteredList, setfilteredList] = useState(null);
	// let [filterLocation, setfilterLocation] = useState(null);
	let [filter, onSetFilter] = useState(null);

	const { pagenumber } = useParams();

	const [page, setPage] = useState(pagenumber);
	const rand = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

	const { data, isLoading, error } = useQuery(
		['partyListing'],
		fetchPartyListing,
		{
			retry: false,
			refetchOnWindowFocus: false,
		}
	);

	if (data && data.data.length === 0 && !isLoading) {
		return (
			<Container style={{ height: '65vh', marginTop: '7rem' }}>
				<h3 className="text-center">No parties found.</h3>
			</Container>
		);
	}

	return (
		<Container className="mTop mb-5">
			{isLoading ? <ScrollToTop /> : null}
			<div>
				<Default>
					<Row className="mt-5 mb-3">
						<Col lg={7} sm={12} />
						<Col lg={5} sm={12}>
							<PartiesFilterNav filter={filter} onSetFilter={onSetFilter} />
						</Col>
					</Row>
				</Default>
				<Mobile>
					<PartiesFilterNav filter={filter} onSetFilter={onSetFilter} />
				</Mobile>
			</div>

			{error && <h5>Failed to load</h5>}
			{isLoading && (
				<div className="grid-container">
					{rand.map((item, index) => {
						return <Skeleton key={index} />;
					})}
				</div>
			)}
			{data &&
				(() => {
					switch (filter) {
						case 'all':
							return <All setPage={setPage} onSetFilter={onSetFilter} />;
						case 'today':
							return <Today setPage={setPage} onSetFilter={onSetFilter} />;
						case 'tomorrow':
							return <Tomorrow setPage={setPage} onSetFilter={onSetFilter} />;
						case 'mumbai':
							return <Mumbai setPage={setPage} onSetFilter={onSetFilter} />;
						case 'pune':
							return <Pune setPage={setPage} onSetFilter={onSetFilter} />;
						case 'favorites':
							return <Favorites setPage={setPage} onSetFilter={onSetFilter} />;
						default:
							return <All setPage={setPage} onSetFilter={onSetFilter} />;
					}
				})()}
		</Container>
	);
}

export default React.memo(Parties);
